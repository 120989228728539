import React from 'react';
import '../../css/POP.css';
import '../../css/POP_reqv.css';
import POP_reqv_cont from './POP_reqv_cont';
import POP_Qestion from '../../Components/POP_Qestion'
import POP_Select from '../../Components/POP_Select'
import styled from 'styled-components'

import POP_reqv_messages from "./POP_reqv_messages"


export default class POP_reqv extends React.Component {
    constructor(props) {
        super()
        this.state = {
            REQV:{},
            REQV_CONT:[],
            USERS:[]
        }
        this.input=[];

        this.Mainfields=[
            {
                name:"INSTALLATION_ORGANIZATION",
                descr:"Монтажная организация"
            },
            {
                name:"PLACE_TITLE",
                descr:"Участок контроля"
            },
            {
                name:"OBJECT_TITLE",
                descr:"Объект контроля",
                disabled:true
            },
            {
                name:"NOTE",
                type:"textarea",
                className:"t1",
                descr:"Примечание"
            }
        ]
        

       

    }
    componentDidMount(){
     if(this.props.ID_OBJECT==null){
        var data={};
        global.SERVER_task('operation=createUpdateREQV&params='+JSON.stringify(data),
        (data)=>{
            try{
                this.props.callBack();
            }catch{}
            this.getREQV(data.result);
        })
     }else{
        this.getREQV()
            .then(()=>this.getCONT());
     }

     
    }

    getREQV(ID){
        return new Promise((resolve,reject)=>{
            if(ID==null) ID=this.props.ID_OBJECT;
            global.SERVER_task('operation=getREQV&ID='+ID,
            (data)=>{
                try{
                this.setState({REQV:data.result[0]});
                this.Mainfields.map((field)=>{
                    this.input[field.name].value=data.result[0][field.name];
                })

                this.input.ID_VIS.value = data.result[0].ID_VIS
                setTimeout(()=>resolve(),5);
            }catch{reject()}
                

            })
        })
        
    }

    getCONT(){
        global.SERVER_task('operation=getREQV_CONT&ID_REQVEST='+this.state.REQV.ID,
        (data)=>{
            this.setState({REQV_CONT:data.result});
        })
    }

    getUSERS(){
        global.SERVER_task('operation=getUsers',
        (data)=>{
            data.result.map((user)=>
            user.TITLE=user.FIO
            )
            this.setState({USERS:data.result});
        })
    }

    Save(){
        let data={};
        this.Mainfields.map((field)=>{
            data[field.name]=this.input[field.name].value;
        })
        data.ID=this.state.REQV.ID;
        data.ID_VIS=this.input.ID_VIS.value;

        global.SERVER_task('operation=createUpdateREQV&params='+JSON.stringify(data),
        (data)=>{
            global.PopUp.Open({content:<POP_Qestion text="Изменения сохранены"/>})
            try{
            this.props.callBack()
            }catch{}
        })
    }

    genAdministr(){
        if(this.props.ID_OBJECT!=null  && this.state.REQV.STATUS>=2){
            let RESPONSIBLE_PERSON = this.state.REQV.RESPONSIBLE_PERSON_FIO;
            if(RESPONSIBLE_PERSON!=null) RESPONSIBLE_PERSON = "Не назначен";
            if(global.Auth.ROLE==5) var onClick=()=>{}
            return(
                <div>
                    <p className="mb-0">Администрирование</p>
                    <small className="text-muted">Назначенный исполнитель</small>
                    <p className="btn btn-secondary" onClick={onClick}>{RESPONSIBLE_PERSON}</p>
                    <hr className="mb-1"></hr>
                </div>
            )
        }
        else{
            return null
        }
    }

    deleteREQV(){
        let data={
            ID:this.state.REQV.ID
        };
        

        global.SERVER_task('operation=deleteREQV&params='+JSON.stringify(data),
        (data)=>{
            if(data.result=="ok"){
                global.PopUp.CloseFunction();
                try{
                    this.props.callBack();
                    this.props.onClose();
                }catch{}

                
            }else{
                global.PopUp.Open({content:<POP_Qestion text="Невозможно удалить заявку"/>})
            }
        })
    }

    ChangeREQVSTATUS(ID_STATUS){
        if(ID_STATUS==6){
            global.API_task(global.API.SEND_MAIL,{
                EMAIL:this.state.REQV.OVNER_EMAIL,
                FROM:"Веско",
                SHABLON:"TEXT",
                TEXT:{":TEXT":"Заявка №"+this.state.REQV.ID+" была отклонена"}
            },(result)=>{})
        }

        if(ID_STATUS==2){
            if(this.state.REQV_CONT.length==0){
                global.PopUp.Open({content:<POP_Qestion text="Невозможно отправить на согласование пустую заявку"/>})
                return
            }
            global.API_task(global.API.SEND_MAIL,{
                EMAIL:this.state.REQV.DIR_EMAIL,
                FROM:"Веско",
                SHABLON:"TEXT",
                TEXT:{":TEXT":"Заявка №"+this.state.REQV.ID+" поступила на рассмотрение"}
            },(result)=>{})
        }

        if(ID_STATUS==1){
            global.API_task(global.API.SEND_MAIL,{
                EMAIL:this.state.REQV.NACH_EMAIL,
                FROM:"Веско",
                SHABLON:"TEXT",
                TEXT:{":TEXT":"Заявка №"+this.state.REQV.ID+" снаята заказчиком с рассмотрения"}
            },(result)=>{})
        }


        let data={
            ID:this.state.REQV.ID,
            ID_STATUS:ID_STATUS
        };
        

        global.SERVER_task('operation=setStatusREQV&params='+JSON.stringify(data),
        (data)=>{
            if(data.result=="ok"){
                this.getREQV();
                this.getCONT();
                try{
                    this.props.callBack();
                }catch{}
            }else{
                global.PopUp.Open({content:<POP_Qestion text="Невозможно изменить статус"/>})
            }
        })
    }

    CopyPastCONT(CONT){
        let data={
            ID_CONT:CONT.ID
        };
        

        global.SERVER_task('operation=CopyPastCONT&params='+JSON.stringify(data),
        (data)=>{
            
                
                this.getCONT();

        })
    }


    complite(){
        let data={
            ID:this.state.REQV.ID
        }

        global.SERVER_task('operation=compliteREQVEST&params='+JSON.stringify(data),
        (data)=>{
            if(data.result=="ok"){
                global.API_task(global.API.SEND_MAIL,{
                    EMAIL:this.state.REQV.OVNER_EMAIL,
                    FROM:"Веско",
                    SHABLON:"TEXT",
                    TEXT:{":TEXT":"Заявка №"+this.state.REQV.ID+" перешела в статус 'Исполенно'"}
                },(result)=>{})

                global.API_task(global.API.SEND_MAIL,{
                    EMAIL:this.state.REQV.NACH_EMAIL,
                    FROM:"Веско",
                    SHABLON:"TEXT",
                    TEXT:{":TEXT":"Заявка №"+this.state.REQV.ID+" перешела в статус 'Исполенно'"}
                },(result)=>{})

                global.PopUp.CloseFunction();
                try{
                    this.props.callBack();
                }catch{}
            }
            if(data.result=="conts not comlited"){
                global.PopUp.Open({content:<POP_Qestion text="Не все запросы исполнены"/>})
            }
        })
    }


    genButtons(){
        return(
        <div className="row mt-3">
               
                    {this.state.REQV.ID_STATUS==1 ?
                        <div className="col-2">
                            <button type="button" onClick={()=>this.Save()} class="w-100 btn btn-sm btn-primary">Сохрнаить</button>
                        </div>
                    :this.state.REQV.ID_STATUS==2 && global.Auth.ROLE==5?
                        null
                   
                    :this.state.REQV.ID_STATUS==4 && global.Auth.ROLE==3?
                        <div className="col-4">
                            <button type="button" onClick={()=>this.complite()} class="w-100 btn btn-sm btn-primary">Завершить заявку</button>
                        </div>
                    : <div className="col-4"></div>}
                    
                
                
                    {this.state.REQV.ID_STATUS==1?
                        <div className="col-2">
                            <button type="button" onClick={()=>this.deleteREQV()} class="w-100 btn-sm btn">Удалить</button>
                        </div>
                    :null}
                
                <div className="col-8">
                    {this.state.REQV.ID_STATUS==1?
                        <div>
                            <button type="button" class=" btn btn-sm " onClick={
                            ()=>
                            global.PopUp.Open({
                                content:<POP_reqv_cont
                                ID_REQVEST={this.state.REQV.ID}
                                callBack={()=>this.getCONT()}
                            />})
                        }>Добавить запрос</button>
                            <button type="button" onClick={()=>this.ChangeREQVSTATUS(2)} class=" btn btn-sm btn-primary float-right">На согласование</button>
                        </div>
                    :this.state.REQV.ID_STATUS==2 && global.Auth.ROLE==4?
                        <button type="button" onClick={()=>this.ChangeREQVSTATUS(1)} class=" btn btn-sm btn-primary float-right">Отозвать от рассмотрения</button>
                    :(this.state.REQV.ID_STATUS==2 && global.Auth.ROLE==5) || (this.state.REQV.ID_STATUS==3 && global.Auth.ROLE==3)?
                        <button type="button" onClick={()=>this.ChangeREQVSTATUS(6)} class=" btn btn-sm float-right">Отклонить заяку</button>
                    :null}

                </div>
            </div>)
    }


    
   

    render (){
        
        let Administr = this.genAdministr();
        let MainFileds = global.genFields(this,this.Mainfields,this.state.REQV.ID_STATUS);

       
        let list_cont = this.state.REQV_CONT.map((JSON)=>
            <div className="zaya"
                onClick={
                    ()=>
                    global.PopUp.Open({
                        content:<POP_reqv_cont
                        ID_OBJECT={JSON.ID}
                        callBack={()=>this.getCONT()}
                    />})
                }>
                <div className="row">
                    <div className="col-2 p-0">{JSON.METHOD_NK}</div>
                    <div className="col-6 p-0">{JSON.LINE_TITLE}</div>
                    <div className="col-1 p-0">{
                    JSON.CONTROLLING_DIM!=null && JSON.CONTROLLING_LENGTH!=null && JSON.CONTROLLING_WEIGHT!=null?
                        JSON.CONTROLLING_DIM+JSON.CONTROLLING_LENGTH+"x"+JSON.CONTROLLING_WEIGHT:null}</div>
                    <div className="col-3 p-0">{JSON.STATUS_TITLE}</div>
                </div>
                <div className={"status st-"+JSON.ID_STATUS}/>
                {JSON.ID_STATUS==1?
                    <CopyBtn src="./server/img/copy.svg" onClick={(e)=>{
                        e.stopPropagation()
                        this.CopyPastCONT(JSON)
                    }}/>
                :null}
            </div>
        )


        let buttons =this.genButtons();


        return(
        <div className="POP POP_reqv content-fluid h-100" >
            <div className="row h-100" style={{maxHeight:"calc(100% - 42px)"}}>
                <div className="col-4">
                    <h5 className='d-inline'>Заявка №</h5>
                    <REQVID disabled={this.state.REQV.ID_STATUS!=1} className="form-control" placeholder={this.state.REQV.ID_VIS} ref={ref=>this.input.ID_VIS=ref}/>
                    <small className='d-inline float-right'>{this.state.REQV.STATUS_TITLE}</small>
                    <br></br>
                    <div className="scroller">
                        {Administr}
                        
                        <p className="mb-0">Основное</p>
                        {MainFileds}
                        
                        

                      


                    </div>
                </div>
                <div className="col-8 right" >

                <small className='d-inline float-right btn btn-light btn-sm' 
                 onClick={
                    ()=>
                    global.PopUp.Open({
                        content:<POP_reqv_messages
                        ID_REQVEST={this.state.REQV.ID}
                        ID_REQV_VIS={this.state.REQV.ID_VIS}
                    />})
                }>Обратная связь по заявке</small>
                    <h5>Запросы</h5>

                    
                    <div className="scroller mt-1">
                        {list_cont}
                    </div>
                </div>
            </div>

            {buttons}
        </div>)
    
    }
}

const CopyBtn = styled.img`
height: 16px;
width:30px;
opacity: 0.5;
transition: .3s;
cursor: pointer;
position: absolute;
right: 9px;
z-index: 1;
top: 16px;
&:hover{
    opacity:1;
}
`

const REQVID = styled.input`
width:100px;
display: inline-block;
margin-left:10px
`