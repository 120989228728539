import React from 'react';
import '../../css/POP.css';


class POP_Rename extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
        this.input=[];

    }
    componentDidMount(){
       
        this.input.FILE_NAME.value=this.props.OBJECT.TITLE+"."+this.props.OBJECT.EXT;
    }


    Save(){
        if(global.checkReqvireds(this.input)){
            var name=this.input.FILE_NAME.value;
            var ext = name.substring(name.lastIndexOf(".")+1);
            name=name.substring(0,name.lastIndexOf("."));
           

            global.SERVER_task('operation=renameFile&ID_FILE='+this.props.OBJECT.ID+"&NEW_NAME="+name+"&NEW_EXT="+ext,
            (data)=>{
                if(data.result=="ok"){
                    try{
                        this.props.callBack();
                    }catch{}
                    global.PopUp.CloseFunction();
                }
            })

        }
        
        
        
    }
   

    render (){

        
        return <div className="POP POP_Rename content-fluid">
                    
                        <p className="mb-0">Название файла</p>
                        <input autoFocus type="text" ref={ref => this.input.FILE_NAME = ref} class="form-control" placeholder="fileName.txt" />
                    <br></br>
                    <div className="row">
                       <div key={global.getRandomInt(9999)} className="col">
                            <button type="button" onClick={()=>this.Save()} class="w-100 btn btn-primary">Переименовать</button>
                        </div>
                        <div key={global.getRandomInt(9999)} className="col">
                            <button type="button" onClick={()=>global.PopUp.CloseFunction()} class="w-100 btn">Отмена</button>
                        </div>
                    </div>
                    
                </div>
    
    }
}

export default POP_Rename;
