import React from 'react';
import '../css/PageTitle.css';


class PageTitle extends React.Component {
    constructor(props) {
        super()
    }

    render (){
        return  <div className="PageTitle">
                    <img className="align-middle mr-2"  src={this.props.img} ></img>
                        {this.props.title}
                </div>
    
    }
}

export default PageTitle;
