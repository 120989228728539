import React from 'react';
import styled from 'styled-components';


export default class Filters extends React.Component {
    constructor(props) {
        super()
        this.state = {
            isVisible:false
        }
        this.input={}
    }

   componentDidUpdate(nextProps){
       this.onReady(nextProps)
   }

    componentDidMount(){
        this.onReady(this.props)
    }

    onReady(props){
        try{
            Object.keys(props.Filters).map(key=>{
                if(props.Filters[key].Type=="text")
                this.input[key].value=props.Filters[key].Value
            })
            
        }catch{}
    }

    clearFilters(){
        Object.keys(this.input).map(key=>{
            this.input[key].value=null
            if(this.props.Filters[key].Type=="select")
                this.input[key].value=-1
        })
    }

    renderFilters(Filters){
        if(Filters==null) return
        return Object.keys(Filters).map((key,i)=>{
            let filter = Filters[key]
            switch(filter.Type){
                case "select":
                return ([
                    <FabWindow.Label for={`filter${i}`}>{filter.Title}</FabWindow.Label>,
                    <select ref={ref=>this.input[key]=ref} className="form-control">
                        <optgroup label="Очистить фильтр">
                            <option value="-1">Любой</option>
                        </optgroup>
                        <optgroup label="Значения">
                            {filter.Variants.map(variant=>
                                <option selected={variant.Value==filter.Value} value={variant.Value}>{variant.Title}</option>
                            )}
                        </optgroup>
                    </select>
                ])

                case "text":
                return ([
                    <FabWindow.Label for={`filter${i}`}>{filter.Title}</FabWindow.Label>,
                    <input
                    className="form-control"
                    ref={ref=>this.input[key]=ref}/>
                ])
            }
        })
    }

    setFilters(){
        let Filters = this.props.Filters
        
        Object.keys(Filters).map(key=>{
            Filters[key]={
                ...Filters[key],
                Value:this.input[key].value!=""?this.input[key].value:null
            }

            if(Filters[key].Type=="select")
                Filters[key].Value=Filters[key].Value==-1?null:Filters[key].Value*1
        })

        this.closeWindow()
        this.props.setFilters(Filters)
    }

    closeWindow(){
        this.setState({ isVisible:1})
        setTimeout(()=>{
            this.setState({ isVisible:0})
        },300)
    }

    openWindow(){
        this.setState({ isVisible:1})
        setTimeout(()=>{
            this.setState({ isVisible:2})
        },300)
    }

    render (){
        const {Filters} = this.props
        const {isVisible} = this.state
        return([
            <FabView.Fab onClick={()=>this.openWindow()}>
                <FabView.Img src="./server/img/filter.svg"/> 
            </FabView.Fab>,
            isVisible>0?
                <FabWindow.Bg onClick={()=>this.closeWindow()} isVisible={isVisible}/>
            :null,
            isVisible>0?
                <FabWindow.View isVisible={isVisible}>
                    <h4>Фильтры</h4>
                    <FabWindow.Close onClick={()=>this.closeWindow()}>x</FabWindow.Close>
                    <div className="btn btn-primary btn-sm mt-3" onClick={()=>this.setFilters()}>Применить выбранное</div>
                    <div className="btn btn-light btn-sm mt-3 float-right" onClick={()=>this.clearFilters()}>Сбросить фильтры</div>
                    <br></br>
                    {this.renderFilters(Filters)}
                </FabWindow.View>
            :null
        ])
    
    }
}
const FabView = {
    Fab : styled.div`
        position:fixed;
        bottom:100px;
        right:40px;
        background:rgb(150,155,176);
        box-shadow:10px 10px 30px rgba(0,0,0,0.05);
        border-radius:100px;
        width:60px;
        height:60px;
        cursor:pointer;
        transition:.3s cubic-bezier(.43,.53,.4,1.43);
        &:hover{
            transform:scale(1.2) rotate(20deg)
        }
    `,
    

    Img :styled.img`
        width: 25px;
        height: 25px;
        margin: auto;
        display: block;
        margin-top: 22px;
    `
}

const FabWindow = {
    Close : styled.p`
        position: absolute;
        right: 20px;
        top: 20px;
        width: 20px;
        text-align: center;
        cursor: pointer;
        transition:.3s;
        opacity:0.5;
        &:hover{
            opacity:1;
        }
    `,

    Bg:styled.div`
        position:fixed;
        left:0px;
        top:0px;
        background:rgba(0,0,0,0.3);
        width:100vw;
        backdrop-filter: blur(4px);
        height: 100vh;
        z-index: 998;
        transition:.3s;
        opacity:${props=>props.isVisible==1?0:1};
    `,
    View:styled.div`
        position:fixed;
        right:${props=>props.isVisible==1?-400:0}px;
        top:0px;
        background:white;
        width:400px;
        height: 100vh;
        z-index: 999;
        transition:.3s;
        padding:20px;
        overflow-y:scroll;
        overflow-x:hidden;
    `,
    Label:styled.label`
        margin-bottom:-10px;
        margin-top:15px;
    `
}