import React from 'react';
import '../../css/POP.css';
import '../../css/POP_reqv.css';
import POP_Qestion from '../../Components/POP_Qestion'
import POP_reqv_item from './POP_reqv_item'


export default class POP_object extends React.Component {
    constructor(props) {
        super()
        this.state = {
        }
        
        this.input=[];


    }
    componentDidMount(){
        
       if(this.props.ID_OBJECT!=null)
        this.getSelf();

    }

    getSelf(){

        global.SERVER_task('operation=getOBJ&ID='+this.props.ID_OBJECT,
        (data)=>{
            this.input.TITLE.value=data.result[0].TITLE
            this.input.DESCR.value=data.result[0].DESCR
        })
    }

    Save(){
        let data={};

        var keys = Object.keys(this.input);

        keys.map((key)=>{
            data[key]=this.input[key].value
        })

        if(this.props.ID_OBJECT!=null)
            data.ID=this.props.ID_OBJECT;

        global.SERVER_task('operation=createUpdateObject&params='+JSON.stringify(data),
        (data)=>{
            try{
                this.props.callBack();
            }catch{}
            global.PopUp.CloseFunction();
        })
    }

    Delete(){
        global.SERVER_task('operation=deleteOBJ&ID='+this.props.ID_OBJECT,
        (data)=>{
            try{
                this.props.callBack();
            }catch{}
            global.PopUp.CloseFunction();
        })
    }
    

    render (){
      
        

        return(
        <div className="POP POP_object  content-fluid " style={{width:500}}>
            <div className="row">
                <div className="col-12">
                    <h5 className='d-inline'>Объект</h5>
                    <br></br>
                    <div className="scroller">
                        <small className="mb-0 mt-2">Название</small>
                        <input ref={ref => this.input.TITLE = ref} className="form-control w-100"/>

                        <small className="mb-0 mt-2">Описание</small>
                        <textarea ref={ref => this.input.DESCR = ref} className="form-control w-100 h-150px" style={{resize:"none"}}/>

                        
                    </div>
                    
                </div>
                
            </div>

            <div className="row mt-3">
               
                <div className="col-3 pr-1">
                    <button type="button" onClick={()=>this.Save()} className="w-100 btn btn-sm btn-primary">Сохранить</button>
                </div>
                <div className="col-3 pl-1">
                    {this.props.ID_OBJECT!=null?
                        <button type="button" onClick={()=>this.Delete()} className="w-100 btn btn-sm btn-light">Удалить</button>
                    :null}
                </div>


            

            </div>
        </div>)
    
    }
}

