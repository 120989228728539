import React from 'react';
import styled from 'styled-components';


class POP_ChatSettings extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          Self:{
              USERS:[]
          },
          USERS:[],
          filter:null
        }
        this.input=[];
        this.selectedUser=null

    }
    componentDidMount(){
        this.getUsers().then(()=>
            this.getSelf()    
        )     
           
    }
    getUsers(){
        return new Promise((resolve,reject)=>{
            global.SERVER_task('operation=getUsers',(data)=>{
                this.setState({USERS:data.result})
                setTimeout(()=>resolve(),10)
            })
        })
        
    }
    getSelf(){ 
        if(this.props.ID_OBJECT==null) return
        global.SERVER_task('operation=getChats',(data)=>{
            let chat = data.result.find(ch=>ch.ID==this.props.ID_OBJECT)
            if(chat!=null)
                global.SERVER_task('operation=getChatsUsers&params='+JSON.stringify({
                    ID_CHAT:chat.ID
                }),(users)=>{
                    chat.USERS=users.result
                    chat.USERS.forEach((u,i)=>{
                        
                        chat.USERS[i]=this.state.USERS.find(us=>us.ID==u.ID_USER)
                    })
                    
                    this.setState({Self:chat})
                    this.input.TITLE.value = chat.TITLE
                    
                })

        })

    }

    Save(){
        const {Self,filter,USERS}=  this.state

        if(global.checkReqvireds([this.input.TITLE])){
            var data={
                TITLE:this.input.TITLE.value,
                USERS:Self.USERS.map(u=>u.ID).filter(u=>u!=global.Auth.ID_USER)
            }
            
            if(this.props.ID_OBJECT!=null)
                data.ID = this.props.ID_OBJECT

            global.SERVER_task('operation=createUpdateChat&params='+JSON.stringify(data),
            (data)=>{
                try{
                    this.props.callBack(data.result*1);
                }catch{}
                global.PopUp.CloseFunction()
            })
        }
    }

    Delete(){
        global.SERVER_task('operation=deleteChat&params='+JSON.stringify({
            ID_CHAT:this.props.ID_OBJECT
        }),
        (data)=>{
            try{
                this.props.callBack(null);
            }catch{}
            global.PopUp.CloseFunction()
        })
    }

  

    render (){
        const {Self,filter,USERS}=  this.state
        let filterUsers = []
        if(filter==null)
            filterUsers=[]
        else{
            
            filterUsers=USERS.filter(u=>
                Self.USERS.findIndex(x=>x.ID==u.ID)==-1 &&
                u.ID!=global.Auth.ID_USER && (
                u.FIO.toUpperCase().indexOf(filter)!=-1 || u.EMAIL.toUpperCase().indexOf(filter)!=-1)
            )
        }

        return(
            <POP>
                <h4>Настройка чата</h4>
                <label for='TITLE' className='mb-0'><small className='mb-0'>Название</small></label>
                <input id='TITLE' className='form-control mt-0' ref={ref=>this.input.TITLE=ref}/>
                <hr/>
                <div className='row' style={{position:'relative',zIndex:2}}>
                    <div className='col-8'>
                        <label for='FILTER' className='mb-0'><small className='mb-0'>Добавить пользователя</small></label>
                        <input onKeyUp={(e)=>{
                            let value = e.target.value
                            if(value=='')
                                value = null
                            else
                                value=value.toUpperCase()

                            this.setState({filter:value})
                        }} id='FILTER' className='form-control mt-0' ref={ref=>this.input.FILTER=ref}/>
                    </div>
                    <div className='col-4'>
                        <div className='btn btn-sm btn-light w-100 mt-4' onClick={()=>{
                            if( this.selectedUser==null) return
                            let arr = Self.USERS.splice(0)
                            arr.push( this.selectedUser)
                            this.setState({Self:{
                                ...Self,
                                USERS:arr,
                                filter:null
                            }})
                            
                            this.input.FILTER.value=''
                            this.selectedUser=null
                        }}>добавить</div>
                    </div>
                </div>
                {filter==null?null:
                <NewUser count={filterUsers.length}>
                    {filterUsers.length==0?
                        <small className='w-100 p-2 text-ceanter d-block'>Пользователи не найдены</small>:
                        filterUsers.map(user=>
                            <UserView onClick={()=>{
                                this.selectedUser=user
                                this.input.FILTER.value=user.FIO
                                this.setState({filter:null})
                            }}>
                                {user.FIO}<br/>
                                <small className='d-block mt-0 pt-0'>{user.ROLE_TITLE}</small>
                            </UserView>
                        )
                    }
                </NewUser>}
                
                <ScrollView>
                    {Self.USERS.length==0?<small>В чате пока что никого нет</small>:
                    Self.USERS.map((user,i)=>
                        <UserView  style={{
                            paddingLeft:0,
                            paddingRight:0
                        }}>
                            {global.Auth.ID_USER==user.ID?null:
                            <div className='deleter btn btn-sm btn-light float-right mt-2' onClick={()=>{
                                let arr = Self.USERS.splice(0)
                                arr.splice(i,1)
                                this.setState({Self:{
                                    ...Self,
                                    USERS:arr
                                }})
                            }}>убрать</div>}
                            {user.FIO} {global.Auth.ID_USER==user.ID?'(я)':null}<br/>
                            
                            <small className='d-block mt-0 pt-0'>{user.ROLE_TITLE}</small>
                            {i!=Self.USERS.length-1?<hr className='mb-0'/>:null}
                        </UserView>
                    )
                    }
                </ScrollView>
                <Bottom>
                    {this.props.ID_OBJECT==null?[
                        <button onClick={()=>this.Save()} class="btn btn-primary btn-sm">Создать</button>,
                        <button onClick={()=>global.PopUp.CloseFunction()} class="btn btn-light float-right btn-sm">Отмена</button>
                    ]:[
                        <button onClick={()=>this.Save()} class="btn btn-primary btn-sm">Сохранить</button>,
                        <button onClick={()=>this.Delete()} class="btn btn-danger float-right btn-sm">Удалить</button>
                    ]}
                </Bottom>
            </POP>
        )
    }
}

export default POP_ChatSettings;

const POP = styled.div`
width:400px
`
const Bottom = styled.div`
width:400px;
margin-top:40px;
`

const UserView = styled.div`
cursor:pointer;
transition:  .3s;
padding:10px;
&:hover{
    ${props=>props.isHover?'background:rgb(208, 218, 230);':''}
    
}
& .deleter{
    opacity:0;
    transition:  .3s;
}
&:hover .deleter{
    opacity:1;
}
`


const NewUser = styled.div`
font-size:14px;
width: 257px;
font-weight:bold;
max-height: 250px;
border-radius: 5px;
background: white;
box-shadow: 10px 10px 30px rgba(0,0,0,0.1);
position: absolute;
margin-top: -30px;
padding-top: 30px;
padding-bottom: 0px;
overflow:auto;
transition: all .3s;
z-index:1;
`
const ScrollView = styled.div`
max-height:40vh;
`
