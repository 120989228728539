import React from 'react';
import '../../css/POP.css';
import styled from 'styled-components';


export default class extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            OpenedFolders:[],
            PromissedFolders:[],
            serchFolder:"",
            Folders:[],
            selectedFolder:null
        }
        this.input=[];

    }
    componentDidMount(){
       this.getFolders();
        
    }

    getFolders(){
        global.SERVER_task('operation=getAllFolders&type=personal',
        (data)=>{

            this.setState({Folders:data.result});   
        })
        
    }

    renderFileSustem(parnetFolder = null,zIndex=0,isNotAlowedGlobal=false){
        const {OpenedFolders,PromissedFolders,serchFolder,selectedFolder} = this.state
        const Folders = parnetFolder==null? this.state.Folders : parnetFolder.Childes
        let renderFolders = []

        Folders.map(folder=>{
            let canDisplaySelf = true
            if(serchFolder.length>0) if(folder.TITLE.toUpperCase().indexOf(serchFolder.toUpperCase())==-1) 
                canDisplaySelf=false

            let index_in_opened_folders = OpenedFolders.findIndex(id_folder=>id_folder==folder.ID)
            
            let index_in_permissions = PromissedFolders.findIndex(id_folder=>id_folder===folder.ID)

            let isAllowed = !isNotAlowedGlobal
            
            if(index_in_permissions==-1 && folder.IS_PRIVATE==1 || index_in_permissions!=-1 && folder.IS_PRIVATE==0) 
                isAllowed = false
            
          
            renderFolders.push(
                <FileSustem.View canDisplaySelf={canDisplaySelf} zIndex={zIndex} isCurrent={this.props.OBJECT.ID_FOLDER==folder.ID} isSelected={folder.ID==selectedFolder} onClick={()=>{
                    let OpenedFolders_tmp= OpenedFolders
                
                    if(index_in_opened_folders==-1)
                        OpenedFolders_tmp.push(folder.ID)
                    else
                        OpenedFolders_tmp.splice(index_in_opened_folders,1)

                    this.setState({OpenedFolders:OpenedFolders_tmp,selectedFolder:folder.ID>0?folder.ID:null})
                }}>
                    <FileSustem.Icon
                        src={isAllowed?"/server/img/files/folder.svg":"/server/img/files/folderGray.svg"}/>
                    <FileSustem.Title isOpen={index_in_opened_folders!=-1}>{folder.TITLE} 
                    <j style={{fontSize:10,color:"rgb(140,140,140)"}}>{folder.IS_PRIVATE==1?" private":""}</j>
                    {this.props.OBJECT.ID_FOLDER==folder.ID?
                    <j style={{fontSize:10,color:"rgb(140,140,140)"}}> текущее расположение</j>:null}
                    </FileSustem.Title>
                </FileSustem.View>
            )
            if(index_in_opened_folders!=-1)
                renderFolders=renderFolders.concat(this.renderFileSustem(folder,zIndex+1,!isAllowed))
        })
        if(Folders.length==0)
            renderFolders.push(
                <FileSustem.View zIndex={zIndex}>
                   
                    <FileSustem.Title style={{color:"rgb(200,200,200)"}} isOpen={false}>Внутри нет каталогов </FileSustem.Title>
                </FileSustem.View>
            )

        return renderFolders
    }


    Save(){
        if(this.state.selectedFolder==null || this.props.OBJECT.ID_FOLDER==this.state.selectedFolder) return
        global.SERVER_task('operation=replaceFile&ID_FILE='+this.props.OBJECT.ID+"&ID_FOLDER="+this.state.selectedFolder,
        (data)=>{
            if(data.result=="ok"){
                try{
                    this.props.callBack();
                }catch{}
                global.PopUp.CloseFunction();
            }
            
        })
    }
   
    selectFolder(ID_FOLDER){
        this.setState({selectedFolderId:ID_FOLDER});
    }

    render (){
        
        
        return <POP_Rename className="POP content-fluid">
                    
                        <p className="mb-0">Новое расположение файла</p>
                        <input onKeyUp={(e)=>this.setState({serchFolder:e.target.value})} type="text" class="form-control mt-0 mb-0" placeholder="Поиск..." />
                        <Scroll>
                            {this.renderFileSustem()}
                        </Scroll>
                    <br></br>
                    <div className="row">
                       <div key={global.getRandomInt(9999)} className="col">
                            <button type="button" onClick={()=>this.Save()} class="w-100 btn btn-primary">Переместить</button>
                        </div>
                        <div key={global.getRandomInt(9999)} className="col">
                            <button type="button" onClick={()=>global.PopUp.CloseFunction()} class="w-100 btn">Отмена</button>
                        </div>
                    </div>
                    
                </POP_Rename>
    
    }
}


const POP_Rename = styled.div`
width:800px;
height:80vh;
`

const Scroll = styled.div`
overflow: scroll;
width: 840px;
margin-left: -20px;
padding-left: 20px;
height: calc(80vh - 110px);
`

const FileSustem={
    View: styled.div`
    opacity:${props=>props.canDisplaySelf?1:0.2};
    padding-left:${props=>props.zIndex*10}px;
    border-bottom: 1px solid rgb(249, 249, 249);
    height: 26px;
    cursor:pointer;
    background:${props=>props.isCurrent?`rgb(230, 230, 230)`:props.isSelected?`rgb(213, 235, 245)`:`white`};
    border-radius: 3px;
    &:hover img{
        margin-left:-3px;
    }
    `,
    Icon: styled.img`
    width:20px;
    height:20px;
    transition: .3s;
    `,
    Title: styled.p`
    ${props=>props.isOpen?`font-weight:bold;`:`font-weight:normal;`}
    display: inline-block;
    margin-left: 10px;
    font-size: 13px;
    `
}
