import React from 'react';
import '../../css/Login.css';
import POP_Qestion from '../../Components/POP_Qestion'



class Login extends React.Component {
    constructor(props) {
        super()
        this.state = {
           
        }

        this.input=[];

      
    }
    
    SignIn(){
        if(global.checkReqvireds(this.input)){
            global.SERVER_task('operation=checkLogined&LOGIN='+this.input.LOGIN.value+"&PASSWORD="+this.input.PASSWORD.value,
            (data)=>{
            debugger;
               if(data.result=="error login"){
                    global.PopUp.Open({content:<POP_Qestion text="Неверный логин \ пароль" />})
               }else if(data.result=="block login"){
                    global.PopUp.Open({content:<POP_Qestion text="Ваш аккаунт заблокирован администратором системы" />})
               }else window.location.reload();
            })
        }
    }

  
    render (){
        return  <div className="Login " >
                   <div id="MAIN_title">
                   <h1>Серверный файловый менеджер</h1>
            <p>Разработано ITTS</p>
                    </div>
                    <div id="Login" >
                        <h3 className="text-center">ВХОД</h3>
                        <input ref={ref => this.input.LOGIN = ref} type="text" class="form-control mb-2 form-dark" placeholder="Логин" />
                        <input ref={ref => this.input.PASSWORD = ref} type="password" class="form-control mb-2 form-dark" placeholder="Пароль" />
                        <button type="button" class="btn btn-primary  w-100" onClick={()=>this.SignIn()}>ПРОДОЛЖИТЬ</button>
                        <button type="button" class="btn btn-link btn-sm mt-2 mb-0">Восстановить пароль</button>
                    </div>
                    <p id="ifo" style={{opacity:0}} className="text-center"><b>Created by <a href="https://vk.com/bicsz" target="_blank">Bicsz</a></b><br></br>2020</p>
                </div>
    
    }
}

export default Login;
