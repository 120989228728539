import React from 'react';
import '../../css/POP.css';
import '../../css/POP_reqv.css';
import POP_Qestion from '../../Components/POP_Qestion'
import styled from 'styled-components';


export default class POP_reqv_set_status extends React.Component {
    constructor(props) {
        super()
        this.state = {
           
            CONTROL_STATUSES:[]
        }
        this.input=[];

        this.Proverka=[
            {
                name:"DEFECT_TEXT",
                type:"textarea",
                className:"t1",
                descr:"Выявленные дефекты у группы"
            }
        ]

        this.ProverkaFields = global.genFields(this,this.Proverka,1);
        

    }
    componentDidMount(){
        this.getCONTROL_STATUSES()
    }



    getCONTROL_STATUSES(){
        global.SERVER_task('operation=getCONTROL_STATUSES',
        (data)=>{
            this.setState({CONTROL_STATUSES:data.result});
        })
    }
   
  

    setStatus(ID){
        if(!global.checkReqvireds(this.input)) return;
        let data={
            IDs:this.props.ID_OBJECTS,
            DEFECT_TEXT:this.input.DEFECT_TEXT.value,
            ID_CONTROL_STATUS:ID,
            TYPE_OBJECTS:this.props.TYPE_OBJECTS
        };

        global.SERVER_task('operation=itemSetStatus_GROUP&params='+JSON.stringify(data),
        (data)=>{
            global.PopUp.CloseFunction();
            try{
                this.props.callBack();
            }catch{}
  
        })
    }

   

   

    render (){
      const {ID_OBJECTS} = this.props

        return(
        <POP className="POP content-fluid " >
            <div className="row">
                <div className="col-12">
                    <h5 className='d-inline'>Групповое назначение статуса</h5>
                    <small className='d-inline float-right'>{ID_OBJECTS.length}шт</small>
                    <br></br>
                        {this.ProverkaFields}
                </div>
            </div>

            <div className="row mt-2">
                <div className="col-12">

                    <div class="dropdown m-1 float-right">
                        <button class="btn btn-sm btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Установить статус
                        </button>
                        <div class="dropdown-menu p-0" aria-labelledby="dropdownMenuButton">
                            {this.state.CONTROL_STATUSES.map((JSON)=>
                                <a onClick={()=>this.setStatus(JSON.ID)}
                                    className={`dropdown-item ${(JSON.ID!=5?"text-white":"")} si-${JSON.ID}`}
                                >{JSON.TITLE}</a>
                            )}
                        </div>
                    </div> 

                </div>

                
            </div>
        </POP>)
    
    }
}

const POP = styled.div`
width:289px;
`
