import React from 'react';
import styled from 'styled-components';
import '../../css/POP.css';
import File from './File';


export default class POP_Search extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            filterTitle:null,
            selectedFile:{},
            Folders_childe:[]
        }
        this.input=[];
        this.searchTimer=null

    }
    componentDidMount(){
       
       
    }

    Search(fileTitle){
        if(this.searchTimer==null){
            this.searchTimer=setTimeout(()=>this.SearchFiles(fileTitle),1000)
        }else{
            clearTimeout(this.searchTimer)
            this.searchTimer=null
            this.Search(fileTitle)
        }
    }

    SearchFiles(fileTitle){
        global.SERVER_task(`operation=SearchFiles&FILE_TITLE=${fileTitle}`,
        (data)=>{
            
            this.setState({Folders_childe:data.result})
        })
    }

    openFile(JSON){
        this.setState({selectedFile:JSON});
        global.onDBClick(JSON.ID,()=>{
            window.open(global.serverURL_Main+"files/"+JSON.URL+"."+JSON.EXT, '_blank');
        });
    }


    render (){
       const {selectedFile,Folders_childe} = this.state
        
        return(
        <POP className="POP POP_Rename content-fluid">
            <div className="row">
                <div className="col-4">
                    <p className="mb-0">Поиск файла</p>
                    <input autoFocus type="text"  class="form-control w-100" placeholder="fileName.txt" onKeyUp={(e)=>this.Search(e.target.value)} />

                    {selectedFile.ID!=null?[
                        selectedFile.EXT=='png' || selectedFile.EXT=='jpg' || selectedFile.EXT=='jpeg'?
                        <ImgPreview src={global.serverURL_Main+"files/"+selectedFile.URL+"."+selectedFile.EXT}/>
                        :null,
                    
                        <p><b>Название:</b> {selectedFile.TITLE}</p>,
                        <p><b>Расширение:</b> {selectedFile.EXT}</p>,
                        <p><b>Рамер:</b> {global.makeNormalChislo(selectedFile.SIZE)} КБ</p>,
                        global.Auth.ROLE<=2?
                            <p><b>Доступ:</b> {selectedFile.PERMISSION_COUNT+global.okonchanie(selectedFile.PERMISSION_COUNT," юзер"," юзера"," юзеров")}</p>
                        : null
                    ]
                    :null
                }
                </div>
                <div className="col-8">
                    <ScrollView>
                        <p className="mb-0">Найденные совпадения</p>
                        {
                            Folders_childe.map((JSON)=>
                                <File
                                SHOW_EXT_IMG={true}
                                SHOW_EXT_TEXT={true} 
                                SHOW_DESCR={false}
                                preTitle={JSON.FOLDER_TITLE}
                                isSelected={selectedFile.ID==JSON.ID?true:false} 
                                ID_OBJECT={JSON.ID}
                                class="scrollElement"
                                onClick={(e)=>{
                                    e.stopPropagation()
                                    this.openFile(JSON)
                                }}
                                TITLE={ global.textCut(JSON.TITLE,30,"..")+"."}
                                EXT={JSON.EXT}
                               />
                            )
                        }
                    </ScrollView>
                </div>
            </div>     
        </POP>
        )
    
    } 
}

const POP = styled.div`
width:900px;
`
const ScrollView = styled.div`
padding:15px;
padding-right:30px;
height:700px;
overflow:scroll;
`
const ImgPreview = styled.img`
height: 200px;
border-radius: 10px;
width: 100%;
object-fit: cover;
margin-bottom: 10px;
margin-top:15px;
`