import React from 'react';
import '../../css/POP.css';
import '../../css/POP_reqv.css';
import POP_Qestion from '../../Components/POP_Qestion'
import styled from 'styled-components';


export default class POP_reqv_joins_create extends React.Component {
    constructor(props) {
        super()
        this.state = {
            ITEM:{},
            CONTROL_STATUSES:[]
        }
        this.input=[];

        this.Mainfields=[
            {
                name:"WELDER",
                descr:"Сварщик"
            },
            {
                name:"WELDER_TYPE",
                descr:"Тип сварного соединения"
            },
            {
                name:"ID_MATERIAL_WELDER",
                descr:"Материал сварки",
                type:"select",
                values:global.SPR["Сталь"].DATA
            },
            {
                name:"DIAMETER",
                descr:"Диаметр"
            },
            {
                name:"WIDTH",
                descr:"Толщина"
            },
            {
                name:"COMMENT",
                descr:"Комментарий",
                type:"textarea",
                className:"t2"
            },
        ]

        this.ShablonFields=[
            {
                name:"NAME_SHABLON",
                descr:"Шаблон названий",
                placeholder:"{№}"
            },
            {
                name:"COUNT",
                descr:"Количество стыков",
                type:"number",
                min:1
            },
        ]
        

       

    }
    componentDidMount(){

    }

    getREQV_ITEM(ID){
        if(ID==null) ID=this.props.ID_OBJECT;
            global.SERVER_task('operation=getREQV_ITEMS&ID='+ID,
            (data)=>{
                this.setState({ITEM:data.result[0]});
                this.Mainfields.map((field)=>{
                    this.input[field.name].value=data.result[0][field.name];
                })
                try{
                    this.input.DEFECT_TEXT.value=data.result[0].DEFECT_TEXT;
                }catch{}
            })
        
    }

    getCONTROL_STATUSES(){
        global.SERVER_task('operation=getCONTROL_STATUSES',
        (data)=>{
            this.setState({CONTROL_STATUSES:data.result});
        })
    }
   
    Create(){
        
        let data={};

        
        this.Mainfields.map((field)=>{
            data[field.name]=this.input[field.name].value==""?null:this.input[field.name].value;
        })

        this.ShablonFields.map((field)=>{
            data[field.name]=this.input[field.name].value;
        })

        data.ID_REQV_CONT=this.props.ID_REQV_CONT;
        data.ID=this.state.ITEM.ID;

        if(data.NAME_SHABLON.length==0)
            data.NAME_SHABLON="{№}"
        
            
        global.SERVER_task('operation=createREQV_JOINS&params='+JSON.stringify(data),
        (data)=>{
            global.PopUp.CloseFunction()
            this.props.callBack()
        })
    }

    setStatus(ID){
        if(!global.checkReqvireds(this.input,["CONTROL_PLACE","DESCR"])) return;
        let data={
            ID:this.state.ITEM.ID,
            DEFECT_TEXT:this.input.DEFECT_TEXT.value,
            ID_CONTROL_STATUS:ID
        };

        global.SERVER_task('operation=itemSetStatus&params='+JSON.stringify(data),
        (data)=>{
            global.PopUp.CloseFunction();
            try{
                this.props.callBack();
            }catch{}
  
        })
    }

   

    deleteITEM(){
        let data={
            ID_REQV_CONT:this.props.ID_REQV_CONT,
            ID:this.state.ITEM.ID
        };
        

        global.SERVER_task('operation=deleteREQV_ITEM&params='+JSON.stringify(data),
        (data)=>{
            if(data.result=="ok"){
                global.PopUp.CloseFunction();
                try{
                    this.props.callBack();
                }catch{}
            }else{
                global.PopUp.Open({content:<POP_Qestion text="Невозможно удалить позицию"/>})
            }
        })
    }

   
   

    render (){
        let MainFileds = global.genFields(this,this.Mainfields,1);
        let ShablonFields = global.genFields(this,this.ShablonFields,1);

        return(
        <POP className="POP content-fluid " >
            <div className="row">
                <div className="col-12">
                    <h5 className='d-inline'>Создание стыков</h5>
                    <small className='d-inline float-right'>{this.state.ITEM.STATUS_TITLE}</small>
                    <br></br>
                    <Scroller>

                        
                        {ShablonFields}
                        
                        <br/>
                        {MainFileds}
                        
                    </Scroller>
                </div>
            </div>

            <div className="row mt-2">
                <div className="col-12">
                    <div className="row ">
                            <div className="col-6">
                                <button type="button" onClick={()=>this.Create()} class="w-100 btn btn-sm btn-primary">Создать</button>
                            </div>
                            <div className="col-6">
                                <button type="button" onClick={()=>global.PopUp.CloseFunction()} class="w-100 btn btn-sm btn-light">Отмена</button>
                            </div>
                        </div>
                    
                </div>


                
            </div>
        </POP>)
    
    }
}

const POP = styled.div`
width:300
`

const Scroller = styled.div`
max-height: calc(85vh - 62px);
overflow-y: scroll;
`