import React from 'react';
import '../../css/_1.css';
import '../../css/Otchot.css';
import POP_reqv from '../Zaiavki/POP_reqv'
import POP_reqv_cont from '../Zaiavki/POP_reqv_cont'
import Legend from "../Zaiavki/Legend"


export default class extends React.Component {
    constructor(props) {
        super()
        this.curDate=new Date(new Date().setHours(0,0,0,0));
        this.state = {
           dateStart: global.addDays(this.curDate,-30),
           dateEnd: global.addDays(this.curDate,30),
           REQV_CONTs:[]
        }
        this.ref=[]
        this.input=[]

        this.REQV_CONTs=null;
        this.LOG=null;
        
    }
   

    componentDidMount(){
        this.input.dateStart.valueAsDate=new Date(this.state.dateStart.setHours(3));
        this.input.dateEnd.valueAsDate=new Date(this.state.dateEnd.setHours(3));
        this.getREQV_CONTs();
        this.getREQV_CONTs_LOG();
    }

    getREQV_CONTs(){
        
        global.SERVER_task('operation=getREQV_CONTs',
        (data)=>{
            this.REQV_CONTs=data.result;
            this.compareCONTs_and_LOG()
        })
    }

    getREQV_CONTs_LOG(){
        
        global.SERVER_task('operation=getREQV_CONTs_LOG',
        (data)=>{
            this.LOG_=data.result;
            this.LOG_.map(con=>{
                con.DATE_TIME=new Date(con.DATE_TIME)
            })
            this.compareCONTs_and_LOG()
        })
    }

    compareCONTs_and_LOG(){
        if(this.REQV_CONTs!=null && this.LOG_!=null){
            this.REQV_CONTs.map(con=>{

                con.LOG=[]
                this.LOG_.map(lg=>{
                    if(lg.ID_OBJECT==con.ID)
                        con.LOG.push(lg)
                })

                this.LOG_=this.LOG_.filter(lg=>lg.ID_OBJECT!=con.ID)
            })


            

            this.REQV_CONTs.map(con=>{
                con.LOG.map((lg,i)=>{
                    try{
                        lg.STATUS_ACTIVE_DAYS=3;
                        lg.DATE_TIME=new Date(lg.DATE_TIME.setHours(0,0,0,0))
                        let nextDateTime=new Date(con.LOG[i+1].DATE_TIME.setHours(0,0,0,0))
                        if(lg.DATE_TIME.getTime()==nextDateTime.getTime()){//если смена статуса произошла за 1 день
                            lg.DATE_TIME_CHANGE=nextDateTime
                            lg.STATUS_ACTIVE_DAYS=0
                        }else{
                            lg.DATE_TIME_CHANGE=new Date(nextDateTime.getTime()-24*60*60*1000)
                            lg.STATUS_ACTIVE_DAYS=Math.ceil(Math.abs(lg.DATE_TIME_CHANGE.getTime() - lg.DATE_TIME.getTime()) / (1000 * 3600 * 24))+1;  
                        }
                        
                        
                    }catch{}
                })
            })

            console.log("compareCONTs_and_LOG")
            console.log(this.REQV_CONTs)
            this.setState({REQV_CONTs:this.REQV_CONTs})
        }
    }

    

   

    renderTimeLine(){
        let {dateStart,dateEnd} = this.state;

        var currentDate=dateStart;

        var nowMonts=dateStart.getMonth();
        var Monts=[{
                title:global.getMonth_Title(nowMonts),
                Days:[{
                    date:currentDate,
                    day:currentDate.getDate(),
                    title:global.getWeekDay_Title(dateStart.getDay(),true)
                }]
            }];


        var diffDays = Math.ceil(Math.abs(dateStart.getTime() - dateEnd.getTime()) / (1000 * 3600 * 24));  
        
      
        for(let i =0;i<diffDays;i++){
           
            currentDate = global.addDays(currentDate,1)
            let currentMonth=currentDate.getMonth();
            if(nowMonts!=currentMonth){
                nowMonts=currentMonth;
                Monts.push({
                    title:global.getMonth_Title(nowMonts),
                    Days:[]
                })
               
            }
            Monts[Monts.length-1].Days.push({
                date:currentDate,
                day:currentDate.getDate(),
                title:global.getWeekDay_Title(currentDate.getDay(),true)
            })
        }

        

        var Color=true;
        let tr_months=(
            <tr>
                {
                    Monts.map(month=>{
                        Color=!Color;
                        return <td className={Color?"bg-light":""} colSpan={month.Days.length}>{month.title}</td>
                    })
                }
            </tr>
        )

        Color=false;
        let tr_days=(
            <tr className="text-center weeks">
                {
                    Monts.map(month=>
                        month.Days.map(day=>{
                                if(day.title=="ПН") Color=!Color;
                                if(day.date.setHours(0,0,0,0)==this.curDate.getTime())
                                    return <td className="bg-primary">{day.title} <br/> {day.day}</td>
                                else
                                    return <td className={Color?"bg-light":""}>{day.title} <br/> {day.day}</td>
                            })
                        )
                }
            </tr>
        )

        return [tr_months,tr_days,this.renderLog(Monts)];
        
    }

    renderLog(TimeLine){
        return this.state.REQV_CONTs.map(con=>{
           console.log("CONT "+con.ID);
            if(con.LOG.length>0)
                if(
                    con.LOG[0].DATE_TIME<TimeLine[TimeLine.length-1].Days[TimeLine[TimeLine.length-1].Days.length-1].date && //Если день первой записи о заявке меньше чем дата окончания таймлайна
                    con.LOG[con.LOG.length-1].DATE_TIME>TimeLine[0].Days[0].date //Если последней записи о заявке больше чем дата начала таймлайна
                ){
                    
                    var TimeLineDays=[];
                    TimeLine.map(M=>{
                        M.Days.map(D=>{
                            TimeLineDays.push({
                                date:D.date,
                                value:null
                            })
                        })
                    })

                    
                    con.LOG.map((lg,i)=>{
                        console.log("LOG "+lg.ID);

                        for(let i=0;i<TimeLineDays.length;i++){
                            let line = TimeLineDays[i]
                            if(lg.STATUS_ACTIVE_DAYS!=0){
                                if(lg.DATE_TIME.setHours(0,0,0,0)==line.date.setHours(0,0,0,0)){
                                    line.value=(
                                        <td colSpan={lg.STATUS_ACTIVE_DAYS}>
                                            <div className={"zay-st st-"+lg.NEW_STATUS+" timeLine"} title={lg.STATUS_TITLE}>
                                                {lg.STATUS_ACTIVE_DAYS<3?null:lg.STATUS_TITLE}
                                            </div>
                                        </td>
                                        )
                                    if(lg.STATUS_ACTIVE_DAYS>1){
                                        TimeLineDays.splice(i+1,lg.STATUS_ACTIVE_DAYS-1)
                                    }
                                }
                            }

                        }


                    })
                    
                   console.log(TimeLineDays)
                    return <tr  className="text-center timeLies">{TimeLineDays.map(line=>line.value==null?<td/>:line.value)}</tr>
                }
        })
    }



    renderREQV_CONTs(){
        return this.state.REQV_CONTs.map(JSON=>
            <tr className="fw-light" onClick={
                ()=>
                    global.PopUp.Open({content:<POP_reqv_cont
                    ID_OBJECT={JSON.ID}
                    callBack={()=>{
                        this.REQV_CONTs=null;
                        this.LOG_=null;
                        this.getREQV_CONTs();
                        this.getREQV_CONTs_LOG();
                    }}
                    />})
                }>
                    <td><div className={"zay-st st-1"} onClick={
                        (e)=>{
                            e.stopPropagation();
                            global.PopUp.Open({content:<POP_reqv
                            ID_OBJECT={JSON.ID_REQVEST}
                            callBack={()=>{
                                this.REQV_CONTs=null;
                                this.LOG_=null;
                                this.getREQV_CONTs();
                                this.getREQV_CONTs_LOG();
                            }}
                            />})
                    }}> {JSON.ID_REQVEST}</div></td>
                    <td>{JSON.TITLE_OBJECT}</td>
                    <td>{JSON.METHOD_NK}</td>
                    <td><div className={"zay-st st-"+JSON.ID_STATUS}>{JSON.STATUS_TITLE}</div></td>
                    <td>{JSON.RESP_FIO}</td>

                    {global.Auth.ROLE==6?<td>{JSON.OVNER_FIO}</td>:null}
                    {global.Auth.ROLE==6?<td>{JSON.OVNER_EMAIL}</td>:null}
                    {global.Auth.ROLE==6?<td>{JSON.OVNER_PHONE}</td>:null}

                    <td>{JSON.NACH_FIO}</td>
                    <td>{JSON.INSTALLATION_ORGANIZATION}</td>

                    <td>{JSON.KNOT_TITLE}</td>
                    <td>{JSON.LINE_TITLE}</td>

                    <td>{JSON.WILDER_JURNAL}</td>
                    <td>{JSON.WLDER_NUMBER}</td>
                    <td>{JSON.WELDERS_JOIN_TITLE}</td>
                    <td>{JSON.MARK_MATERIAL_WELDER}</td>
                    <td>{JSON.MARK_MATERIAL_TITLE}</td>
                    <td>{JSON.WELDER_MARK}</td>


            </tr>
            )
    }

    

  

    render (){


        return  <div className="_1 Zaiavki Otchot tttb" >
                    <div class="container-fluid m-0 " >
                        <div class="row main_pages ">
                            <div className="col-lg-2 col-md-5 left">
                                {this.props.TITLE}
                                <Legend/>
                                <div class="form-group mb-0 ">
                                    <label for="inputDate" className="mb-0 pb-0"><small>Период отчетности c</small></label>
                                    <input max={global.makeNormalDate(this.state.dateEnd,"yyyy-mm-dd") } ref={ref=>this.input.dateStart=ref} type="date" id="inputDate1" class="form-control mb-0" onChange={e=>{this.setState({dateStart:new Date(e.target.valueAsDate.setHours(0))}); debugger }}/>
                                </div>
                                <div class="form-group mt-0">
                                    <label for="inputDate2" className="mb-0 pb-0 mt-1"><small>по</small></label>
                                    <input min={global.makeNormalDate(this.state.dateStart,"yyyy-mm-dd") } ref={ref=>this.input.dateEnd=ref} type="date" id="inputDate2" class="form-control" onChange={e=>this.setState({dateEnd:new Date(e.target.valueAsDate.setHours(0))})}/>
                                </div>
                            </div>

                            <div className="col-3 bg-white border-light border-right scroll p-0 m-0">
                                <table className="table w-100 CONTS_OTCH" >
                                    <thead >
                                        <tr className="mainLabels">
                                            <td>Заявка</td>
                                            <td>Объект</td>
                                            <td>Метод НК</td>
                                            <td>Статус</td>
                                            <td>Исполнитель</td>
                                            {global.Auth.ROLE==6?<td>Заказчик</td>:null}
                                            {global.Auth.ROLE==6?<td>Заказчик e-mail</td>:null}
                                            {global.Auth.ROLE==6?<td>Заказчик телефон</td>:null}

                                            <td>Оветственный</td>
                                            <td>Монтажная организация</td>

                                            <td>Наименование узла</td>
                                            <td>Наименование линии</td>

                                            <td>№ Схемы</td>
                                            <td>№ Соединения</td>
                                            <td>Тип соединения</td>
                                            <td>Марка сварного материалла</td>
                                            <td>Марка стали</td>
                                            <td>Клеймо сварщика</td>
                                        </tr>
                                        
                                       
                                    </thead>
                                    {this.renderREQV_CONTs()}
                                </table>
                            </div>

                            <div className="col-lg-7 col-md-4 right row   m-0  p-0 scroll">
                                
                            <table class="table" ref={ref => this.ref.TABLE = ref}>
                            <thead >
                                {this.renderTimeLine()}
                                

                                
                            </thead>

                            
                                
                            </table>


                                   
                            </div> 
                        </div>
                    </div>
                </div>
    
    }
}


