import React from 'react';
import styled from 'styled-components';
import '../../css/File.css';


class File extends React.Component {
    constructor(props) {
        super()
        this.state = {
            
        }
 

      
    }

    ImgError(obj){
        obj.target.src="/server/img/files/simple.svg";
    }

    
   
    render (){
        return  (
        <FileView  onClick={(e)=>this.props.onClick(e)} selected={this.props.isSelected} className={`File pl-2 ${this.props.class}`} >
            {this.props.SHOW_DESCR===false?[
            
                this.props.EXT!=null&&this.props.SHOW_EXT_IMG?
                    <Img src={"/server/img/files/"+this.props.EXT+".svg"} onError={(e)=>this.ImgError(e)}/>
                :null,
                    <Title className="title" >{this.props.preTitle!=null?<Pre>{global.textCut(this.props.preTitle+"/",30,"../")}</Pre>:null}{this.props.TITLE}<small>{this.props.SHOW_EXT_TEXT?this.props.EXT:null}</small>
                    </Title>   
                ]
                :[
                        
                        this.props.EXT!=null&&this.props.SHOW_EXT_IMG?
                            <Img src={"/server/img/files/"+this.props.EXT+".svg"} onError={(e)=>this.ImgError(e)}/>
                        :null,
                        
                        <Title className="title" isSmall={this.props.TEXT!=null}>{this.props.preTitle!=null?<Pre>{global.textCut(this.props.preTitle+"/",30,"../")}</Pre>:null}{this.props.TITLE}<small>{this.props.SHOW_EXT_TEXT?this.props.EXT:null}</small></Title>,
 
                        this.props.DATE_TIME_LOAD!=null?
                            <F1 className="loadDate">{this.props.DATE_TIME_LOAD}</F1>
                        :null,

                        this.props.SIZE!=null?
                            <F2 className="size">{this.props.SIZE}</F2>
                        :null,

                        this.props.TEXT!=null?
                            <F3 className="text">{global.textCut(this.props.TEXT,150,"...")}</F3>
                        :null  
            
                ]
    
            }
        </FileView>
        )
    }
}

export default File;


const Pre = styled.pre`
opacity:0.5;
display:inline
`

const FileView = styled.div`
position: relative;
height: 30px;
margin-bottom: 2px;
padding: 5px;
transition: .3s;
border-radius: 5px;
box-shadow: 10px 10px 60px rgba(0,0,0,0);
cursor:pointer;
z-index:1;
${props=>props.selected?`
background: rgb(153, 191, 226);
transform: scale(1.01);
& p{
    color:black;
}
`:null}
&:hover{
    box-shadow: 10px 10px 60px rgba(0,0,0,0.05);
    transform: scale(1.01);
    z-index:2;
}
&:hover p{
    color:black;
}
& p{
    transition: .3s;
}
`


const Img = styled.img`
height: 20px;
width: 20px;
object-fit: contain;
position: absolute;
`
const Title = styled.p`
position: absolute;
margin-left: 25px;
width: ${props=>props.isSmall?`20vw`:`30vw`};
overflow: hidden;
height:20px;
font-size: 14px;
`

const F1 = styled.p`
position: absolute;
margin-left: calc(30vw + 25px);
color: rgb(144,144,144);
font-weight: 300;
font-size: 12px;
overflow: hidden;
height:20px;
width: 100px;
text-align: center;
`
const F2 = styled.p`
position: absolute;
margin-left: calc(30vw + 125px);
color: rgb(144,144,144);
font-weight: 300;
font-size: 12px;
overflow: hidden;
height:20px;
width: 100px;
text-align: center;
`
const F3 = styled.p`
position: absolute;
margin-left: calc(20vw + 25px);
color: rgb(144,144,144);
font-weight: 300;
overflow: hidden;
height:20px;
font-size: 12px;
width: 20vw;
text-align: center;
`


