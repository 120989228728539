import React from 'react';
import '../../css/Contacts.css';
import PageTitle from '../../Components/PageTitle'
import Folder from '../_1/Folder'
import File from '../_1/File'
import POP_Qestion from '../../Components/POP_Qestion'
import POP_CreateContact from './POP_CreateContact'



class Contacts extends React.Component {
    constructor(props) {
        super()
        this.state = {
            openImageURL:null,
            selectedDistID:1,
            selectedContactID:null,
            DIST:[],
            Contacts:[],
            ContactFiles:[]
        }
      this.input=[];
      this.saveTimer=null;
    }

    componentDidMount(){
        this.getDIST();
        this.getContacts();
        
        
    }

    getDIST(){
        global.SERVER_task('operation=getContactsDIST',
        (data)=>{
           this.setState({DIST:data.result});
        })
    }
    getContacts(){
        global.SERVER_task('operation=getContacts&DIST='+this.state.selectedDistID,
        (data)=>{
           this.setState({Contacts:data.result});
           
           if(this.state.selectedContactID!=null){
               for(let i=0;i<data.result.length;i++){
                   if(data.result[i].ID==this.state.selectedContactID){
                       this.openContact(data.result[i]);
                       break;
                   }
               }
           }
      
        })
        
    }

    openContact(JSON){
        this.setState({selectedContactID:JSON.ID});
        setTimeout(()=>{
            this.getFiles();

            Object.keys(this.input).forEach(key=>{
               
                this.input[key].value = JSON[key]
               })
        },20)
        
       
    }

    openDIST(ID_OBJECT){
       
        this.setState({selectedDistID:ID_OBJECT,selectedContactID:null});
        setTimeout(() => {
            this.setState({selectedContactID:null,ContactFiles:[]});
            this.getContacts();
        }, 20); 

        
    }
    DeleteCont(){
        if(global.Auth.ROLE<=2)
            global.PopUp.Open({content:<POP_Qestion text="Безвозвратно удалить контакт и файлы, содержащиеся в нём?" buttons={[
                ["Удалить",'danger',()=>this.DeleteCont_compl()],
                ["Отмена",'']
                ]}/>
            })
     }
     DeleteCont_compl(){
         global.SERVER_task('operation=deleteContact&ID='+this.state.selectedContactID,
             (data)=>{
                this.setState({selectedContactID:null,ContactFiles:[]});
                 this.getContacts();
             })
     }

    saveTimerCh(){
        if(global.Auth.ROLE<=2){
            if(this.saveTimer==null){
                this.saveTimer=setTimeout(()=>this.Save(),400);
            }else{
                clearTimeout(this.saveTimer);
                this.saveTimer=null;
                this.saveTimer=setTimeout(()=>this.Save(),300);
            }
        }else{
            let JSON=null;
            for(let i=0;i<this.state.Contacts.length;i++){
                if(this.state.Contacts[i].ID==this.state.selectedContactID){
                    JSON=this.state.Contacts[i];
                    break;
                }
            }
            this.input.TITLE.value=JSON.TITLE;
            this.input.TEXT.value=JSON.TEXT;
        }
    }
    Save(){
        if(global.Auth.ROLE<=2){
            var data={
                TITLE:this.input.TITLE.value,
                EMAIL:this.input.EMAIL.value,
                PHONE:this.input.PHONE.value,
                WORK:this.input.WORK.value,
                PODRAZD:this.input.PODRAZD.value,
                PLACE:this.input.PLACE.value,
                OBJECT:this.input.OBJECT.value,
                TEXT:this.input.TEXT.value,
                ID:this.state.selectedContactID
            };
        
        

            global.SERVER_task('operation=createUpdateContact&params='+JSON.stringify(data),
            (data)=>{
                
                this.getContacts();
            })
        }
    }

    getFiles(){
        //загрузка файлов контакта
        global.SERVER_task('operation=getContactsFiles&ID_CONTACT='+this.state.selectedContactID,
        (data)=>{
           this.setState({ContactFiles:data.result});
           debugger;

        })
    }



    UploadFile(obj){
        if(global.Auth.ROLE<=2){
            var folderID = this.state.selectedContactID;
             var form_data = new FormData();
             for(var i=0;i<obj.files.length;i++){
                 form_data.append('file'+i, obj.files[i]);
             }
             
             global.UploadFlieToServer_DEFOULT(form_data,folderID,()=>this.getFiles(),"contacts");   
             obj.value=null;
            
 
         }
    }
    RemoveFile(ID){
        if(global.Auth.ROLE<=2)
       global.PopUp.Open({content:<POP_Qestion text="Безвозвратно удалить файл?" buttons={[
            ["Удалить",'danger',()=>this.deleteFile(ID)],
            ["Отмена",'']
        ]}/>})

        
    }
    deleteFile(ID){
        if(global.Auth.ROLE<=2)
        global.SERVER_task('operation=delteFile_contact&ID_FILE='+ID,
        (data)=>{
            this.getFiles();
        })
    }
    
    render (){

        var list_DIST = this.state.DIST.map((JSON)=>
            <Folder class="scrollElement" 
                ID_OBJECT={JSON.ID}
                onClick={(ID_OBJECT)=>this.openDIST(ID_OBJECT)} 
                isSelected={this.state.selectedDistID==JSON.ID?true:false}
                title={JSON.TITLE}
                img={global.serverURL_Main+"/img/contacts/img_"+JSON.ID+".jpg"}
            />
        )

        var list_files = this.state.ContactFiles.map((JSON)=>
            <div className="col">
                {global.Auth.ROLE<=2?
                    <div className="remove" onClick={()=>this.RemoveFile(JSON.ID)}>x</div>
                :null}
                
                <a href={global.serverURL_Main+"/contacts/"+JSON.URL+".jpg"} target="blank">
                    <img src={global.serverURL_Main+"/contacts/"+JSON.URL+".jpg"} />
                </a>
            </div>
        )

        var list_contacts = this.state.Contacts.map((JSON)=>
            <File isSelected={this.state.selectedContactID==JSON.ID?true:false}  ID_OBJECT={JSON.ID} class="scrollElement" onClick={()=>this.openContact(JSON)} TITLE={JSON.TITLE}  TEXT={JSON.TEXT}/>
        )
        if(this.state.selectedContactID!=null)
            var content=<div>
                                <div className="row">
                                    <input type="text" placeholder="Наименование" class="form-control ml-3 mr-3 mb-2" 
                                        ref={ref => this.input.TITLE = ref} onKeyUp={()=>this.saveTimerCh()} onChange={()=>this.saveTimerCh()}/>

                                    <input type="text" placeholder="Почта" type="email" class="form-control ml-3 mr-3 mb-2" 
                                        ref={ref => this.input.EMAIL = ref} onKeyUp={()=>this.saveTimerCh()} onChange={()=>this.saveTimerCh()}/>
                                    <input type="text" placeholder="Телефон" type="phone" class="form-control ml-3 mr-3 mb-2" 
                                        ref={ref => this.input.PHONE = ref} onKeyUp={()=>this.saveTimerCh()} onChange={()=>this.saveTimerCh()}/>
                                    <input type="text" placeholder="Должность" class="form-control ml-3 mr-3 mb-2" 
                                        ref={ref => this.input.WORK = ref} onKeyUp={()=>this.saveTimerCh()} onChange={()=>this.saveTimerCh()}/>
                                    <input type="text" placeholder="Подразделение" class="form-control ml-3 mr-3 mb-2" 
                                        ref={ref => this.input.PODRAZD = ref} onKeyUp={()=>this.saveTimerCh()} onChange={()=>this.saveTimerCh()}/>
                                    <input type="text" placeholder="Участок" class="form-control ml-3 mr-3 mb-2" 
                                        ref={ref => this.input.PLACE = ref} onKeyUp={()=>this.saveTimerCh()} onChange={()=>this.saveTimerCh()}/>
                                    <input type="text" placeholder="Объект" class="form-control ml-3 mr-3 mb-2" 
                                        ref={ref => this.input.OBJECT = ref} onKeyUp={()=>this.saveTimerCh()} onChange={()=>this.saveTimerCh()}/>

                                    <textarea placeholder="Коментарий" class="form-control ml-3 mr-3 mb-2" 
                                        ref={ref => this.input.TEXT = ref} onKeyUp={()=>this.saveTimerCh()} onChange={()=>this.saveTimerCh()}/>
                                    
                                    {global.Auth.ROLE<=2?
                                        <div className="col-12">
                                            <label for="ffl" type="button" class="border btn btn-light btn-sm w-100 mb-1 ">Добавить документ</label>
                                            <input multiple type="file" id="ffl" accept="image/x-png,image/jpeg,image/jpg" hidden onChange={(e)=>this.UploadFile(e.target)}/>
                                        </div>
                                    :null}
                                    
                                </div>
                                {list_files.length>0?
                                    <div className="row">
                                        <div className="verticalScroll"  id="docs">
                                            <div className="scrollContent row row-cols-5">
                                                {list_files}
                                            </div>
                                        </div>
                                    </div>
                                :null}
                                
            </div>
        let bottom_buts=null;
        if(global.Auth.ROLE<=2 && this.state.selectedContactID!=null)
            bottom_buts = <div className="row downButs" >
                                <div className="col-6">
                                    <button onClick={()=>this.Save()} type="button" id="" class="btn btn-light w-100">Сохранить</button>
                                </div>
                               
                                    <div className="col-6">
                                        <button onClick={()=>this.DeleteCont()} type="button" id="denger" class="btn btn-light w-100">Удалить выбранную</button>
                                    </div>
                                
                            </div>
                        

        return  <div className="Contacts " >
           
                    <div className="container-fluid m-0" >
                        <div className="row main_pages">
                            <div className="col-md-5 left" >
                                <PageTitle 
                                    img='/server/img/contact.svg'
                                    title={<p>Раздел<br></br>контакты</p>}
                                />
                                {content}
                                {bottom_buts}
                                </div>
                            <div className="col-md-7 right pl-3 pt-0">
                                <div className="horizontalScroll pt-5 pb-3">
                                    <div className="scrollContent pl-3 pr-5">
                                        {list_DIST}
                                    </div>
                                </div>
                                <div className="whiteGrad TOP"></div>
                                <div className="verticalScroll" >
                                    <div className="scrollContent pl-5 pr-5 pb-5 pt-0 mb-5">
                                    <button onClick={()=>global.PopUp.Open({content:<POP_CreateContact DIST={this.state.selectedDistID} callBack={(NEW_ID)=>{
                                            this.setState({selectedContactID:NEW_ID});
                                            this.getContacts()
                                        }
                                    } />})}
                                        type="button" class="btn btn-light mb-2">Новая запись</button>
                                        {list_contacts}
                                    </div>
                                </div>
                                <div className="whiteGrad BOTTOM"></div>
                            </div>
                        </div>
                    </div>
                </div>
    
    }
}

export default Contacts;
