import React from 'react';
import '../../css/Folder.css';
import POP_Folder from './POP_Folder'


class Folder extends React.Component {
    constructor(props) {
        super()
        this.state = {
            
        }
 

      
    }

    Click(){
        if(this.props.canChange){
        if(global.Auth.ROLE<=2 || this.props.IS_IN_SELF_PRIVATE_FOLDER)
            global.onDBClick(this.props.ID_OBJECT,()=>{
                global.PopUp.Open({content:<POP_Folder ID_OBJECT={this.props.ID_OBJECT} callBack={()=>this.props.callBack()} DIST={this.props.DIST}/>})
            });
        }
        this.props.onClick(this.props.ID_OBJECT);
       
    }

    render (){
        return  <div onClick={()=>this.Click()} className={"Folder "+(this.props.isSelected?"Selected":"")+" "+this.props.class} >
                    <img src={this.props.img} onError={(e)=>e.target.src="./server/img/big-folder.svg"}/>
                    <p>{this.props.title}</p>
                </div>
    
    }
}

export default Folder;
