import React from 'react';
import '../css/Chat.css';


class Cont extends React.Component {
    constructor(props) {
        super()
        this.state = {
            
        }
      
    }

    render (){
        return  <div className="Cont w-100 container-fluid" >
                    <div className="row">
                       
                        <div className="col-6 p-0 text-center">
                            <button type="button" class="btn  btn-sm btn-primary "
                            onClick={()=>this.props.openPage("CONTACTS")}>Контакты</button>
                        </div>
                        <div className="col-6 p-0 text-center">
                            <button type="button" class="btn  btn-sm btn-primary ">Почта</button>
                        </div>
                    
                    </div>
                </div>
    
    }
}

export default Cont;
