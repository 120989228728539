import React from 'react';
import '../../css/POP.css';
import '../../css/POP_Folder.css';
import POP_Select from '../../Components/POP_Select'
import POP_Qestion from '../../Components/POP_Qestion'
import styled from 'styled-components';


class POP_CreateDirr extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedDistId:this.props.DIST,
            Dist:[],
            Users:[],
            Users_selected:[],
            IS_PRIVATE:0
        }
        this.input=[];
        
        this.AllWaysSelectedUsers=[];

    }
    componentDidMount(){
       
       this.getUsers();
       if(this.props.ID_OBJECT!=null)
        this.getFolder();
       
    }

    getFolder(){
        global.SERVER_task('operation=getFolders&ID='+this.props.ID_OBJECT+"&ID_PARENT="+this.props.ID_PARENT,
        (data)=>{
            this.input.FOLDER_NAME.value=data.result[0].TITLE;
            this.setState({
                    selectedDistId:data.result[0].DIST,
                    IS_PRIVATE:data.result[0].IS_PRIVATE
                })
        })

        global.SERVER_task('operation=getFolder_Users&ID='+this.props.ID_OBJECT,
        (data)=>{
            var users=[];
            for(var i=0;i<data.result.length;i++){
                users.push(data.result[i].ID_USER);
            }
            this.setState({
                Users_selected:users
                })
        })
    }

   

    getUsers(){
        global.SERVER_task('operation=getUsers',
        (data)=>{
            this.AllWaysSelectedUsers=[];
            for(var i=0;i<data.result.length;i++){
                data.result[i].TITLE=data.result[i].FIO;
                if(data.result[i].ROLE*1<=2)
                    this.AllWaysSelectedUsers.push(data.result[i].ID);
            }
            
            this.setState({Users:data.result});   
        })
    }


    Save(){
        if(global.checkReqvireds(this.input)){
            var data={
                TITLE:this.input.FOLDER_NAME.value,
                DIST:this.props.ID_MAIN_FOLDER,
                ID_PARENT:this.props.ID_PARENT,
                IS_PRIVATE:this.state.IS_PRIVATE,
                Users_selected:this.state.Users_selected
            };
            if(this.props.ID_OBJECT!=undefined)
                data.ID=this.props.ID_OBJECT;
           
           

            global.SERVER_task('operation=createUpdateFolder&params='+JSON.stringify(data),
            (data)=>{
                this.props.callBack();
                global.PopUp.CloseFunction();
                
            })

           

        }
        
        
        
    }

  
    Delete(){
       global.PopUp.Open({content:<POP_Qestion text="Безвозвратно удалить папку и файлы, содержащиеся в ней?" buttons={[
            ["Удалить",'danger',()=>this.deleteFolder()],
            ["Отмена",'']
            ]}/>
        })
    }
    deleteFolder(){
        global.SERVER_task('operation=deleteFolder&ID='+this.props.ID_OBJECT,
            (data)=>{
                this.props.openParentFolder()
                global.PopUp.CloseFunction()
            })
    }

    setUsers(Users){
        this.setState({Users_selected:Users});
    }
   
    selectDist(ID){
        this.setState({selectedDistId:ID});
    }

    render (){
        for(var i=0;i<this.state.Dist.length;i++){
            if(this.state.Dist[i].ID==this.state.selectedDistId){
                var Dist_title=this.state.Dist[i].TITLE;
                break;
            }
        }

var list_folders=this.state.Dist.map((JSON)=>
    <a class={"dropdown-item "+(this.state.selectedDistId==JSON.ID?"bg-primary":"")} onClick={()=>this.selectDist(JSON.ID)}>{JSON.TITLE}</a>
);


if(this.props.ID_OBJECT==null)
    var buttons =  <div className="row">
                        <div key={global.getRandomInt(9999)} className="col ">
                            <button type="button" onClick={()=>this.Save()} class="w-100 btn btn-primary ">Создать</button>
                        </div>
                        <div key={global.getRandomInt(9999)} className="col">
                            <button type="button" onClick={()=>global.PopUp.CloseFunction()} class="w-100 btn">Отмена</button>
                        </div>
                    </div>
else 

    var buttons =  <div className="row">
                        <div key={global.getRandomInt(9999)} className="col">
                            <button type="button" onClick={()=>this.Save()} class="w-100 btn btn-primary">Сохранить</button>
                        </div>
                        <div key={global.getRandomInt(9999)} className="col">
                            <button type="button" onClick={()=>this.Delete()} class="w-100 btn">Удалить</button>
                        </div>
                    </div>

        
        return <CreateDirr className="POP  content-fluid">
                    
                       
                        <p className="mb-0">Название дочерней папки</p>
                        <input autoFocus type="text" ref={ref => this.input.FOLDER_NAME = ref} class="form-control mb-2" placeholder="Новая папка" />

                       

                        <p className="mb-0">Доступ к папке</p>
                        <div className="row p-0 m-0">
                            <div className="col-6 p-0 pr-1 m-0">
                                    <button type="button" className={"btn btn-sm w-100 mb-4 "+(this.state.IS_PRIVATE==1?"btn-primary":"btn-light")}
                                    onClick={
                                        ()=>{
                                            this.setState({IS_PRIVATE:1});
                                            global.PopUp.Open({content:<POP_Select
                                            ELEMENTS={this.state.Users}
                                            SELECTED={this.state.Users_selected}
                                            MEGA_SELECTED={this.AllWaysSelectedUsers}
                                            MEGA_SELECTED_POSTFIX="admin"
                                            callBack={(Users)=>this.setUsers(Users)}
                                            />});
                                        }
                                    }
                                >Доступно только для ...</button>
                            </div>
                            <div className="col-6 p-0 pl-1 m-0">
                                    <button type="button" className={"btn btn-sm  w-100 mb-4 "+(this.state.IS_PRIVATE==1?"btn-light":"btn-primary")} 
                                    onClick={
                                        ()=>{
                                            this.setState({IS_PRIVATE:0});
                                            global.PopUp.Open({content:<POP_Select
                                            ELEMENTS={this.state.Users}
                                            SELECTED={this.state.Users_selected}
                                            MEGA_SELECTED={[]}
                                            MEGA_SELECTED_POSTFIX="admin"
                                            callBack={(Users)=>this.setUsers(Users)}
                                            />});
                                        }
                                    }>Доступна всем, кроме ...</button>
                            </div>
                        </div>

                       
                    <br></br>
                   {buttons}
                    
                </CreateDirr>
    
    }
}


export default POP_CreateDirr;

const CreateDirr = styled.div`
width:400px
`