import React from 'react';
import '../../css/POP.css';
import POP_Qestion from '../../Components/POP_Qestion'
import '../../css/POP_ChangeUser.css';


class POP_ChangeUser extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            User:{
                STATUS:null
            }
        }
        this.input=[];
       

    }
    
    componentDidMount(){
       this.getUserInfo();
    }

    getUserInfo(){
       
        global.SERVER_task('operation=getUsers&ID_USER='+this.props.ID_OBJECT,
        (data)=>{
            this.setState({User:data.result[0]});
            this.input.FIO.value=data.result[0].FIO;
            this.input.LOGIN.value=data.result[0].LOGIN;
            this.input.PASSWORD.value=data.result[0].PASSWORD;
            this.input.EMAIL.value=data.result[0].EMAIL;
            this.input.PHONE.value=data.result[0].PHONE;
            this.input.DOLJNOST.value=data.result[0].DOLJNOST;
            this.input.PODRAZD.value=data.result[0].PODRAZD;
            this.input.PLACE.value=data.result[0].PLACE;
            this.input.ZAKAZCHIK.value=data.result[0].ZAKAZCHIK;
        })
    }

    Save(){
        var data={
            ID_USER:this.props.ID_OBJECT,
            FIO:this.input.FIO.value,
            LOGIN:this.input.LOGIN.value,
            PASSWORD:this.input.PASSWORD.value,
            EMAIL:this.input.EMAIL.value,
            PHONE:this.input.PHONE.value,
            DOLJNOST:this.input.DOLJNOST.value,
            PODRAZD:this.input.PODRAZD.value,
            PLACE:this.input.PLACE.value,
            ZAKAZCHIK:this.input.ZAKAZCHIK.value

        };
        global.SERVER_task('operation=updateUserData&params='+JSON.stringify(data),
        (data)=>{
            try{
                this.props.callBack(this.props.ID_OBJECT);
            }catch{}
            global.PopUp.CloseFunction();
        })
    }

    Block(){
        if(this.state.User.STATUS!=2)
            global.PopUp.Open({content:<POP_Qestion text="Заблокирвоать акаунт пользователя?" buttons={[
                ["Заблокировать",'danger',()=>this.Block_complite()],
                ["Отмена",'']
                ]}/>
            })
        else
            global.PopUp.Open({content:<POP_Qestion text="Разблокировать акаунт пользователя?" buttons={[
                ["Разблокировать",'danger',()=>this.Anblock_complite()],
                ["Отмена",'']
                ]}/>
            })
        
    }

    Block_complite(){
        var data={
            ID_USER:this.props.ID_OBJECT
        };
        global.SERVER_task('operation=blockUser&params='+JSON.stringify(data),
        (data)=>{
            this.getUserInfo();
            try{
                this.props.callBack(this.props.ID_OBJECT);
            }catch{}
            global.PopUp.CloseFunction();
        })
    }

    Anblock_complite(){
        var data={
            ID_USER:this.props.ID_OBJECT
        };
        global.SERVER_task('operation=anblockUser&params='+JSON.stringify(data),
        (data)=>{
            this.getUserInfo();
            try{
                this.props.callBack(this.props.ID_OBJECT);
            }catch{}
            global.PopUp.CloseFunction();
        })
    }

    Delete(){
        global.PopUp.Open({content:<POP_Qestion text={"Безвозвратно удалить пользователя?"} buttons={[
            ["Да, удалить",'danger',()=>this.Delete_complite()],
            ["Отмена",'']
            ]}/>
        })
    }
    Delete_complite(){
        global.SERVER_task('operation=deleteUser&ID_USER='+this.props.ID_OBJECT,
        (data)=>{
            try{
                this.props.callBack();
            }catch{}
            global.PopUp.CloseFunction();
        })
    }


    DeletePermissions(){
        global.PopUp.Open({content:<POP_Qestion text={"Удалить доступы ко всем файлам и папкам?"} buttons={[
            ["Да, удалить",'danger',()=>this.DeletePermissions_complite()],
            ["Отмена",'']
            ]}/>
        })
    }
    DeletePermissions_complite(){
        var data={
            ID_USER:this.props.ID_OBJECT
        };
        global.SERVER_task('operation=deleteUserPermissions&params='+JSON.stringify(data),
        (data)=>{
            this.getUserInfo();
            try{
                this.props.callBack(this.props.ID_OBJECT);
            }catch{}
            global.PopUp.CloseFunction();
        })
    }
    
    render (){
      
        
        return <div className="POP POP_ChangeUser content-fluid">
            <div class="input-group mb-2">
                <div class="input-group-prepend">
                    <span class="input-group-text"  >ФИО</span>
                </div>
                    <input  ref={ref => this.input.FIO = ref} class="form-control" />
            </div>
            <div class="input-group mb-2">
                <div class="input-group-prepend">
                    <span class="input-group-text"  >Логин</span>
                </div>
                    <input  ref={ref => this.input.LOGIN = ref} class="form-control" />
            </div>
            <div class="input-group mb-2">
                <div class="input-group-prepend">
                    <span class="input-group-text"  >Пароль</span>
                </div>
                    <input  ref={ref => this.input.PASSWORD = ref} class="form-control" placeholder="*********" />
            </div>


            <div class="input-group mb-2">
                <div class="input-group-prepend">
                    <span class="input-group-text"  >E-mail</span>
                </div>
                    <input type="email" ref={ref => this.input.EMAIL = ref} class="form-control" placeholder="ex@mail.ex" />
            </div>
            <div class="input-group mb-2">
                <div class="input-group-prepend">
                    <span class="input-group-text"  >Телефон</span>
                </div>
                    <input type="email" ref={ref => this.input.PHONE = ref} class="form-control" placeholder="+79000000000" />
            </div>


            <div class="input-group mb-2">
                <div class="input-group-prepend">
                    <span class="input-group-text"  >Должность</span>
                </div>
                    <input type="email" ref={ref => this.input.DOLJNOST = ref} class="form-control"  />
            </div>
            <div class="input-group mb-2">
                <div class="input-group-prepend">
                    <span class="input-group-text"  >Руководитель подразделения</span>
                </div>
                    <input type="email" ref={ref => this.input.PODRAZD = ref} class="form-control"  />
            </div>
            <div class="input-group mb-2">
                <div class="input-group-prepend">
                    <span class="input-group-text"  >Участок</span>
                </div>
                    <input type="email" ref={ref => this.input.PLACE = ref} class="form-control"  />
            </div>
            <div class="input-group mb-2">
                <div class="input-group-prepend">
                    <span class="input-group-text"  >Заказчик</span>
                </div>
                    <input type="email" ref={ref => this.input.ZAKAZCHIK = ref} class="form-control"  />
            </div>
                    
                    <div className="row">
                       <div key={global.getRandomInt(9999)} className="col">
                            <button type="button" onClick={()=>this.Save()} class="w-100 btn btn-primary">Сохранить изменения</button>
                        </div>
                        <div key={global.getRandomInt(9999)} className="col">
                        <div class="dropdown w-100">
                            <button class="btn btn-light w-100  dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Другие действия
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item" onClick={()=>this.Delete()}>Удалить пользователя</a>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" onClick={()=>this.Block()}>{this.state.User.STATUS==2?"Разблокировать доступ к системе":"Заблокировать доступ к системе"}</a>
                                <a class="dropdown-item" onClick={()=>this.DeletePermissions()}>Убрать все права доступа к файлам и папкам</a>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
    
    }
}

export default POP_ChangeUser;
