import React from 'react';
import '../../css/POP.css';
import '../../css/POP_reqv.css';
import POP_Qestion from '../../Components/POP_Qestion'
import styled from 'styled-components';


export default class POP_reqv_item extends React.Component {
    constructor(props) {
        super()
        this.state = {
            REQV_ITEM:{},
            REQV_JOI:{},
            CONTROL_STATUSES:[],
            MainFileds:null,
            Proverka:null
        }


 
        this.input=[];

        this.Mainfields=[
            {
                name:"CONTROL_PLACE",
                descr:"Название"
            },
            {
                name:"DESCR",
                type:"textarea",
                className:"t1",
                descr:"Примечание"
            }
        ]

        this.Proverka=[
            {
                name:"DEFECT_TEXT",
                type:"textarea",
                className:"t1",
                descr:"Выявленные дефекты"
            }
        ]

        
    }
    componentDidMount(){

        
  
        if(this.props.ID_OBJECT==null){
            var data={
                ID_REQV_JOIN:this.props.ID_REQV_JOIN
            }
            
            global.SERVER_task('operation=createUpdateREQV_ITEM&params='+JSON.stringify(data),
            (data)=>{
                
                try{
                    this.props.callBack();
                }catch{}
                
                this.getREQV_ITEMS(data.result).then(()=>this.getREQV_JOINS())
            
            })
         }else{
            this.getREQV_ITEMS().then(()=>this.getREQV_JOINS())
            this.getCONTROL_STATUSES();
         }
    }

    getREQV_ITEMS(ID){
        return new Promise(resolve=>{
            if(ID==null) ID=this.props.ID_OBJECT
            global.SERVER_task('operation=getREQV_ITEMS&ID='+ID,
            (data)=>{
               
                this.setState({REQV_ITEM:data.result[0]})
                
                this.setState({
                    MainFileds:global.genFields(this,this.Mainfields,1),
                    Proverka : global.genFields(this,this.Proverka,data.result[0].ID_RESPONSIBLE_PERSON==global.Auth.ID_USER?1:0)
                })
                this.Mainfields.map((field)=>{
                    this.input[field.name].value=data.result[0][field.name];
                })
                setTimeout(()=> resolve(),5)               
            })
        })
    }

    getREQV_JOINS(){
        return new Promise(resolve=>{
            global.SERVER_task('operation=getREQV_JOINS&ID='+this.state.REQV_ITEM.ID_REQV_JOIN,
            (data)=>{
                this.setState({REQV_JOI:data.result[0]});
                setTimeout(()=>{
                    try{ 
                        this.input["DEFECT_TEXT"].value=this.state.REQV_ITEM.DEFECT_TEXT;
                    }catch{}
                },10)
                
                setTimeout(()=>resolve(),5)
            })
        })
    }

    



    getCONTROL_STATUSES(){
        global.SERVER_task('operation=getCONTROL_STATUSES',
        (data)=>{
            this.setState({CONTROL_STATUSES:data.result});
        })
    }
   
    Save(){
        if(!global.checkReqvireds([this.input.CONTROL_PLACE])) return;
        let data={};

        this.Mainfields.map((field)=>{
            data[field.name]=this.input[field.name].value;
        })
        data.ID=this.state.REQV_ITEM.ID

        if(this.state.REQV_JOI.ID_RESPONSIBLE_PERSON==global.Auth.ID_USER){
            data.DEFECT_TEXT=this.input.DEFECT_TEXT.value
        }

        global.SERVER_task('operation=createUpdateREQV_ITEM&params='+JSON.stringify(data),
        (data)=>{
            global.PopUp.CloseFunction();
            try{
                this.props.callBack();
            }catch{}
        })
    }

    setStatus(ID){
        if(!global.checkReqvireds(this.input,["CONTROL_PLACE","DESCR"])) return;
        let data={
            ID:this.state.REQV_ITEM.ID,
            DEFECT_TEXT:this.input.DEFECT_TEXT.value,
            ID_CONTROL_STATUS:ID
        };

        global.SERVER_task('operation=itemSetStatus&params='+JSON.stringify(data),
        (data)=>{
            global.PopUp.CloseFunction();
            try{
                this.props.callBack();
            }catch{}
  
        })
    }

   

    deleteITEM(){
        let data={
            ID:this.state.REQV_ITEM.ID
        };
        

        global.SERVER_task('operation=deleteREQV_ITEM&params='+JSON.stringify(data),
        (data)=>{
            if(data.result=="ok"){
                global.PopUp.CloseFunction();
                try{
                    this.props.callBack();
                }catch{}
            }else{
                global.PopUp.Open({content:<POP_Qestion text="Невозможно удалить"/>})
            }
        })
    }

    renderButtons(){
        const {REQV_ITEM,REQV_JOI} = this.state

        let content=[
            <button type="button" onClick={()=>this.Save()} class=" btn btn-sm m-1 btn-primary">Обновить</button>,
            <button type="button" onClick={()=>this.deleteITEM()} class=" btn btn-sm m-1 btn-danger">Удалить</button>
        ]


        if(REQV_JOI.ID_RESPONSIBLE_PERSON==global.Auth.ID_USER)
            content.push(
                <div class="dropdown m-1 float-right">
                    <button class="btn btn-sm btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Установить статус
                    </button>
                    <div class="dropdown-menu p-0" aria-labelledby="dropdownMenuButton">
                        {this.state.CONTROL_STATUSES.map((JSON)=>
                            <a onClick={()=>this.setStatus(JSON.ID)}
                                className={`dropdown-item ${(JSON.ID!=5?"text-white":"")} si-${JSON.ID}`}
                            >{JSON.TITLE}</a>
                        )}
                    </div>
                </div> 
            )
            
        return <div className="col-12">{content}</div>
    }
   

    render (){
        const {REQV_ITEM,REQV_JOI,MainFileds,Proverka} = this.state
        
        
        

        return(
        <div className="POP POP_reqv POP_reqv_item content-fluid " >
            <div className="row">
                <div className="col-12">

                    <h5 className='d-inline'>{REQV_JOI.ID_METHOD==1?"Пленка":"Участок"}</h5>
                    <small className='d-inline float-right text-right'>
                        {REQV_ITEM.CONTROL_STATUS_TITLE}
                        <br/>
                        <Sss>{global.makeNormalDate(REQV_ITEM.DATE_CONTROL,"dd.mm.yyyy H:M")}</Sss>
                    </small>

                    <br></br>
                    <div className="scroller">
                        
                        {MainFileds}
                        {REQV_JOI.ID_RESPONSIBLE_PERSON==global.Auth.ID_USER?[
                            <p className="mb-0 mt-2">Исследование</p>,
                            Proverka
                        ]:null}
                        
                    </div>
                </div>
                
            </div>

            <div className="row mt-3">
                {this.renderButtons()}
            </div>
        </div>)
    
    }
}

const Sss = styled.div`
font-size:10px;
color:rgb(150,150,150)
`