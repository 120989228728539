import axios from 'axios/lib/axios';
import $ from 'jquery/dist/jquery.min.js'; 
import React  from 'react';
import POP_Qestion from './View/Components/POP_Qestion'

if(window.location.hostname=="localhost")
  global.serverURL_Main="https://filemanager.lpn.su/server/"; 
else
  global.serverURL_Main="/server/"; 
//global.serverURL_Main="http://213.139.211.159/server/"; 
global.API={
  SEND_MAIL:"http://3.dsf.z8.ru/API/fileManager/sendMail.php",
  DOMAIN_CHECK:"http://3.dsf.z8.ru/API/AllowedDomains/checkDomain.php"
}
global.serverURL=global.serverURL_Main+"task.php?";

global.SPR=[]//справочник
global.Auth={
  ROLE:null,
  ID_USER:null
}

global.CURRENT_PAGE=null;
global.TIMERS=[];
global.dblClick_timer={
  timer:null,
  clickId:null
}


global.PopUp={
    PreviosPopUp_key:[],
    ActualPopUp_key:null,
    CloseFunction:null,
    open:null 
};
global.Toast={
    /*
        {
        text:"Text message",
        title:"Title",
        buttons:[["Ответить1",this.showAlert]]
        }
    */
    Show:null
}




/* Шпаргалка по окончаниям
t1 -мешок  (1)
t2 -мешка  (2,22)
t3 -мешков (5,19,14)
*/
global.okonchanie=(count,t1,t2,t3)=>okonchanie(count,t1,t2,t3);


global.genFields=(Context,fields,STATUS)=>genFields(Context,fields,STATUS);

global.makeNormalDate=(Date,format)=>makeNormalDate(Date,format);
global.makeNormalChislo=(str)=>makeNormalChislo(str);
global.API_task=(API_URL,GET_ARGS_ARRAY,callBack)=>API_task(API_URL,GET_ARGS_ARRAY,callBack);


global.SERVER_task=(URL,callBack,nullErrorFunction)=>SERVER_task(URL,callBack,nullErrorFunction);
global.UploadFlieToServer=(dir,fileName,file_data)=>UploadFlieToServer(dir,fileName,file_data);
global.UploadFlieToServer_DEFOULT=(file_data,folderID,callBack,folderIND)=>UploadFlieToServer_DEFOULT(file_data,folderID,callBack,folderIND);
global.getRandomInt=(max)=>getRandomInt(max);

global.RoleCheck=(admin_elem,user_elem)=>RoleCheck(admin_elem,user_elem);

global.mydate=(date)=>mydate(date);

global.deleteCookie=(name)=>deleteCookie(name);
global.getCookie=(name)=>getCookie(name);
global.setCookie=(name, value, options)=>setCookie(name, value, options);

global.checkReqvireds=(arrayReqv,ignorArray)=>checkReqvireds(arrayReqv,ignorArray);
global.smollFIO=(fio)=>smollFIO(fio);

global.checkFile=(url,function_ok,function_not)=>checkFile(url,function_ok,function_not);

global.addTimer=(id,func,interval)=>addTimer(id,func,interval);
global.onDBClick=(clickId,func)=>onDBClick(clickId,func);
global.scrollHorizontally=(e)=>scrollHorizontally(e);
global.textCut=(text,lenght,postfix)=>textCut(text,lenght,postfix);

global.getMonth_Title=(monthNumber,needSmoll)=>getMonth_Title(monthNumber,needSmoll);
global.getWeekDay_Title=(weekDayNumber,needSmoll)=>getWeekDay_Title(weekDayNumber,needSmoll);
global.addDays=(date,days)=>addDays(date,days);


function addDays(date,days){
  return new Date(date.getTime()+(days*24*3600000))
}

function getMonth_Title(monthNumber,needSmoll=false){
  switch(monthNumber){
    case 0: return needSmoll?"Янв":"Январь";
    case 1: return needSmoll?"Фев":"Февраль";
    case 2: return needSmoll?"Мар":"Март";
    case 3: return needSmoll?"Апр":"Апрель";
    case 4: return needSmoll?"Май":"Май";
    case 5: return needSmoll?"Июн":"Июнь";
    case 6: return needSmoll?"Июл":"Июль";
    case 7: return needSmoll?"Авг":"Август";
    case 8: return needSmoll?"Сен":"Сентябрь";
    case 9: return needSmoll?"Окт":"Октябрь";
    case 10:return needSmoll?"Ноя":"Ноябрь";
    case 11:return needSmoll?"Дек":"Декабрь";
  }
}

function getWeekDay_Title(weekDayNumber,needSmoll=false){
  switch(weekDayNumber){
    case 0: return needSmoll?"ВС":"Воскресенье";
    case 1: return needSmoll?"ПН":"Понедельник";
    case 2: return needSmoll?"ВТ":"Вторник";
    case 3: return needSmoll?"СР":"Среда";
    case 4: return needSmoll?"ЧТ":"Четверг";
    case 5: return needSmoll?"ПТ":"Пятница";
    case 6: return needSmoll?"СБ":"Суббота";
    
  }
}

function scrollHorizontally(e) {
  e = window.event || e;
  var delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));
 
  e.target.scrollLeft -= (delta*10); // Multiplied by 10
  e.preventDefault();
}

function onDBClick(clickId,func){

  if(global.dblClick_timer.timer!=null && global.dblClick_timer.clickId == clickId){
  
    try{
    func(); 
    }catch{}
    clearTimeout(global.dblClick_timer.timer);
    global.dblClick_timer.timer=null;
    global.dblClick_timer.clickId=null;
  }else{
    global.dblClick_timer.clickId = clickId;
    global.dblClick_timer.timer=setTimeout(()=>{
        clearTimeout(global.dblClick_timer.timer);
        global.dblClick_timer.timer=null;
        global.dblClick_timer.clickId=null;
    },600);
  }
}

function addTimer(id,func,interval){
  for(var i=0;i<global.TIMERS.length;i++){
    if(global.TIMERS==id){
      return;
    }
  }
  global.TIMERS.push(id);
  setInterval(func,interval);
}


function makeNormalDate(Date_,format){
try{
  try{
  var y = Date_.getFullYear();
  }catch{
var tmpD=Date_;
Date_=Date_.replace(/ /g,"T")
    Date_=new Date(Date_);
   
    if(Date_.getFullYear()>0){}else
      Date_=new Date("1998-11-26T"+tmpD);

     
      var y = Date_.getFullYear();

  }
 
  var m = Date_.getMonth()+1; if(m<10) m="0"+m;
  var d = Date_.getDate();   if(d<10) d="0"+d;

  var H = Date_.getHours();   if(H<10) H="0"+H;
  var M = Date_.getMinutes();   if(M<10) M="0"+M;
  var S = Date_.getSeconds();   if(S<10) S="0"+S;
  var I = Date_.getMilliseconds();  

  format= format.replace("yyyy",y);
  format= format.replace("dd",d);
  format= format.replace("mm",m);

  format= format.replace("H",H);
  format= format.replace("M",M);
  format= format.replace("S",S);
  format= format.replace("I",I);
 
  return format;
}catch{return ""}
}


function makeNormalChislo(str){
  return (str+"").replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
}



function checkFile(url,function_ok,function_not){
  
    var img = new Image();
    img.onload = function_ok; 
    img.onerror = function_not;
    img.src = url;
 
}

function okonchanie(count,t1,t2,t3){
  if(count%10 >1 && count%10 <5 && !(count>=10 && count<=15)){
    return t2;
  }else if((count%10 >=5 && count%10 <=9)||count%10 ==0 || (count>=10 && count<=15)){
    return t3;
  }else{
    return t1;
  }
}



function deleteCookie(name) {
  setCookie(name, "", {
    'max-age': -1
  })
}

function checkReqvireds(arrayReqv,ignorArray=[]){
  var isOk=true;
  try{
  Object.keys(arrayReqv).forEach(key => {
      
    var itIgnor=false;
    for(var i=0;i<ignorArray.length;i++){
      if(ignorArray[i]==key){
        itIgnor=true;
        break;
      }
    }
    
      if(!itIgnor&&((arrayReqv[key].value==null  || arrayReqv[key].value.length==0 || arrayReqv[key].value==-1) && key!='file')){
          arrayReqv[key].classList.add("border-danger");
         
          isOk=false;
      }else{
          
          arrayReqv[key].classList.remove("border-danger");
      }
      
  });
}catch{}
  return isOk;
}


function getCookie(name) {
  let matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

function setCookie(name, value, options = {}) {
  

  options = {
    path: '/',
    'max-age':9000
    // при необходимости добавьте другие значения по умолчанию...options
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }
  

  document.cookie = updatedCookie;
}

function RoleCheck(admin_elem,user_elem){
  if(global.Auth.ROLE<=2)
    return admin_elem;
  else
    return user_elem;
}

function API_task(API_URL,GET_ARGS_ARRAY,callBack){
  var ARGS="?";
 
    
    Object.keys(GET_ARGS_ARRAY).forEach(function (key) {
     
      if(ARGS!="?")
        ARGS+="&";
      var itJSON=false;
      try{
        if(JSON.stringify(GET_ARGS_ARRAY[key])[0]=="{"){
          itJSON=true;
        }
      }catch{}
      if(itJSON)
        GET_ARGS_ARRAY[key]=JSON.stringify(GET_ARGS_ARRAY[key]);
      ARGS=ARGS+key+"="+GET_ARGS_ARRAY[key]
      
    });
  
  axios.get(API_URL+ARGS)
    .then( (response) => {
      if(response.data.resultError!=undefined){
        global.PopUp.Open(
          {content:<POP_Qestion title="Ошибка" text={response.data.resultError} footer={response.data.description}/>}
        )
        return
      }

      try{
        callBack(response.data);
      }catch(Exr){
        global.PopUp.Open(
          {content:<POP_Qestion title="Ошибка" text={Exr.message}/>}
        )
      }
    });
}

function textCut(text,lenght,postfix=""){
  if(text.length>lenght)
    text=text.substr(0,lenght)+postfix;
  return text;
}

function genFields(THIS,fields,STATUS){

  var arr=[];
  fields.map((field)=>{
      var input=null;
      switch(field.type){

          case "textarea":input = <textarea
            placeholder={field.placeholder}
            style={field.style}
            key={"sdcsdfcs"+field.name}
            onKeyUp={field.onChange}
            disabled={STATUS!=1||field.disabled}
            ref={ref => THIS.input[field.name] = ref} 
            className={"form-control "+field.className}
            autoComplete="new-password" />
          break; 


          case "select":input = <select style={field.style} key={"svfvg"+global.getRandomInt(100000000000)} onChange={(e)=>{
            if(e.target.value == -100){
              e.target.value=null
              THIS.input[field.name].value=null
            }
            try{
              field.onChange()
            }catch(ex){
              console.error(ex)
            }
          }} disabled={STATUS!=1||field.disabled} ref={ref => THIS.input[field.name] = ref} className={"form-control "+field.className} autoComplete="new-password">
            <optgroup label="Очистить поле">
              <option key={"wdcsd"+global.getRandomInt(100000000000)} value={-100}>Не указано</option>
            </optgroup>
            <optgroup label="Значения">
              {field.values!=null || field.VALUE!=null?
                field.values.map((value)=>{
                  if(value.value==null && value.VALUE!=null){
                    value.value=value.ID;
                    value.text=value.VALUE;
                  }
                return <option key={"wdcsd"+global.getRandomInt(100000000000)} value={value.value}>{value.text!=null?value.text:value.value}</option>
                }):null}
              </optgroup>
          </select>
              break;


          default:
          case "input":input = <input type={field.type!=null?field.type:"text"} min={field.min} max={field.max} placeholder={field.placeholder} style={field.style} onKeyUp={field.onChange} disabled={STATUS!=1||field.disabled}  ref={ref => THIS.input[field.name] = ref} className={"form-control "+field.className} autoComplete="new-password" />
              break;

      }
      
      

      arr.push(
      <div>
          <small className="text-muted">{field.descr}</small>
          {input}
      </div>
      )
  })
  return arr;
}

function SERVER_task(URL,callBack,nullErrorFunction){

  try{
    //console.log(global.serverURL+URL);
    console.log("SERVER to "+URL);
    axios.get(global.serverURL+URL)
    .then( (response) => {
      
      console.log("SERVER: ");
      console.log(response.data);
      if(response.data!=null && response.data!=undefined){
        
        if(nullErrorFunction!=undefined){
          if(response.data.resultError!=undefined){
            debugger;
            if(response.data.resultError.indexOf("Сессия завершена")>0){
              setTimeout(()=>window.location.reload(),2000);
            }
            global.PopUp.Open(
              {content:<POP_Qestion title="Ошибка" text={response.data.resultError} footer={response.data.description}/>}
            )
           
            nullErrorFunction();
            return;
          }
          if(response.data.result[0]==undefined){
              
            nullErrorFunction();
            return;
          }else{
            try{
              response.data.result=JSON.parse(response.data.result);
            }catch{}
            if(response.data.resultError!=null){
              if(response.data.resultError.indexOf("Сессия завершена")>0){
                setTimeout(()=>window.location.reload(),2000);
              }
              global.PopUp.Open({content:<POP_Qestion 
                title="Ошибка" text={response.data.resultError} footer={response.data.description}
              />})
             
            }
            console.log("SERVER result "+URL);
            console.log(response.data);
            callBack(response.data);
          }
        }
        else{
          try{
            response.data.result=JSON.parse(response.data.result);
          }catch{}
          if(response.data.resultError!=null){
            if(response.data.resultError.indexOf("Сессия завершена")>0){
              setTimeout(()=>window.location.reload(),2000);
            }
            global.PopUp.Open({content:<POP_Qestion 
              title="Ошибка" text={response.data.resultError} footer={response.data.description}
            />})
          }
          console.log("SERVER result "+URL);
          console.log(response.data);
          callBack(response.data);
        }
      }else{
        global.PopUp.Open({content:<POP_Qestion 
          title="Ошибка" text="NULL SERVER DATA"
        />})
        console.log("SERVER: NULL DATA");
      }
    })
    .catch( (error) => {
      console.log(error);
      
      global.PopUp.Open({content:<POP_Qestion 
        title="Ошибка" text={error.message}
      />})
    }); 
  }catch(Ext){
    global.PopUp.Open({content:<POP_Qestion 
      title="Ошибка" text={Ext.message}
    />})
  }
}

function smollFIO(fio){
  var t = fio.split (' ');
 
  if(t.length==1)
    var shortFIO = fio;
  if(t.length==2)
    var shortFIO = t [0] + ' ' + t [1][0] +".";
  if(t.length>2)
    var shortFIO = t [0] + ' ' + t [1][0]+ '. ' + t [2][0] + '.';
  return shortFIO
}

function UploadFlieToServer(fileType,fileID,file_data){
  
  file_data.append("ID",fileID);
  file_data.append("fileType",fileType);
  
  $.ajax({
    url: global.serverURL+"operation=UploadFlieToServer",
    dataType: 'text',
    cache: false,
    contentType: false,
    processData: false,
    data: file_data,
    type: 'post',
    success: (php_script_response)=>{
     
      var result = JSON.parse(php_script_response);
      
      if(result.resultError!=undefined)
        global.PopUp.Open({content:<POP_Qestion 
          title="Ошибка" text={result.resultError} footer={result.description}
        />})
         
      
    }
  });
}

function UploadFlieToServer_DEFOULT(file_data,folderID,callBack,folderIND="files"){
  file_data.append("folderID",folderID);
  file_data.append("folderIND",folderIND);
 
  $.ajax({
    url: global.serverURL+"operation=UploadFlieToServer_DEFOULT",
    dataType: 'text',
    cache: false,
    contentType: false,
    processData: false,
    data: file_data,
    type: 'post',
    success: (php_script_response)=>{
     console.log(php_script_response);
      var result = JSON.parse(php_script_response);
      try{
        
        callBack(php_script_response);
      }catch{}
      if(result.resultError!=undefined)
        global.PopUp.Open({content:<POP_Qestion 
          title="Ошибка" text={result.resultError}  footer={result.description}
        />})
         
      
    },
    error:(e)=>{
      console.log(e);
     
    }
  });
}

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}


function mydate(date) {
  
  var userTimezoneOffset = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() - userTimezoneOffset);
}





