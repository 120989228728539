import React from 'react'
import { Player } from 'video-react';

export default class extends React.Component {
    constructor(props) {
        super()
        this.state = {
           
        }
    }
   
    render (){
      
        return  <div className="_1 Zaiavki DataBase tttb" >
                    <div class="container-fluid m-0 " >
                        <div class="row main_pages ">
                            <div className="col-lg-2 col-md-5 left">
                                Техническая поддержка
                            </div>

                            <div className="col-lg-10 col-md-7 right m-0  p-0 scroll pt-3" id="scroll_msgs" >
                            <h4>Видео о создании заявки - 2 части</h4>
                            <video src="./server/1.mov" width="640px" height="380px" playsinline controls/>
                            <video src="./server/2.mov" width="640px" height="380px" playsinline controls/>

                            <br></br>
                            <h4>Скачивание файлов</h4>
                            <video src="./server/file-downloads.mov" width="640px" height="380px" playsinline controls/>

                            <br></br>
                            <h4>Права в файловой системе</h4>
                            <video src="./server/folders-ruls.mov" width="640px" height="380px" playsinline controls/>
                              
                            </div> 
                        </div>
                    </div>
                </div>
    
    }
}


