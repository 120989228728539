import React from 'react';
import '../../css/POP.css';
import '../../css/POP_CreateContact.css';


class POP_CreateContact extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
           
        }
        this.input=[];
       

    }
    Save(){
        if(global.checkReqvireds(this.input,["TEXT"])){
            var data={
                TITLE:this.input.TITLE.value,
                TEXT:this.input.TEXT.value,
                DIST:this.props.DIST
            };

            global.SERVER_task('operation=createUpdateContact&params='+JSON.stringify(data),
            (data)=>{
                try{
                    this.props.callBack(data.result*1);
                }catch{}
                global.PopUp.CloseFunction()
            })
        }
    }

    render (){
       
        
        return <div className="POP POP_CreateContact content-fluid">
                        <div className="row">
                                    <input type="text" autoFocus placeholder="Наименование" class="form-control ml-3 mr-3 mb-2" 
                                        ref={ref => this.input.TITLE = ref} />
                                    <textarea placeholder="Блок любой информации" class="form-control ml-3 mr-3 mb-3" 
                                        ref={ref => this.input.TEXT = ref} />
                                    
                        </div>
                        <div className="row">
                            <div key={global.getRandomInt(9999)} className="col">
                                    <button type="button" onClick={()=>this.Save()} class="w-100 btn btn-primary">Сохранить</button>
                                </div>
                            <div key={global.getRandomInt(9999)} className="col">
                                <button type="button" onClick={()=>global.PopUp.CloseFunction()} class="w-100 btn">Отмена</button>
                            </div>
                        </div>
                </div>
    
    }
}

export default POP_CreateContact;
