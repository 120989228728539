import React from 'react';
import '../../css/_1.css';
import Folder from './Folder'

import File from './File'
import POP_Rename from './POP_Rename'
import POP_Replace from './POP_Replace'
import POP_Qestion from '../../Components/POP_Qestion'
import POP_Select from '../../Components/POP_Select'
import AddFolder from './AddFolder'
import POP_CreateDirr from './POP_CreateDirr'
import Dropzone from 'react-dropzone'
import styled from 'styled-components';
import POP_Search from './POP_Search';

import FileViewer from "react-file-viewer";

class _1 extends React.Component {
    constructor(props) {
        super()
        this.state = {
            selectedFolderID:null,
            selectedMainFolderID:null,
            selectedFileID:null,
            Folders:[],
            contentLoading:true,
            Files:[],
            DIST:null,
            isDIST_Private:null,
            DISTs:[],
            Users:[],
            FilePermissions:[],
            Folders_childe:[],
            selectedFolder:null,
            parentFolder:null
        }

        this.input=[];
        this.AllWaysSelectedUsers=[];
        this.FilePromissonsLoad=2;
        
      
    }
    setDefault(){
       this.setState({
        selectedFolderID:null,
        selectedMainFolderID:null,
        selectedFileID:null,
        Folders:[],
        Files:[],
        contentLoading:true,
        DIST:this.props.DIST,
        Folders_childe:[],
        selectedFolder:null
       })
    }

    componentDidMount(){
        
        this.getDists()
            .then(()=>{
                this.getFolders()
                this.getUsers()
            })

        global.addTimer("filesAndFolders",()=>{
            if(global.CURRENT_PAGE==1){
                this.getFolders();
                
            }
        },20000)

      
    }

    getFolders(){
       
        global.SERVER_task('operation=getFolders&DIST='+this.state.DIST,
        (data)=>{
             this.setState({Folders:data.result}); 
             if(data.result.length>0){
                if(this.state.selectedMainFolderID==null )   
                    this.setState({
                        selectedMainFolderID:data.result[0].ID,
                        selectedFolderID:data.result[0].ID
                    })
                this.getContentOfOpenedFolder()
            }else{
                this.setState({
                    selectedMainFolderID:null,
                    selectedFolderID:null,
                    Folders_childe:[],
                    Files:[]
                })
            }
        })
    }

    getContentOfOpenedFolder(){
        Promise.allSettled([
            this.getFolders_childe(),
            this.getFiles()
        ]).then(res=>{
            
            setTimeout(()=>{
                this.setState({
                    Folders_childe:res[0].value})
                setTimeout(()=>{
                    this.setState({
                        contentLoading:false})
                },100);
            },200);
        })
    }

    getFolders_childe(){
        return new Promise((resolve)=>{
            if(this.state.selectedFolderID>0){
                global.SERVER_task('operation=getFolders&ID='+this.state.selectedFolderID,
                (data1)=>{
                    this.setState({
                        selectedFolder:data1.result[0]
                    })
                    global.SERVER_task('operation=getFolders&ID_PARENT='+this.state.selectedFolderID,
                    (data)=>{
                        resolve(data.result)
                    })
                })
            }
        })
    }

    getFiles(){
        return new Promise((resolve)=>{
            global.SERVER_task('operation=getFiles&ID_FOLDER='+this.state.selectedFolderID,
            (data)=>{
                resolve(data.result)
                setTimeout(()=>this.setState({Files:data.result}),200);
            })
        })
    }

    getParent(){
        if(this.state.selectedFolder!=null && this.state.selectedFolder.ID_PARENT!=0){
            global.SERVER_task('operation=getFolderParent&ID_PARENT='+this.state.selectedFolder.ID_PARENT,
            (data)=>{
                this.setState({parentFolder:data.result[0]});
            })
        }
    }
    FolderBack(){
        if(this.state.parentFolder.ID==this.state.selectedMainFolderID)
            this.openFolder(this.state.parentFolder.ID,true);
        else
            this.openFolder(this.state.parentFolder,false);
    }

    openFolder(ID_OBJECT,is_main){
        this.setState({contentLoading:true});
       //если это дочерняя папка, то меняется толкьо selectedFolderID иначе и selectedMainFolderID и selectedFolderID
        if(is_main)
            this.setState({selectedMainFolderID:ID_OBJECT,selectedFolderID:ID_OBJECT,selectedFileID:null,selectedFolder:null});

        else{
           
            //ID_OBJECT => JSON folder
            this.setState({
                selectedFolderID:ID_OBJECT.ID,
                selectedFileID:null,
                selectedFolder:ID_OBJECT
            });
        }
        setTimeout(() => {
            this.getParent();
            this.setState({selectedFileID:null});
            this.getContentOfOpenedFolder()
        }, 20); 
    }

    getDists(){
        return new Promise((resolve,reject)=>{
            global.SERVER_task('operation=getDistForSelf',
            (data)=>{
               
                    if(data.result.length>0)
                        if(this.state.DIST==null){
                        this.setState({DISTs:data.result,DIST:data.result[0].ID,isDIST_Private:data.result[0].ID_PVIVATE_OVNER})
                          
                        }else this.setState({DISTs:data.result})  
                    else{
                        this.setState({DISTs:[]})  
                        reject()
                        return
                    }
                 

                setTimeout(()=>resolve(),5)
            })
        }) 
    }

    deleteFile(ID_OBJECT){
        global.SERVER_task('operation=delteFile&ID_FILE='+ID_OBJECT,
        (data)=>{
            this.getFiles();
        })
    }

    openFile(JSON){
        this.setState({selectedFileID:JSON.ID});
        global.onDBClick(JSON.ID,()=>{
            window.open(`server/files/${JSON.URL}.${JSON.EXT}`, '_blank')
        });

        
        
    }

   


    getUsers(){
        if(global.Auth.ROLE<=2)
        global.SERVER_task('operation=getUsers',
        (data)=>{
            this.AllWaysSelectedUsers=[];
            for(var i=0;i<data.result.length;i++){
                data.result[i].TITLE=data.result[i].FIO;
                if(data.result[i].ROLE*1<=2)
                    this.AllWaysSelectedUsers.push(data.result[i].ID);
            }
            
            this.setState({Users:data.result});   
        })
    }


    getFileInfo(){
       
        var File=null;
        for(var i=0; i<this.state.Files.length; i++){
            if(this.state.Files[i].ID==this.state.selectedFileID){
                File=this.state.Files[i];
                break;
            }
        }
        
        
        
        if(File!=null)
            return <div className="fileInfo">
                        <div className="container-fluid mb-2">
                            <div className="row p-0">
                                <div class="dropdown w-100">
                                    <button class="btn btn-sm btn-light dropdown-toggle w-100" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Действия</button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        
                                        <a  class="dropdown-item"
                                            href={"/server/files/"+File.URL+"."+File.EXT} target="blank">Открыть</a>
                                        <a  class="dropdown-item"
                                            href={"/server/files/"+File.URL+"."+File.EXT} target="blank" download={File.TITLE+'.'+File.EXT}
                                            >Скачать</a>
                                        <div class="dropdown-divider"></div>
                                        {global.Auth.ROLE<=2?
                                            <a  class="dropdown-item"
                                            onClick={
                                                ()=>
                                                    global.PopUp.Open({content:<POP_Select
                                                    ELEMENTS={this.state.Users}
                                                    SELECTED={[]}
                                                    MEGA_SELECTED={this.AllWaysSelectedUsers}
                                                    MEGA_SELECTED_POSTFIX="admin"
                                                    callBack={(Users)=>this.setFilePromossions(Users)}
                                                    />})
                                            }
                                            >Настроить доступ</a>
                                            :
                                            null
                                        }
                                        
                                        <a  class="dropdown-item"
                                            onClick={()=>global.PopUp.Open({content:<POP_Replace callBack={()=>this.getFiles()} OBJECT={File}/>})} 
                                            >Переместить</a>
                                        <a  class="dropdown-item"
                                            onClick={()=>global.PopUp.Open({content:<POP_Rename callBack={()=>this.getFiles()} OBJECT={File}/>})}
                                            >Переименовать</a>
                                        <div class="dropdown-divider"></div>
                                        <a  class="dropdown-item" 
                                            onClick={()=>global.PopUp.Open({content:<POP_Qestion text="Безвозвратно удалить файл?" buttons={[
                                                ["Удалить",'danger',()=>this.deleteFile(File.ID)],
                                                ["Отмена",'']
                                            ]}/>})}
                                            >Удалить</a>
                                    </div>
                                </div> 
                            </div>
                        </div>
                        <div id="PREVIEW"
                        onClick={()=>
                            window.open(`file_preview/${File.URL}/${File.TITLE}/${File.EXT}`, '_blank')
                        }>
                        {File.EXT=='png' || File.EXT=='jpg' || File.EXT=='jpeg'?
                            <ImgPreview src={global.serverURL_Main+"files/"+File.URL+"."+File.EXT}/>
                            :
                         File.EXT=='docx' || File.EXT=='xslx'|| File.EXT=='csv'|| File.EXT=='pdf'|| File.EXT=='mp3'|| File.EXT=='mp4'|| File.EXT=='webm'|| File.EXT=='gif'?
                            <FileViewer
                                
                                fileType={File.EXT}
                                filePath={"/server/files/"+File.URL+"."+File.EXT}
                                onError={(e)=>console.error(e)}/>
                         :null
                        }
                        </div>
                        <p className="m-0 p-0"><b>Название:</b> {File.TITLE}</p>
                        <p className="m-0 p-0"><b>Расширение:</b> {File.EXT}</p>
                        <p className="m-0 p-0"><b>Рамер:</b> {global.makeNormalChislo(File.SIZE)} КБ</p>
                       
                        



                    </div>
        
    }

    makeDirr(ID){
        if(this.state.selectedFolderID!=null){
            global.PopUp.Open({content:<POP_CreateDirr
                ID_OBJECT={ID}
                ID_PARENT={
                    ID!=undefined?
                        this.state.parentFolder.ID:
                        this.state.selectedFolderID
                }
                ID_MAIN_FOLDER={this.state.selectedMainFolderID}
                callBack={()=>
                        this.getFolders()
                }
                openParentFolder={()=>{
                    this.openFolder(this.state.parentFolder,false)
                }}
            />})
        }
    }

    FileUpload(files){
        if(this.state.selectedFolderID!=null)
            if(files.length>0){
            var folderID = this.state.selectedFolderID;
                var form_data = new FormData();
                for(var i=0;i<files.length;i++){
                
                    form_data.append('file'+i, files[i]);
                    
                }
                
                global.UploadFlieToServer_DEFOULT(form_data,folderID,()=>this.getFiles());   
                this.input.file.value=null;
            

            }
    }

    render (){
        const {DISTs,DIST} = this.state


        let list_folders = this.state.Folders.map((JSON)=>
            <Folder class="scrollElement" 
                IS_IN_SELF_PRIVATE_FOLDER = {this.state.isDIST_Private}
                canChange={true}
                ID_OBJECT={JSON.ID}
                onClick={(ID_OBJECT)=>this.openFolder(ID_OBJECT,true)} 
                isSelected={this.state.selectedMainFolderID==JSON.ID?true:false}
                title={JSON.TITLE}
                img={"/server/img/folders/img_"+JSON.ID+".jpg"}
                callBack={()=>this.getFolders()}
            />
        )

        if(global.Auth.ROLE<=2 || this.state.isDIST_Private == global.Auth.ID_USER)
            list_folders.push(
                <AddFolder DIST={DIST} class="scrollElement" callBack={()=>this.getFolders()}/>
            );


        let list_files = this.state.Files.map((JSON)=>
            <File SHOW_EXT_IMG={true} SHOW_EXT_TEXT={true}  isSelected={this.state.selectedFileID==JSON.ID?true:false}  ID_OBJECT={JSON.ID} class="scrollElement" onClick={(e)=>{
                e.stopPropagation()
                this.openFile(JSON)
            }} TITLE={global.textCut(JSON.TITLE,30,"..")+"."} EXT={JSON.EXT} SIZE={global.makeNormalChislo(JSON.SIZE)+" КБ"} DATE_TIME_LOAD={global.makeNormalDate(JSON.DATE_TIME_UPLOAD,"dd.mm.yyyy H:M")}/>
        )

        let list_folders_childe = this.state.Folders_childe.map((JSON)=>
            <File SHOW_EXT_IMG={true} SHOW_EXT_TEXT={false}  ID_OBJECT={JSON.ID} class="scrollElement" onClick={(e)=>{
                e.stopPropagation()
                global.onDBClick(JSON.ID,()=>{
                    this.openFolder(JSON,false);
                });
                
            }} TITLE={global.textCut(JSON.TITLE,32,"..") } EXT="folder" />
    )


        return  ([
                <div className="_1 " >
                    <div class="container-fluid m-0 " >
                        <div class="row main_pages ">
                            <div className="col-lg-2 col-md-5 left">
                            <SeatchButtn onClick={()=>{
                                global.PopUp.Open({content:<POP_Search/>})
                            }} src="./server/img/search.svg"/>
                            {this.props.TITLE}
                            

                                {
                                    this.state.selectedFileID!=null?
                                        this.getFileInfo()
                                        :
                                        null
                                }
                                
                                
                                <div id="smoller">
                                    
                                    <div>
                                        {this.state.selectedFolderID!=this.state.selectedMainFolderID?
                                            <div className="btn btn-light  btn-sm w-100 mb-2" onClick={()=>this.makeDirr(this.state.selectedFolderID)}>ИЗМЕНИТЬ ПАПКУ</div>
                                        :null}
                                        <div className="btn btn-light  btn-sm w-100 mb-2" onClick={()=>this.makeDirr()}>СОЗДАТЬ ПАПКУ</div>
                                    </div>

                                    <div id="PrevImg_cont">
                                        <label className="btn btn-primary btn-sm m-0" id="upload" for="pct"> ДОБАВИТЬ ФАЙЛ</label>
                                    </div>
                                    <input type="file" id="pct" disabled={this.state.selectedFolderID!=null?false:true} multiple ref={ref => this.input.file = ref} onChange={(e)=>this.FileUpload(e.target.files)} hidden class=" mb-2 w-100" />
                                </div>
                                
                               
                            </div>  
                            <div className="col-lg-10 col-md-7 right">
                                <DistView>
                                    {DISTs.map(dist=>
                                        <DistFolder style={{width:100/DISTs.length+"%"}} isSelected={dist.ID==DIST} onClick={()=>{
                                            this.setState({DIST:dist.ID,selectedMainFolderID:null,isDIST_Private:dist.ID_PVIVATE_OVNER})
                                            setTimeout(()=>this.getFolders(),1)
                                        }}>
                                            <p>{dist.TITLE}</p>
                                        </DistFolder>
                                    )}
                                </DistView>     
                                {list_folders.length>0?
                                        <div className="horizontalScroll pt-5 pb-3">
                                            <div className="scrollContent pl-3 pr-5">
                                                {list_folders}
                                            </div>
                                        </div>
                                :<p className="m-5">Нет разделов, доступных вам </p>}
                                    
                                
                                <div className="whiteGrad TOP"></div>
                                {
                                            this.state.selectedFolderID!=this.state.selectedMainFolderID?
                                            <p id="backer" onClick={()=>this.FolderBack()}>‹  {this.state.selectedFolder.PARENT_TITLE +" / "+this.state.selectedFolder.TITLE}</p>
                                        :null}
                                <div id="file_folders" className={(this.state.contentLoading?"loading ":"")+" verticalScroll"} >
                                    <div className="scrollContent pl-5 pr-5 pb-5 pt-3 mb-5">

                                        <Dropzone onClick={()=>{debugger}} onDrop={acceptedFiles => {
                                            
                                            this.FileUpload(acceptedFiles)
                                        }}>
                                            {({getRootProps, getInputProps}) => (
                                                <section>
                                                <div style={{
                                                    outline: "none",
                                                    border: "none",
                                                    boxShadow: "none"
                                                }} {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    {list_folders_childe.length==0 && list_files.length==0?<p>Нет доступного контента</p>:null}
                                                    {list_folders_childe}
                                                    {list_files}
                                                </div>
                                                </section>
                                            )}
                                        </Dropzone>
                                        
                                    </div>
                                </div>
                                <div className="whiteGrad BOTTOM"></div>
                            </div> 
                        </div>
                    </div>
                </div>
                
            ])
    
    }
}

export default _1;


const ImgPreview = styled.img`
height: 200px;
border-radius: 10px;
width: 100%;
object-fit: cover;
margin-bottom: 10px;
`
const SeatchButtn = styled.img`
height: 40px;
width: 40px;
float: right;
margin-top: 15px;
margin-right: 0px;
padding: 10px;
cursor: pointer;
transition: .3s;
opacity:0.5;
&:hover{
    opacity:1;
}
`
const DistView = styled.div`
margin-left:-15px;
margin-right:-15px;
`

const DistFolder = styled.div`
width: 33.3%;
font-size:12px;
text-align:center;
padding-top:4px;
height: 25px;
overflow: hidden;
display: inline-block;
cursor:pointer;
font-weight: ${props=>props.isSelected?"bold":"normal"};
background:${props=>props.isSelected?"white":"rgb(240,240,240)"};
border-bottom:${props=>props.isSelected?0:1}px solid rgb(230,230,230);
border-left:1px solid rgb(230,230,230);
`