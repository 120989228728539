import React from 'react';
import '../css/Fab.css';
import {Plus}  from 'react-bootstrap-icons';


class Fab extends React.Component {
    constructor(props) {
        super()
        this.state = {

        }
    }



    render (){
        if(this.props.buttons!=null)
            var list=this.props.buttons.map((JSON)=>
                <li class="fab-buttons__item"> 
                            <a class="fab-buttons__link" data-tooltip={JSON.title} onClick={JSON.onClick}>
                            {JSON.svg}
                            </a>
                        </li>
            )
    
        return  <div class={"fab "+this.props.class}>
                    <span class="fab-action-button">
                        <Plus size={56} color="white"/>
                    </span>
                    <ul class="fab-buttons">
                        {list}
                    
                    
                    </ul>
                </div>
    
    }
}


export default Fab;

