import React from 'react';
import '../../css/POP.css';
import '../../css/POP_reqv.css';
import POP_Qestion from '../../Components/POP_Qestion'
import POP_reqv_item from './POP_reqv_item'
import POP_Select from '../../Components/POP_Select'
import POP_reqv_messages from "./POP_reqv_messages"
import POP_reqv_joins_create from './POP_reqv_joins_create';
import styled from 'styled-components';
import POP_reqv_join from './POP_reqv_join';


export default class POP_reqv_cont extends React.Component {
    constructor(props) {
        super()
        this.state = {
            REQV_CONT:{},
            REQV_JOINS:[]
        }
        this.REQV_JOINS_TO_UPLOAD=[]
        this.JOINS_elements={}
        this.shiftKeyCheckBox={
            start:null,
            end:null
        }
        this.input=[];
        this.ID_REQV_CONT=props.ID_OBJECT
        this.Mainfields=[
            {
                name:"ID_METHOD",
                descr:"Метод НК",
                type:"select",
                values:global.SPR["НК"].DATA
            },
            {
                name:"KNOT_TITLE",
                descr:"Наименование узла детали",
                type:"textarea",
                className:"t2"
            },
            {
                name:"LINE_TITLE",
                descr:"Наименование линии",
                type:"textarea",
                className:"t2"
            },
            {
                name:"POSITION_ON_MAP",
                descr:"№ позиции на схеме"
            },
            {
                name:"WILDER_JURNAL",
                descr:"№ схемы"
            },
            {
                name:"ID_PIPES_CATEG",
                descr:"Категория трубопровода"
            },
            {
                name:"TRANSPORT_PROD_TITLE",
                descr:"Транспортируемый продукт",
                type:"select",
                values:global.SPR["Транспортируемые продукты"].DATA
            },
            /*
            {
                name:"CONTROLLING_DIM",
                descr:"Контролируемая единица измерения",
                type:"select",
                values:[
                    {
                        text:"Диаметр",
                        value:"D"
                    },
                    {
                        text:"Длина",
                        value:"L"
                    }
                ]
            },
            */
            
            
            {
                name:"MARK_MATERIAL_TITLE",
                descr:"Марка стали",
                type:"select",
                values:global.SPR["Сталь"].DATA
            },
            
        ]


        this.Controlfields=[
            {
                name:"CONDITIONS_TITLE",
                descr:"Условия проведения контроля"
            },
            {
                name:"VALUE_OF_CONTROL",
                descr:"Объем контроля"
            },
            {
                name:"CONTROL_TOOLS_TITLE",
                descr:"Применяемые средства контроля",
                type:"textarea",
                className:"t2"
            },
            {
                name:"NTD_CONTROL_TITLE",
                descr:"Контроль в соответствии с НТД"
            },
            {
                name:"NTD_QUALITY",
                descr:"Оценка качества по НТД"
            }
        ]

        this.YZKfields=[
            {
                name:"CONVERTER_PARAMS",
                descr:"Параметры преобразователей",
                type:"textarea",
                className:"t2"
            },
            {
                name:"CONTROL_SCHEME",
                descr:"Применяемые средства контроля",
                type:"textarea",
                className:"t2"
            },
            {
                name:"QUALITY_ASSESSMENT",
                descr:"Применяемые средства контроля",
                type:"textarea",
                className:"t2"
            }
        ]

        this.RKfields=[
            {
                name:"SENSITIVITY",
                descr:"Чувствительность (мм)"
            },
            {
                name:"ESTABLISHED_SNIP",
                descr:"Установленный СНиП 3.05.05-84"
            },
            {
                name:"BASED_RESULTS",
                descr:"По результатам контроля"
            }
        ]

        this.DIM = null
        this.LENGTH =null

        if(global.Auth.ROLE==6 || global.Auth.ROLE==5)
        this.getUSERS();
    
        this.VIEW_MainFileds = global.genFields(this,this.Mainfields,1);
        this.VIEW_ControlFileds = global.genFields(this,this.Controlfields,1);
        this.VIEW_YZKFileds = global.genFields(this,this.YZKfields,1);
        this.VIEW_RKFileds = global.genFields(this,this.RKfields,1);

    }

    getUSERS(){
        global.SERVER_task('operation=getUsers',
        (data)=>{
            let nach=[]
            data.result.map((user)=>{
                if(global.Auth.ROLE ==6 && user.ROLE==5 || global.Auth.ROLE ==5 && user.ROLE==3){
                    user.TITLE=user.FIO
                    nach.push(user)
                }
            })
            this.setState({USERS:nach});
        })
    }
    componentDidMount(){
       
        if(this.props.ID_OBJECT==null){
            var data={
                ID_REQVEST:this.props.ID_REQVEST
            };
            global.SERVER_task('operation=createUpdateREQV_CONT&params='+JSON.stringify(data),
            (data)=>{
                try{
                    this.props.callBack();
                }catch{}
                this.ID_REQV_CONT=data.result
                this.getCONT(data.result)
                    .then(()=>this.getREQV_JOINS())
            })
         }else{
            this.getCONT()
                .then(()=>this.getREQV_JOINS())
         }

    }
    onChangeInp(){
        
        if(this.DIM==this.input.CONTROLLING_DIM.value && this.LENGTH==this.input.CONTROLLING_LENGTH.value) return

        this.DIM = this.input.CONTROLLING_DIM.value;
        this.LENGTH = this.input.CONTROLLING_LENGTH.value;
        let WEIGHT = this.input.CONTROLLING_WEIGHT.value;
   
        
        if(this.DIM=="D"){
            

            /*
            let cont_items =Math.floor(this.LENGTH*Math.PI/100);
           
            for(var i = 0;i<cont_items-1;i++){
                REQV_ITEMS_tmp.push({
                    CONTROL_PLACE:i*100+"-"+(i+1)*100
                });
            }
            REQV_ITEMS_tmp.push({
                CONTROL_PLACE:i*100+"-0"
            });
            */

            if(this.LENGTH<=108){
                this.REQV_ITEMS_TO_UPLOAD=2
            }else{

            switch(this.LENGTH){
                case 159: this.REQV_ITEMS_TO_UPLOAD=3; break;
                case 219: this.REQV_ITEMS_TO_UPLOAD=4; break;
                case 273: this.REQV_ITEMS_TO_UPLOAD=4; break;
                case 325: this.REQV_ITEMS_TO_UPLOAD=4; break;
                case 426: this.REQV_ITEMS_TO_UPLOAD=5; break;
                default:
                    this.REQV_ITEMS_TO_UPLOAD=Math.ceil(this.LENGTH*Math.PI/260)
                break;
            }
        }
        
            

            
        }else if(this.DIM=="L"){
            /*
            let REQV_ITEMS_tmp =[];
            REQV_ITEMS_tmp.push({
                CONTROL_PLACE:"0-"+this.LENGTH
            });
            */
            this.REQV_ITEMS_TO_UPLOAD=1;
        }
    }

    getCONT(ID){
        return new Promise((resolve, reject)=>{
            if(ID==null) ID=this.ID_REQV_CONT;
            global.SERVER_task('operation=getREQV_CONT&ID='+ID,
            (data)=>{
                try{
                    
                    this.setState({REQV_CONT:data.result[0]})
                   
                    
                    this.Mainfields.map((field)=>{
                        this.input[field.name].value=data.result[0][field.name]
                        console.error(field.name +" = "+data.result[0][field.name])
                    })

                    
                    
                    
                }catch{
                    reject();
                }
                setTimeout(()=>{
                    try{
                        this.Controlfields.map((field)=>{
                            this.input[field.name].value=this.state.REQV_CONT[field.name];
                        })
                    }catch{}
        
                    try{
                        if(this.state.REQV_CONT.ID_METHOD==1)
                            this.RKfields.map((field)=>{
                                this.input[field.name].value=this.state.REQV_CONT[field.name];
                            })
                    }catch{}
        
                    try{
                        if(this.state.REQV_CONT.ID_METHOD==2)
                            this.YZKfields.map((field)=>{
                                this.input[field.name].value=this.state.REQV_CONT[field.name];
                            })
                    }catch{}

                    resolve()
                },5);
            })
        })
        
    }

    getREQV_JOINS(){
        this.JOINS_elements={}
        global.SERVER_task('operation=getREQV_JOINS&ID_REQV_CONT='+this.state.REQV_CONT.ID,
        (data)=>{
            this.JOINS_elements={}
            
            this.setState({REQV_JOINS:data.result});
        })
    }

   
    deleteREQV_JOINS(){
        let data={
            IDs:[]
        }

        let arr ={}
        Object.keys(this.JOINS_elements).map((key,i)=>{
            if(this.JOINS_elements[key]!=null)
                arr[key]=this.JOINS_elements[key]
        })

        Object.keys(arr).map((key,i)=>{
            if(this.JOINS_elements[key].checked)
                data.IDs.push(this.state.REQV_JOINS[this.state.REQV_JOINS.length-i-1].ID)
        })
        
        if(data.IDs.length>0)
            global.SERVER_task('operation=deleteREQV_JOINS&params='+JSON.stringify(data),
            (data)=>{
                this.getREQV_JOINS()
            })
    }

    deleteCONT(){
        let data={
            ID:this.state.REQV_CONT.ID
        };

        global.SERVER_task('operation=deleteREQV_CONT&params='+JSON.stringify(data),
        (data)=>{
            if(data.result=="ok"){
                global.PopUp.CloseFunction();
                try{
                    this.props.callBack();
                }catch{}
            }else{
                global.PopUp.Open({content:<POP_Qestion text="Невозможно удалить запрос"/>})
            }
        })
    }

    Save(){
        if(global.Auth.ROLE!=4){//если не заказчик

            let data={}
            
            this.Controlfields.map((field)=>{
                data[field.name]=this.input[field.name].value;
            })
            data.ID_REQV_CONT=this.state.REQV_CONT.ID;
            global.SERVER_task('operation=createUpdateREQV_CONT_ADDITIONALS&params='+JSON.stringify(data),
            (data)=>{
                
            })

            if(this.state.REQV_CONT.ID_METHOD==1){
                let data={}
                this.RKfields.map((field)=>{
                    data[field.name]=this.input[field.name].value;
                })
                data.ID_REQV_CONT=this.state.REQV_CONT.ID;
                global.SERVER_task('operation=createUpdateREQV_CONT_RK&params='+JSON.stringify(data),
                (data)=>{
                    
                })
            }

            if(this.state.REQV_CONT.ID_METHOD==2){
                this.YZKfields.map((field)=>{
                    data[field.name]=this.input[field.name].value;
                })
                let data={}
                data.ID_REQV_CONT=this.state.REQV_CONT.ID;
                global.SERVER_task('operation=createUpdateREQV_CONT_YZK&params='+JSON.stringify(data),
                (data)=>{
                    
                })
            }
            
            
            data={};
            
            this.Mainfields.map((field)=>{
                data[field.name]=this.input[field.name].value!=null && this.input[field.name].value!=''?this.input[field.name].value!=null:null;
            })
            
            if(this.props.ID_REQVEST!=null)
                data.ID_REQVEST=this.props.ID_REQVEST;

            if(this.props.ID_OBJECT!=null)
                data.ID=this.props.ID_OBJECT;
            else
                data.ID=this.state.REQV_CONT.ID;
    
                
            global.SERVER_task('operation=createUpdateREQV_CONT&params='+JSON.stringify(data),
            (data)=>{
                    this.getCONT(this.state.REQV_CONT.ID)
                    .then(()=>this.getREQV_JOINS())
                    global.PopUp.Open({content:<POP_Qestion text="Изменения сохранены"/>})
                try{
                    this.props.callBack()
                }catch{}
            })

            
           
        }else{
            if(!global.checkReqvireds([this.input.ID_METHOD])) return;
    
            let data={};
            
            this.Mainfields.map((field)=>{
                data[field.name]=this.input[field.name].value;
            })
            
            if(this.props.ID_REQVEST!=null)
                data.ID_REQVEST=this.props.ID_REQVEST;

            if(this.props.ID_OBJECT!=null)
                data.ID=this.props.ID_OBJECT;
            else
                data.ID=this.state.REQV_CONT.ID;
    
                
            global.SERVER_task('operation=createUpdateREQV_CONT&params='+JSON.stringify(data),
            (data)=>{
                    this.getCONT(this.state.REQV_CONT.ID)
                    .then(()=>this.getREQV_JOINS())
                    global.PopUp.Open({content:<POP_Qestion text="Изменения сохранены"/>})
                try{
                    this.props.callBack()
                }catch{}
            })

   
        }
    }

    complite(){
        let data={
            ID:this.state.REQV_CONT.ID,
            ID_REQVEST:this.state.REQV_CONT.ID_REQVEST
        }

        global.SERVER_task('operation=compliteREQV_CONT&params='+JSON.stringify(data),
        (data)=>{
            if(data.result=="ok" || data.result=="ok all"){

                global.API_task(global.API.SEND_MAIL,{
                    EMAIL:this.state.REQV_CONT.OVNER_EMAIL,
                    FROM:"Веско",
                    SHABLON:"TEXT",
                    TEXT:{
                        ":TEXT":"Запрос на исследование "+this.state.REQV_CONT.METHOD_NK+" по заявке №"+this.state.REQV_CONT.ID_REQVEST+" перешел в статус 'Исполенно'"
                    }
                },(result)=>{
                })

                global.API_task(global.API.SEND_MAIL,{
                    EMAIL:this.state.REQV_CONT.NACH_EMAIL,
                    FROM:"Веско",
                    SHABLON:"TEXT",
                    TEXT:{
                        ":TEXT":"Запрос на исследование "+this.state.REQV_CONT.METHOD_NK+" по заявке №"+this.state.REQV_CONT.ID_REQVEST+" перешел в статус 'Исполенно'"
                    }
                },(result)=>{
                })

                global.API_task(global.API.SEND_MAIL,{
                    EMAIL:this.state.REQV_CONT.DIR_EMAIL,
                    FROM:"Веско",
                    SHABLON:"TEXT",
                    TEXT:{
                        ":TEXT":"Запрос на исследование "+this.state.REQV_CONT.METHOD_NK+" по заявке №"+this.state.REQV_CONT.ID_REQVEST+" перешел в статус 'Исполенно'"
                    }
                },(result)=>{
                })



                if(data.result=="ok all"){
                    global.API_task(global.API.SEND_MAIL,{
                        EMAIL:this.state.REQV_CONT.OVNER_EMAIL,
                        FROM:"Веско",
                        SHABLON:"TEXT",
                        TEXT:{":TEXT":"Заявка №"+this.state.REQV_CONT.ID_REQVEST+" перешела в статус 'Исполенно'"}
                    },(result)=>{})
    
                    global.API_task(global.API.SEND_MAIL,{
                        EMAIL:this.state.REQV_CONT.NACH_EMAIL,
                        FROM:"Веско",
                        SHABLON:"TEXT",
                        TEXT:{":TEXT":"Заявка №"+this.state.REQV_CONT.ID_REQVEST+" перешела в статус 'Исполенно'"}
                    },(result)=>{})

                    global.API_task(global.API.SEND_MAIL,{
                        EMAIL:this.state.REQV_CONT.DIR_EMAIL,
                        FROM:"Веско",
                        SHABLON:"TEXT",
                        TEXT:{":TEXT":"Заявка №"+this.state.REQV_CONT.ID_REQVEST+" перешела в статус 'Исполенно'"}
                    },(result)=>{})
                }



                global.PopUp.CloseFunction();
                try{
                    this.props.callBack();
                }catch{}
            }
            if(data.result=="joins not comlited"){
                global.PopUp.Open({content:<POP_Qestion text="Не все участки исследованы"/>})
            }
        })
    }


    
    setRESPONSIBLE_PERSON(selected){
        
        let data={
            ID_USER:selected[0],
            ID:this.state.REQV_CONT.ID,
            ID_REQVEST:this.state.REQV_CONT.ID_REQVEST
        }
        global.SERVER_task('operation=setsetRESPONSIBLE_PERSON_TO_REQV&params='+JSON.stringify(data),
        (data)=>{
            if(data.result=="ok"){

                global.API_task(global.API.SEND_MAIL,{
                    EMAIL:this.state.REQV_CONT.OVNER_EMAIL,
                    FROM:"Веско",
                    SHABLON:"TEXT",
                    TEXT:{":TEXT":"Заявка №"+this.state.REQV_CONT.ID_REQVEST+" метод "+this.state.REQV_CONT.METHOD_NK+" передана в работу"}
                },(result)=>{})

                this.getCONT()
                    .then(()=>{
                        global.API_task(global.API.SEND_MAIL,{
                            EMAIL:this.state.REQV_CONT.NACH_EMAIL,
                            FROM:"Веско",
                            SHABLON:"TEXT",
                            TEXT:{":TEXT":"Вы назначены ответственным для Заявки №"+this.state.REQV_CONT.ID_REQVEST+" метод "+this.state.REQV_CONT.METHOD_NK}
                        },(result)=>{})
                    })

                try{
                    this.props.callBack();
                }catch{}
            }else{
                global.PopUp.Open({content:<POP_Qestion text="Невозможно изменить статус"/>})
            }
        })
    }


    
    setNACH(selected){
        
        let data={
            ID_NACHLNIK:selected[0],
            ID:this.state.REQV_CONT.ID
        }
        global.SERVER_task('operation=setNACH_TO_REQV&params='+JSON.stringify(data),
        (data)=>{
            if(data.result=="ok"){

                global.API_task(global.API.SEND_MAIL,{
                    EMAIL:this.state.REQV_CONT.OVNER_EMAIL,
                    FROM:"Веско",
                    SHABLON:"TEXT",
                    TEXT:{":TEXT":"Заявка №"+this.state.REQV_CONT.ID_REQVEST+" метод "+this.state.REQV_CONT.METHOD_NK+" передана на рассмотрение начальнику лаборатории"}
                },(result)=>{})

                this.getCONT()
                    .then(()=>{
                        global.API_task(global.API.SEND_MAIL,{
                            EMAIL:this.state.REQV_CONT.NACH_EMAIL,
                            FROM:"Веско",
                            SHABLON:"TEXT",
                            TEXT:{":TEXT":"Вы назначены ответственным для Заявки №"+this.state.REQV_CONT.ID_REQVEST +" метод "+this.state.REQV_CONT.METHOD_NK}
                        },(result)=>{
                            global.PopUp.Open({content:<POP_Qestion text="Ответственное лицо назначено"/>})
                        })
                    })

                try{
                    this.props.callBack();
                }catch{}
            }else{
                global.PopUp.Open({content:<POP_Qestion text="Невозможно изменить статус"/>})
            }
        })
    }

    ChangeREQVSTATUS(ID_STATUS){
        if(ID_STATUS==6){
            global.API_task(global.API.SEND_MAIL,{
                EMAIL:this.state.REQV_CONT.OVNER_EMAIL,
                FROM:"Веско",
                SHABLON:"TEXT",
                TEXT:{":TEXT":"Заявка №"+this.state.REQV.ID+" была отклонена"}
            },(result)=>{})
        }

        if(ID_STATUS==2){
            global.API_task(global.API.SEND_MAIL,{
                EMAIL:this.state.REQV_CONT.DIR_EMAIL,
                FROM:"Веско",
                SHABLON:"TEXT",
                TEXT:{":TEXT":"Заявка №"+this.state.REQV.ID+" поступила на рассмотрение"}
            },(result)=>{})
        }

        if(ID_STATUS==1){
            global.API_task(global.API.SEND_MAIL,{
                EMAIL:this.state.REQV_CONT.NACH_EMAIL,
                FROM:"Веско",
                SHABLON:"TEXT",
                TEXT:{":TEXT":"Заявка №"+this.state.REQV.ID+" снята заказчиком с рассмотрения"}
            },(result)=>{})
        }


        let data={
            ID:this.state.REQV_CONT.ID,
            ID_REQVEST:this.state.REQV_CONT.ID_REQVEST,
            ID_STATUS:ID_STATUS
        };
        

        global.SERVER_task('operation=setStatusREQV_CONT&params='+JSON.stringify(data),
        (data)=>{
            if(data.result=="ok"){
                this.getCONT();
                try{
                    this.props.callBack();
                }catch{}
            }else{
                global.PopUp.Open({content:<POP_Qestion text="Невозможно изменить статус"/>})
            }
        })
    }

    CreateOtchot(){
        let data={
            ID_REQVEST:this.state.REQV_CONT.ID_REQVEST,
            ID_CONT:this.state.REQV_CONT.ID
        }
        var operation=null
        switch(this.state.REQV_CONT.ID_METHOD){
            case 3:operation="VIK_generateDoc"; break;
            case 2:operation="UZK_generateDoc"; break;
            case 1:operation="RK_generateDoc"; break;
        }
        
        if(operation==null) return;

        global.SERVER_task('operation='+operation+'&params='+JSON.stringify(data),
        (data)=>{
            window.open(global.serverURL_Main+data.result, '_blank');
        })

      
    }

    renderButtons(){
        const {REQV_CONT,USERS} = this.state
        let content=[
            <button type="button" onClick={()=>this.Save()} class=" btn btn-sm m-1 btn-primary">Обновить</button>
        ]

        
        if(REQV_CONT.ID_STATUS==4 && global.Auth.ROLE==3)
            content = content.concat([
                <button type="button" onClick={()=>this.complite()} class=" btn btn-sm m-1 btn-primary">Завершить запрос</button>
            ]) 
        
        if(REQV_CONT.ID_STATUS==2 && global.Auth.ROLE==6)
            content = content.concat([
                <button type="button" class=" btn btn-sm m-1 btn-primary" onClick={
                    ()=>global.PopUp.Open({
                         content:<POP_Select
                         IS_SINGLE={true}
                         ELEMENTS={USERS}
                         MEGA_SELECTED={[]}
                         callBack={(selected)=>this.setNACH(selected)}
                    />})
                }>Назначить начальника</button>
            ])
        
        

        if(REQV_CONT.ID_STATUS==2 && global.Auth.ROLE==5 && REQV_CONT.ID_NACHLNIK==global.Auth.ID_USER)
            content = content.concat([
                <button type="button" class=" btn btn-sm m-1 btn-primary " onClick={
                    ()=>global.PopUp.Open({
                         content:<POP_Select
                         IS_SINGLE={true}
                         ELEMENTS={USERS}
                         MEGA_SELECTED={[]}
                         callBack={(selected)=>this.setRESPONSIBLE_PERSON(selected)}
                    />})
                }>Назначить исполнителя</button>
            ])

        if(REQV_CONT.ID_STATUS==5 && (global.Auth.ROLE==5 || global.Auth.ROLE==6))
            content = content.concat([
                <button type="button" onClick={()=>this.CreateOtchot()} class=" btn btn-sm m-1 btn-primary">Выгрузить отчет</button>
            ])

        if(REQV_CONT.ID_STATUS==3 && global.Auth.ROLE==3)
            content = content.concat([
                <button type="button" onClick={()=>this.ChangeREQVSTATUS(4)} class=" btn btn-sm m-1 btn-primary">Принять в работу</button>
            ])

        if(REQV_CONT.ID_STATUS==1)
            content = content.concat([
                <button type="button" onClick={()=>this.deleteCONT()} class=" btn-sm m-1 btn btn-danger float-right">Удалить запрос</button>
            ])

        return <div className="col-12">{content}</div>
    }


    render (){
        const {REQV_JOINS,REQV_CONT} = this.state

        

       
        return(
        <POP className=" content-fluid " >
            <div className="row">
                <BlockLeft className="col-4" comment="LEFT">
                    <h5 className='d-inline'>Запрос</h5>
                    <small className='d-inline float-right'>{REQV_CONT.STATUS_TITLE}</small>
                    <br></br>
                    <ScrollView>

                        <p className="mb-0">Параметры линии</p>
                        {this.VIEW_MainFileds}

                        {REQV_CONT.ID_STATUS>1 && (global.Auth.ROLE==4 || global.Auth.ROLE==5 || global.Auth.ID==REQV_CONT.REQV_ID_RESPONSIBLE_PERSON)? 
                        <div>
                            <hr className="mb-1"></hr>
                            <p className="mb-0">Контроль</p>
                            {this.VIEW_ControlFileds}
                                {REQV_CONT.ID_METHOD==2?
                                <div>
                                    <hr className="mb-1"></hr>
                                    <p className="mb-0">Ульразвуковой контроль</p>
                                    {this.VIEW_YZKFileds}
                                </div>
                                :null}

                                {REQV_CONT.ID_METHOD==1?
                                <div>
                                    <hr className="mb-1"></hr>
                                    <p className="mb-0">Радиографический контроль</p>
                                    {this.VIEW_RKFileds}
                                </div>
                                :null}
                        </div>
                        :null}

                        
                    </ScrollView>
                    
                </BlockLeft>
                <div className="col-8 right" comment="RIGHT">
                    <small className='d-inline float-right btn btn-sm' 
                    onClick={
                        ()=>
                        global.PopUp.Open({
                            content:<POP_reqv_messages
                            ID_REQVEST={REQV_CONT.ID_REQVEST}
                            ID_REQV_VIS={REQV_CONT.ID_VIS}
                        />})
                    }>Обратная связь по заявке</small>
                    <h5>Стыки для контроля {REQV_JOINS.length}шт</h5>

                    <div class="btn-group" role="group" >
                        <button type="button" class="btn btn-light btn-sm"
                        onClick={()=>global.PopUp.Open({
                            content:<POP_reqv_joins_create
                            ID_REQV_CONT={REQV_CONT.ID}
                            callBack={()=>this.getREQV_JOINS()}
                        />})}>Добавить стыки</button>

                        <button type="button" class="btn btn-light btn-sm" onClick={()=>{
                            let arr ={}
                            Object.keys(this.JOINS_elements).map((key,i)=>{
                                if(this.JOINS_elements[key]!=null)
                                    arr[key]=this.JOINS_elements[key]
                            })

                            Object.keys(arr).map(key=>{
                                this.JOINS_elements[key].checked=true
                            })
                        }}>Выбрать все</button>
                        <button type="button" class="btn btn-light btn-sm" onClick={()=>{
                            let arr ={}
                            Object.keys(this.JOINS_elements).map((key,i)=>{
                                if(this.JOINS_elements[key]!=null)
                                    arr[key]=this.JOINS_elements[key]
                            })
                    
                            Object.keys(arr).map((key,i)=>{
                                this.JOINS_elements[key].checked=false
                            })
                        }}>Отменить выделение</button>


                        <button type="button" class="btn btn-danger btn-sm" onClick={()=>this.deleteREQV_JOINS()}>Удалить выбранные</button>
                    </div>
                    

                    <ScrollView>
                        
                        {REQV_JOINS.map((data)=>
                            <CheckBoxElement.View key={'CheckBoxElement_'+data.ID}>
                                <input type="checkbox" onClick={(e)=>{
                                    if(e.shiftKey){
                                        if(this.shiftKeyCheckBox!=null){

                                            let Direction = null
                                            if(this.shiftKeyCheckBox<data.ID)
                                                Direction="toTop"
                                            else if(this.shiftKeyCheckBox>data.ID)
                                                Direction="toBottom"

                                            if(Direction!=null){  //шифт не на том же самом элементе
                                                
                                                let arr ={}
                                                Object.keys(this.JOINS_elements).map((key,i)=>{
                                                    if(this.JOINS_elements[key]!=null)
                                                        arr[key]=this.JOINS_elements[key]
                                                })
                                                
                                                Object.keys(arr).map((key,i)=>{
                                                    let elemView = this.JOINS_elements[key]
                                                    let elem = REQV_JOINS[REQV_JOINS.length-i-1]

                                                        
                                                    if(
                                                        (elem.ID < data.ID && elem.ID > this.shiftKeyCheckBox && Direction=="toTop") ||
                                                        (elem.ID > data.ID && elem.ID < this.shiftKeyCheckBox && Direction=="toBottom")
                                                    ){
                                                        
                                                        this.JOINS_elements[key].checked=!elemView.checked
                                                    }
                                                })

                                            }

                                        }
                                    }
                                    this.shiftKeyCheckBox=data.ID

                                }} id={`CheckBoxElement_${data.ID}`} ref={ref=>this.JOINS_elements[data.ID]=ref}/>
                                    <CheckBoxElement.Body className="row" onClick={()=>
                                        global.PopUp.Open({
                                            content:<POP_reqv_join
                                            ID_OBJECT={data.ID}
                                            callBack={()=>this.getREQV_JOINS()}
                                        />})
                                    }>
                                        <div className="col-2 p-0 m-0 text-left">{data.NAME.length>18?data.NAME.substr(0,18)+"..":data.NAME}</div>
                                        <div className="col-2 p-0 m-0 text-center">{data.DIAMETER}x{data.WIDTH}</div>
                                        <div className="col-5 COMMENT p-0 m-0">{data.COMMENT!=null?data.COMMENT.length>70?data.COMMENT.substr(0,70)+"..":data.COMMENT:null}</div>
                                        <div className="col-3 p-0 m-0 pr-2">
                                            <StatusText className={"si-"+data.ID_CONTROL_STATUS}>{data.CONTROL_STATUS_TITLE}</StatusText></div>
                                       
                                    </CheckBoxElement.Body>
                            </CheckBoxElement.View>
                        )}

                    </ScrollView>
                </div>
            </div>

            <div className="row mt-3">
                {this.renderButtons()}
            </div>
        </POP>)
    
    }
}
const POP = styled.div`
width:900px !important;

`
const BlockLeft = styled.div`
border-right: 1px rgb(241, 241, 241) solid;
`

const ScrollView = styled.div`
max-height:70vh;
overflow-y:scroll;
overflow-x:hidden;
margin-top:20px;
padding:5px;
border-radius:5px;

`

const StatusText = styled.p`
text-align: right;
margin-top: 15px;
padding: 4px;
border-radius: 5px;
width: max-content;
float: right;
`

const CheckBoxElement = {
    
    View:styled.div`
    margin:5px;
    line-height: 11px;
    transition:.3s;
    &:hover{
        background:rgb(250,250,250)
    }
    position:relative;
    padding-left:40px;
    cursor:pointer;
    & input{
        position:absolute;
        left:0px;
        top:6px;
    }
    & .row{
        align-items: center;
        align-content: center;
    }
    
    `,
    Body:styled.div`
    height: 25px;
    & div{
        font-size:13px;
    }
    & .text-left{
        font-weight:600;
    }
    & .COMMENT{
        font-size:11px;
    }
    
    `
}


