import React from 'react';
import '../../css/POP.css';
import '../../css/POP_reqv.css';


export default class POP_data extends React.Component {
    constructor(props) {
        super()
        this.state = {
        }
        this.input=[];

       

    }
    componentDidMount(){
       if(this.props.ID_OBJECT !=null){
        
        global.SERVER_task('operation=getSPR&ID='+this.props.ID_OBJECT,
            (data)=>{
                debugger
               this.input.VALUE.value=data.result[0].VALUE
            })
       }
    }

    Save(){
        if(!global.checkReqvireds(this.input)) return;

        var data={
            ID_TYPE:this.props.ID_TYPE,
            VALUE:this.input.VALUE.value
        }
        if(this.props.ID_OBJECT!=null)
            data.ID=this.props.ID_OBJECT

        global.SERVER_task('operation=createUpdateSPR_DATA&params='+JSON.stringify(data),
            (data)=>{
                global.PopUp.CloseFunction();
                try{
                    this.props.callBack()
                }catch{}
            })
    }

    Delete(){
        var data={
            ID:this.props.ID_OBJECT
        }

        global.SERVER_task('operation=deleteSPR_DATA&params='+JSON.stringify(data),
            (data)=>{
                global.PopUp.CloseFunction();
                try{
                    this.props.callBack()
                }catch{}
            })
    }
   
    render (){
      
        return(
        <div className="POP POP_reqv POP_reqv_item content-fluid " >
            <div className="row">
                <div className="col-12">
                    <h5 className='d-inline'>Запись</h5>
                    <input className="form-control mt-2" ref={ref=>this.input.VALUE=ref} placeholder="Значение переменной"/>
                </div>
                
            </div>

            <div className="row mt-3 mb-0">

                <div className="col-6 mb-0">
                      <p className="btn mb-0 btn-light btn-sm mx-auto d-block" onClick={()=>this.Save()}>Сохранить</p>
                      
                </div>
                <div className="col-6 mb-0">
                     
                      {this.props.ID_OBJECT!=null?
                        <p className="btn mb-0 btn-light btn-sm  mx-auto d-block" onClick={()=>this.Delete()}>Удалить</p>
                      :null}
                </div>


                
            </div>
        </div>)
    
    }
}

