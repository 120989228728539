import React from 'react';
import  FileViewer  from 'react-file-viewer';
import { withRouter } from "react-router";
import styled from 'styled-components';

class FilePreview extends React.Component {
  constructor(props) {
    super();
    this.state={
    }
   
  }

 
  render() {
    const {params} = this.props.match
    debugger
    return(
      <Bg className="contsiner p-3">
        <p className="text-light text-center">{params.FILE_TITLE}.{params.FILE_EXT}</p>
        <FileViewer                         
        fileType={params.FILE_EXT}
        filePath={"/server/files/"+params.FILE_URL+"."+params.FILE_EXT}
        onError={(e)=>console.error(e)}/>
        
      </Bg>
    )
  }

}

const Bg = styled.div`
backdrop-filter:blur(30px);
background: rgba(0,0,0,0.3);
height:100vh;
width:100vw;
position:fixed;
top:0px;
left:0px;
`


export default withRouter(FilePreview);