import React from 'react';
import '../../css/Legend.css';


export default class Legend extends React.Component {
    constructor(props) {
        super()
        this.state = {
            REQV_STATUSES:[]
        }
    }
   

    componentDidMount(){
        this.getSTAT();
    }

    getSTAT(){
        global.SERVER_task('operation=getREQV_STATUSES',
        (data)=>{
            this.setState({REQV_STATUSES:data.result});
        })
    }

    render (){

        let list = this.state.REQV_STATUSES.map((JSON)=>
            <p><div className={"zay-st st-"+JSON.ID}/> {JSON.TITLE}</p>
        )
      
        return (
            <div id="Legend">
                <p id="bt">Показать легенду</p>
                <div id="leg">
                    {list}
                </div>
            </div>
        )
    
    }
}


