import React from 'react';
import '../../css/Accaunt.css';
import POP_Qestion from '../../Components/POP_Qestion'
import POP_Users from './POP_User'
import { data } from 'jquery';


class Accaunt extends React.Component {
    constructor(props) {
        super()
        this.state = {
            Account:{
                ID:null
            },
            passChanging:false
        }

        this.ImgLoaded=false;
        this.input=[];

      
    }

    componentDidMount(){
        global.SERVER_task('operation=getAccount',
        (data)=>{
            this.setState({Account:data.result[0]});
            try{
            this.input.LOGIN.value=data.result[0].LOGIN;
            }catch{}
           
            this.input.EMAIL.value=data.result[0].EMAIL;
            this.input.PHONE.value=data.result[0].PHONE;

            this.input.DOLJNOST.value=data.result[0].DOLJNOST;
            this.input.PODRAZD.value=data.result[0].PODRAZD;

            global.checkFile(
                global.serverURL_Main+"img/accounts/img_"+data.result[0].ID+".jpg",
                ()=>{
                    document.querySelector("#PrevImg_img").style.backgroundImage = "url(" + global.serverURL_Main+"img/accounts/img_"+data.result[0].ID+".jpg?"+global.getRandomInt(10000) + ")";
                    document.querySelector("#PrevImg_img").style.backgroundColor = "rgba(255,255,255,0.3);";
                    document.querySelector("#PrevImg_img").style.backgroundSize = "cover";
                }
            )
           
            
                
        })
    }


    FilePrev() {
        var obj=document.getElementById("pct");
        debugger;
        if (obj.files[0]) {
          var fr = new FileReader();
          var THIS = this;
      
          fr.addEventListener("load", ()=>{
            document.querySelector("#PrevImg_img").style.backgroundImage = "url(" + fr.result + ")";
            document.querySelector("#PrevImg_img").style.backgroundColor = "rgba(255,255,255,0.3);";
            document.querySelector("#PrevImg_img").style.backgroundSize = "cover";
            this.ImgLoaded=true;
            
          }, false);
      
          fr.readAsDataURL(obj.files[0]);
        }else{
            this.ImgLoaded=false;
            
            document.querySelector("#PrevImg_img").style.backgroundImage = "url('/img/user_white.svg')";
            document.querySelector("#PrevImg_img").style.backgroundColor = "rgba(255,255,255,0.3);";
            document.querySelector("#PrevImg_img").style.backgroundSize = "100px";
        }
    }

    Save(){
        if(global.checkReqvireds(this.input)){
            var data={
                EMAIL:this.input.EMAIL.value,

                DOLJNOST:this.input.DOLJNOST.value,
                PODRAZD:this.input.PODRAZD.value,
                

                PHONE:this.input.PHONE.value
            };
            if(this.props.AppState.isLogined==3){
                data.LOGIN=this.input.LOGIN.value;
                data.PASSWORD=this.input.PASSWORD.value;
                if(data.LOGIN.length<4){
                    global.PopUp.Open({content:<POP_Qestion text="Слишком короткий логин" />})
                    return;
                }else if(data.LOGIN=="INIT"){
                    global.PopUp.Open({content:<POP_Qestion text="Измените логин с INIT на любой другой" />})
                    return;
                }
                if(data.PASSWORD!=null)
                    if(data.PASSWORD.length>=6){
                        global.PopUp.Open({content:<POP_Qestion text={"Вы уверены, что хотите изменить пароль на "+data.PASSWORD} buttons={[
                            ["Да, изменить",'danger',()=>this.Save_complite(data)],
                            ["Отмена",'']
                            ]}/>
                        })
                    }else{
                        global.PopUp.Open({content:<POP_Qestion text="Слишком короткий пароль" footer="нужно не менее 6ти символов" />})
                        return;
                    }
                else
                    this.Save_complite(data);
            }else if(this.state.passChanging){
                data.PASSWORD=this.input.PASSWORD.value;
                data.LOGIN=this.state.Account.LOGIN;
                if(data.PASSWORD!=null)
                    if(data.PASSWORD.length>=6){
                        global.PopUp.Open({content:<POP_Qestion text={"Вы уверены, что хотите изменить пароль на "+data.PASSWORD} buttons={[
                            ["Да, изменить",'danger',()=>this.Save_complite(data)],
                            ["Отмена",'']
                            ]}/>
                        })
                    }else{
                        global.PopUp.Open({content:<POP_Qestion text="Слишком короткий пароль" footer="нужно не менее 6ти символов" />})
                        return;
                    }
                else{
                    this.setState({passChanging:false});
                    this.Save_complite(data);
                }
            }
            else
                this.Save_complite(data);
            

        }
        
        
    }
    Save_complite(data){
        this.setState({passChanging:false});
        global.SERVER_task('operation=updateAccount&params='+JSON.stringify(data),
        (data)=>{
            if(data.result*1>0){
                if(this.ImgLoaded){
                    var ID = data.result*1;
                    var file_data = this.input.file.files[0];
                    var form_data = new FormData();
                    form_data.append('file', file_data);
                    global.UploadFlieToServer("accounts",ID,form_data);   
                }

                global.PopUp.Open({content:<POP_Qestion 
                    text="Сохранено"
                />})

                if(this.props.AppState.isLogined==3){
                    window.location.reload();
                }
            }
        })
    }

    Exit(){
        global.PopUp.Open({content:<POP_Qestion text="Вы уверены, что хотите завершить сессию?" buttons={[
             ["Да, выйти",'danger',()=>this.Exit_complite()],
             ["Отмена",'']
             ]}/>
         })
     }
     Exit_complite(){
         global.SERVER_task('operation=ExitAccount',
             (data)=>{
                window.location.reload();
             })
     }

    render (){
        return  <div className="Accaunt " >
                    <div className="container-fluid m-0" >
                        <div className="row main_pages">
                            <div className="col-md-4 left" id="PrevImg_img">
                                <div id="PrevImg_cont">
                                    <label className="filePrev btn btn-light btn-sm" id="PrevImg" for="pct"> Выбрать изображение</label>
                                </div>
                               
                               
                                <input type="file" id="pct" ref={ref => this.input.file = ref} onChange={()=>this.FilePrev()} hidden class=" mb-2 w-100" />
                            </div>
                            <div className="col-md-8 right p-4">
                                <p  className="text-muted mb-0">Приветствую,</p>
                                <h1 className="pl-2 pb-0 mb-0">{this.state.Account.FIO}</h1>
                                
                                {this.props.AppState.isLogined==3 || this.state.passChanging?
                                    <div>
                                        
                                        {!this.state.passChanging?
                                            <div>
                                                <h5 className="mb-3 mt-0 pt-0"> {this.state.Account.ROLE_TITLE}</h5>
                                                <div className="alert alert-warning">
                                                    Измените сгенерированные системой данные - Логин и Пароль на удобные вам
                                                </div>
                                                <div class="input-group mb-2">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"  id="basic-addon1">Логин</span>
                                                    </div>
                                                        <input type="text" ref={ref => this.input.LOGIN = ref} class="form-control" placeholder="Login" />
                                                </div>
                                            </div>
                                            :<h5 className="mb-3 mt-0 pt-0"><b>{this.state.Account.LOGIN}</b> {this.state.Account.ROLE_TITLE}</h5>
                                        }
                                        
                                        <div class="input-group mb-4">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"  id="basic-addon1">Пароль</span>
                                            </div>
                                                <input type="password" ref={ref => this.input.PASSWORD = ref} class="form-control" placeholder="********" />
                                        </div>
                                    </div>
                                    :<h5 className="mb-3 mt-0 pt-0"><b>{this.state.Account.LOGIN}</b> {this.state.Account.ROLE_TITLE}</h5>
                                }
                                
                                
                                <div class="input-group mb-2">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"  id="basic-addon1">E-mail</span>
                                    </div>
                                        <input type="email" ref={ref => this.input.EMAIL = ref} class="form-control" placeholder="ex@mail.ex" />
                                </div>
                                <div class="input-group mb-2">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"  id="basic-addon1">Телефон</span>
                                    </div>
                                        <input type="email" ref={ref => this.input.PHONE = ref} class="form-control" placeholder="+79000000000" />
                                </div>


                                <div class="input-group mb-2">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"  id="basic-addon1">Должность</span>
                                    </div>
                                        <input type="email" ref={ref => this.input.DOLJNOST = ref} class="form-control"  />
                                </div>
                                <div class="input-group mb-2">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"  id="basic-addon1">Руководитель подразделения</span>
                                    </div>
                                        <input type="email" ref={ref => this.input.PODRAZD = ref} class="form-control"  />
                                </div>
                               
                                <div className="container-fluid">
                                    <div className="row row-cols-2 ">
                                        <div className="col pl-0">
                                            <button onClick={()=>this.Save()} type="button" class="btn btn-primary btn-sm w-100">СОХРАНИТЬ ИЗМЕНЕНИЯ</button>
                                        </div>
                                        <div className="col pr-0">
                                            <div class="dropdown w-100">
                                                <button class="btn btn-sm btn-light dropdown-toggle w-100" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                   ДРУГИЕ ДЕЙСТВИЯ
                                                </button>
                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    {global.Auth.ROLE==1 || global.Auth.ROLE==6?
                                                        <a  class="dropdown-item"
                                                            onClick={()=>
                                                                global.PopUp.Open({content:<POP_Users />})
                                                            }>
                                                            Контроль пользователей</a>
                                                    :null}
                                                    <a class="dropdown-item" onClick={()=>this.setState({passChanging:!this.state.passChanging})}>{this.state.passChanging?"Отменить изменения пароля":"Сменить пароль"}</a>
                                                    <div class="dropdown-divider"></div>
                                                    <a class="dropdown-item" onClick={()=>this.Exit()}>Выйти из аккаунта</a>
                                                </div>
                                            </div>
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>


                           

                        </div>
                    </div>
                </div>
    
    }
}

export default Accaunt;
