import React from 'react';
import '../../css/POP.css';
import '../../css/POP_reqv.css';
import POP_Qestion from '../../Components/POP_Qestion'
import POP_reqv_item from './POP_reqv_item'
import POP_Select from '../../Components/POP_Select'
import POP_reqv_messages from "./POP_reqv_messages"
import POP_reqv_joins_create from './POP_reqv_joins_create';
import styled from 'styled-components';
import POP_reqv_set_status from './POP_reqv_set_status';


export default class POP_reqv_join extends React.Component {
    constructor(props) {
        super()
        this.state = {
            REQV_JOINS:{},
            REQV_ITEMS:[],
            CONTROL_STATUSES:[],
        }
        this.REQV_JOINS_TO_UPLOAD=[]
        this.CheckBox_elements={}
        this.shiftKeyCheckBox=null
        this.input=[];
        this.ID_REQV_CONT=props.ID_OBJECT

        this.Mainfields=[
            {
                name:"NAME",
                descr:"Наименование стыка"
            },
            {
                name:"WELDER",
                descr:"Сварщик"
            },
            {
                name:"WELDER_TYPE",
                descr:"Тип сварки"
            },
            {
                name:"ID_MATERIAL_WELDER",
                descr:"Сварочный материал",
                type:"select",
                values:global.SPR["Сталь"].DATA
            },
            {
                name:"DIAMETER",
                descr:"Диаметр"
            },
            {
                name:"WIDTH",
                descr:"Толщина"
            },
            {
                name:"COMMENT",
                descr:"Комментарий к стыку",
                type:"textarea",
                className:"t2"
            }
            
        ]

        this.VIEW_MainFileds = global.genFields(this,this.Mainfields,1)

    }

    
    componentDidMount(){
        this.getCONTROL_STATUSES()

        this.getREQV_JOINS()
            .then(()=>this.getREQV_ITEMS())
    }


    getCONTROL_STATUSES(){
        global.SERVER_task('operation=getCONTROL_STATUSES',
        (data)=>{
            this.setState({CONTROL_STATUSES:data.result});
        })
    }

    getREQV_JOINS(){
        return new Promise((resolve, reject)=>{
           
            global.SERVER_task('operation=getREQV_JOINS&ID='+this.props.ID_OBJECT,
            (data)=>{
                try{
                     
                    this.setState({REQV_JOINS:data.result[0]})
                   
                    this.Mainfields.map((field)=>{
                        this.input[field.name].value=data.result[0][field.name]
                    })
                }catch{
                    reject();
                }
                setTimeout(()=>resolve(),5);
            })
        })
        
    }

   

    getREQV_ITEMS(){
        this.deselectAll()
        global.SERVER_task('operation=getREQV_ITEMS&ID_REQV_JOIN='+this.props.ID_OBJECT,
        (data)=>{
            this.setState({REQV_ITEMS:data.result});
           
        })
    }

    deleteREQV_JOINS(){
        let data={
            IDs:[]
        }

        let arr ={}
        Object.keys(this.CheckBox_elements).map((key,i)=>{
            if(this.CheckBox_elements[key]!=null)
                arr[key]=this.CheckBox_elements[key]
        })

        Object.keys(arr).map((key,i)=>{
            if(this.CheckBox_elements[key].checked)
                data.IDs.push(this.state.REQV_ITEMS[this.state.REQV_ITEMS.length-i-1].ID)
        })

        if(data.IDs.length>0)
            global.SERVER_task('operation=deleteREQV_ITEMS&params='+JSON.stringify(data),
            (data)=>{
                this.getREQV_ITEMS()
            })
    }

    deleteCONT(){
        let data={
            ID:this.state.REQV_CONT.ID
        };

        global.SERVER_task('operation=deleteREQV_CONT&params='+JSON.stringify(data),
        (data)=>{
            if(data.result=="ok"){
                global.PopUp.CloseFunction();
                try{
                    this.props.callBack();
                }catch{}
            }else{
                global.PopUp.Open({content:<POP_Qestion text="Невозможно удалить запрос"/>})
            }
        })
    }

    Save(){
       
            if(!global.checkReqvireds([this.input.NAME])) return;
    
            let data={};
            
            this.Mainfields.map((field)=>{
                data[field.name]=this.input[field.name].value;
            })
            
            data.ID=this.props.ID_OBJECT
    
                
            global.SERVER_task('operation=updateREQV_JOINS&params='+JSON.stringify(data),
            (data)=>{
                this.getREQV_JOINS()
                global.PopUp.Open({content:<POP_Qestion text="Изменения сохранены"/>})
                try{
                    this.props.callBack()
                }catch{}
            })

   
    }

    complite(){
        let data={
            ID:this.state.REQV_CONT.ID,
            ID_REQVEST:this.state.REQV_CONT.ID_REQVEST
        }

        global.SERVER_task('operation=compliteREQV_CONT&params='+JSON.stringify(data),
        (data)=>{
            if(data.result=="ok" || data.result=="ok all"){

                global.API_task(global.API.SEND_MAIL,{
                    EMAIL:this.state.REQV_CONT.OVNER_EMAIL,
                    FROM:"Веско",
                    SHABLON:"TEXT",
                    TEXT:{
                        ":TEXT":"Запрос на исследование "+this.state.REQV_CONT.METHOD_NK+" по заявке №"+this.state.REQV_CONT.ID_REQVEST+" перешел в статус 'Исполенно'"
                    }
                },(result)=>{
                })

                global.API_task(global.API.SEND_MAIL,{
                    EMAIL:this.state.REQV_CONT.NACH_EMAIL,
                    FROM:"Веско",
                    SHABLON:"TEXT",
                    TEXT:{
                        ":TEXT":"Запрос на исследование "+this.state.REQV_CONT.METHOD_NK+" по заявке №"+this.state.REQV_CONT.ID_REQVEST+" перешел в статус 'Исполенно'"
                    }
                },(result)=>{
                })

                global.API_task(global.API.SEND_MAIL,{
                    EMAIL:this.state.REQV_CONT.DIR_EMAIL,
                    FROM:"Веско",
                    SHABLON:"TEXT",
                    TEXT:{
                        ":TEXT":"Запрос на исследование "+this.state.REQV_CONT.METHOD_NK+" по заявке №"+this.state.REQV_CONT.ID_REQVEST+" перешел в статус 'Исполенно'"
                    }
                },(result)=>{
                })



                if(data.result=="ok all"){
                    global.API_task(global.API.SEND_MAIL,{
                        EMAIL:this.state.REQV_CONT.OVNER_EMAIL,
                        FROM:"Веско",
                        SHABLON:"TEXT",
                        TEXT:{":TEXT":"Заявка №"+this.state.REQV_CONT.ID_REQVEST+" перешела в статус 'Исполенно'"}
                    },(result)=>{})
    
                    global.API_task(global.API.SEND_MAIL,{
                        EMAIL:this.state.REQV_CONT.NACH_EMAIL,
                        FROM:"Веско",
                        SHABLON:"TEXT",
                        TEXT:{":TEXT":"Заявка №"+this.state.REQV_CONT.ID_REQVEST+" перешела в статус 'Исполенно'"}
                    },(result)=>{})

                    global.API_task(global.API.SEND_MAIL,{
                        EMAIL:this.state.REQV_CONT.DIR_EMAIL,
                        FROM:"Веско",
                        SHABLON:"TEXT",
                        TEXT:{":TEXT":"Заявка №"+this.state.REQV_CONT.ID_REQVEST+" перешела в статус 'Исполенно'"}
                    },(result)=>{})
                }



                global.PopUp.CloseFunction();
                try{
                    this.props.callBack();
                }catch{}
            }
            if(data.result=="items not comlited"){
                global.PopUp.Open({content:<POP_Qestion text="Не все участки проконтролированы"/>})
            }
        })
    }


    
    setRESPONSIBLE_PERSON(selected){
        
        let data={
            ID_USER:selected[0],
            ID:this.state.REQV_CONT.ID,
            ID_REQVEST:this.state.REQV_CONT.ID_REQVEST
        }
        global.SERVER_task('operation=setsetRESPONSIBLE_PERSON_TO_REQV&params='+JSON.stringify(data),
        (data)=>{
            if(data.result=="ok"){

                global.API_task(global.API.SEND_MAIL,{
                    EMAIL:this.state.REQV_CONT.OVNER_EMAIL,
                    FROM:"Веско",
                    SHABLON:"TEXT",
                    TEXT:{":TEXT":"Заявка №"+this.state.REQV_CONT.ID_REQVEST+" метод "+this.state.REQV_CONT.METHOD_NK+" передана в работу"}
                },(result)=>{})

                this.getCONT()
                    .then(()=>{
                        global.API_task(global.API.SEND_MAIL,{
                            EMAIL:this.state.REQV_CONT.NACH_EMAIL,
                            FROM:"Веско",
                            SHABLON:"TEXT",
                            TEXT:{":TEXT":"Вы назначены ответственным для Заявки №"+this.state.REQV_CONT.ID_REQVEST+" метод "+this.state.REQV_CONT.METHOD_NK}
                        },(result)=>{})
                    })

                try{
                    this.props.callBack();
                }catch{}
            }else{
                global.PopUp.Open({content:<POP_Qestion text="Невозможно изменить статус"/>})
            }
        })
    }


    
    setNACH(selected){
        
        let data={
            ID_NACHLNIK:selected[0],
            ID:this.state.REQV_CONT.ID
        }
        global.SERVER_task('operation=setNACH_TO_REQV&params='+JSON.stringify(data),
        (data)=>{
            if(data.result=="ok"){

                global.API_task(global.API.SEND_MAIL,{
                    EMAIL:this.state.REQV_CONT.OVNER_EMAIL,
                    FROM:"Веско",
                    SHABLON:"TEXT",
                    TEXT:{":TEXT":"Заявка №"+this.state.REQV_CONT.ID_REQVEST+" метод "+this.state.REQV_CONT.METHOD_NK+" передана на рассмотрение начальнику лаборатории"}
                },(result)=>{})

                this.getCONT()
                    .then(()=>{
                        global.API_task(global.API.SEND_MAIL,{
                            EMAIL:this.state.REQV_CONT.NACH_EMAIL,
                            FROM:"Веско",
                            SHABLON:"TEXT",
                            TEXT:{":TEXT":"Вы назначены ответственным для Заявки №"+this.state.REQV_CONT.ID_REQVEST +" метод "+this.state.REQV_CONT.METHOD_NK}
                        },(result)=>{
                            global.PopUp.Open({content:<POP_Qestion text="Ответственное лицо назначено"/>})
                        })
                    })

                try{
                    this.props.callBack();
                }catch{}
            }else{
                global.PopUp.Open({content:<POP_Qestion text="Невозможно изменить статус"/>})
            }
        })
    }

    ChangeREQVSTATUS(ID_STATUS){
        if(ID_STATUS==6){
            global.API_task(global.API.SEND_MAIL,{
                EMAIL:this.state.REQV_CONT.OVNER_EMAIL,
                FROM:"Веско",
                SHABLON:"TEXT",
                TEXT:{":TEXT":"Заявка №"+this.state.REQV.ID+" была отклонена"}
            },(result)=>{})
        }

        if(ID_STATUS==2){
            global.API_task(global.API.SEND_MAIL,{
                EMAIL:this.state.REQV_CONT.DIR_EMAIL,
                FROM:"Веско",
                SHABLON:"TEXT",
                TEXT:{":TEXT":"Заявка №"+this.state.REQV.ID+" поступила на рассмотрение"}
            },(result)=>{})
        }

        if(ID_STATUS==1){
            global.API_task(global.API.SEND_MAIL,{
                EMAIL:this.state.REQV_CONT.NACH_EMAIL,
                FROM:"Веско",
                SHABLON:"TEXT",
                TEXT:{":TEXT":"Заявка №"+this.state.REQV.ID+" снята заказчиком с рассмотрения"}
            },(result)=>{})
        }


        let data={
            ID:this.state.REQV_CONT.ID,
            ID_REQVEST:this.state.REQV_CONT.ID_REQVEST,
            ID_STATUS:ID_STATUS
        };
        

        global.SERVER_task('operation=setStatusREQV_CONT&params='+JSON.stringify(data),
        (data)=>{
            if(data.result=="ok"){
                this.getCONT();
                try{
                    this.props.callBack();
                }catch{}
            }else{
                global.PopUp.Open({content:<POP_Qestion text="Невозможно изменить статус"/>})
            }
        })
    }

    CreateOtchot(){
        let data={
            ID_REQVEST:this.state.REQV_CONT.ID_REQVEST,
            ID_CONT:this.state.REQV_CONT.ID
        }
        var operation=null
        switch(this.state.REQV_CONT.ID_METHOD){
            case 3:operation="VIK_generateDoc"; break;
            case 2:operation="UZK_generateDoc"; break;
            case 1:operation="RK_generateDoc"; break;
        }
        
        if(operation==null) return;

        global.SERVER_task('operation='+operation+'&params='+JSON.stringify(data),
        (data)=>{
            window.open(global.serverURL_Main+data.result, '_blank');
        })

      
    }

    groupControl(){
        let data=[]

        let arr ={}
        Object.keys(this.CheckBox_elements).map((key,i)=>{
            if(this.CheckBox_elements[key]!=null)
                arr[key]=this.CheckBox_elements[key]
        })

        Object.keys(arr).map((key,i)=>{
            if(this.CheckBox_elements[key].checked)
                data.push(this.state.REQV_ITEMS[this.state.REQV_ITEMS.length-i-1].ID)
        })

        global.PopUp.Open({content:
            <POP_reqv_set_status
                ID_OBJECTS={data}
                TYPE_OBJECTS="ITM"
                callBack={()=>this.getREQV_ITEMS()}
            />
        })
    }

    setStatus(ID){
       
        let data={
            ID:this.state.REQV_JOINS.ID,
            ID_CONTROL_STATUS:ID
        };

        global.SERVER_task('operation=joinSetStatus&params='+JSON.stringify(data),
        (data)=>{
            global.PopUp.CloseFunction();
            try{
                this.props.callBack();
            }catch{}
  
        })
    }

    deselectAll(){
        let arr ={}
        Object.keys(this.CheckBox_elements).map((key,i)=>{
            if(this.CheckBox_elements[key]!=null)
                arr[key]=this.CheckBox_elements[key]
        })

        Object.keys(arr).map((key,i)=>{
            this.CheckBox_elements[key].checked=false
        })
        
    }

    renderButtons(){
        const {REQV_CONT,USERS,REQV_ITEMS} = this.state
        let content=[
            <button onClick={()=>this.Save()} class=" btn btn-sm m-1 btn-primary">Обновить</button>
        ]


        if(REQV_ITEMS.filter(item=>item.ID_CONTROL_STATUS!=5).length==REQV_ITEMS.length)
            content.push(
                <div class="dropdown m-1 float-right">
                    <button class="btn btn-sm btn-light dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Установить статус
                    </button>
                    <div class="dropdown-menu p-0" aria-labelledby="dropdownMenuButton">
                        {this.state.CONTROL_STATUSES.map((JSON)=> 
                            <a onClick={()=>this.setStatus(JSON.ID)}
                                className={`dropdown-item ${(JSON.ID!=5?"text-white":"")} si-${JSON.ID}`}
                            >{JSON.TITLE}</a>
                        )}
                    </div>
                </div>   
            )
        

        return <div className="col-12">{content}</div>
    }


    render (){
        const {REQV_JOINS,REQV_ITEMS} = this.state

        

       
        return(
        <POP className=" content-fluid " >
            <div className="row">
                <BlockLeft className="col-4" comment="LEFT">
                    <h5 className='d-inline'>Редактирование стыка</h5>
                    <small className='d-inline float-right'>{REQV_JOINS.STATUS_TITLE}</small>
                    <br></br>
                    <ScrollView>

                        <p className="mb-0">Основные параметры</p>
                        {this.VIEW_MainFileds}


                    </ScrollView>
                    
                </BlockLeft>
                <div className="col-8 right" comment="RIGHT">
                <small className='d-inline float-right text-right'>
                        {REQV_JOINS.CONTROL_STATUS_TITLE}
                        <br/>
                        <Sss>{global.makeNormalDate(REQV_JOINS.DATE_CONTROL,"dd.mm.yyyy H:M")}</Sss>
                    </small>
                    <h5>{REQV_JOINS.ID_METHOD==1?"Пленки":"Участки"} {REQV_ITEMS.length}шт</h5>
                    

                    <div class="btn-group" role="group" >
                        <button class="btn btn-primary btn-sm" onClick={()=>this.groupControl()}>Групповой контроль</button>

                        <button class="btn btn-light btn-sm"
                        onClick={()=>global.PopUp.Open({
                            content:<POP_reqv_item
                            ID_REQV_JOIN={REQV_JOINS.ID}
                            callBack={()=>this.getREQV_ITEMS()}
                        />})}>Добавить {REQV_JOINS.ID_METHOD==1?"пленку":"участок"}</button>

                        <button class="btn btn-light btn-sm" onClick={()=>{
                            let arr ={}
                            Object.keys(this.CheckBox_elements).map((key,i)=>{
                                if(this.CheckBox_elements[key]!=null)
                                    arr[key]=this.CheckBox_elements[key]
                            })

                            Object.keys(arr).map(key=>{
                                this.CheckBox_elements[key].checked=true
                            })
                        }}>Выбрать все</button>
                        <button class="btn btn-light btn-sm" onClick={()=>this.deselectAll()}>Отменить выделение</button>


                        <button class="btn btn-danger btn-sm" onClick={()=>this.deleteREQV_JOINS()}>Удалить выбранные</button>
                    </div>
                    

                    <ScrollView>
                        
                        {REQV_ITEMS.map((data)=>
                            <CheckBoxElement.View key={'CheckBoxElement_'+data.ID}>
                                <input type="checkbox" onClick={(e)=>{
                                    if(e.shiftKey){
                                        if(this.shiftKeyCheckBox!=null){

                                            let Direction = null
                                            if(this.shiftKeyCheckBox<data.ID)
                                                Direction="toTop"
                                            else if(this.shiftKeyCheckBox>data.ID)
                                                Direction="toBottom"

                                            if(Direction!=null){  //шифт не на том же самом элементе
                                                
                                                let arr ={}
                                                Object.keys(this.CheckBox_elements).map((key,i)=>{
                                                    if(this.CheckBox_elements[key]!=null)
                                                        arr[key]=this.CheckBox_elements[key]
                                                })
                                                
                                                Object.keys(arr).map((key,i)=>{
                                                    let elemView = this.CheckBox_elements[key]
                                                    let elem = REQV_ITEMS[REQV_ITEMS.length-i-1]

                                                        
                                                    if(
                                                        (elem.ID < data.ID && elem.ID > this.shiftKeyCheckBox && Direction=="toTop") ||
                                                        (elem.ID > data.ID && elem.ID < this.shiftKeyCheckBox && Direction=="toBottom")
                                                    ){
                                                        
                                                        this.CheckBox_elements[key].checked=!elemView.checked
                                                    }
                                                })

                                            }

                                        }
                                    }
                                    this.shiftKeyCheckBox=data.ID

                                }} id={`CheckBoxElement_${data.ID}`} ref={ref=>this.CheckBox_elements[data.ID]=ref}/>
                                    <CheckBoxElement.Body className="row" onClick={()=>global.PopUp.Open({
                                        content:<POP_reqv_item
                                        ID_OBJECT={data.ID}
                                        callBack={()=>this.getREQV_ITEMS()}
                                    />})}>
                                        <div className="col-2 p-0 m-0 text-left">{data.CONTROL_PLACE.length>15?data.CONTROL_PLACE.substr(0,15)+"..":data.CONTROL_PLACE}</div>
                                        <div className="col-6 p-0 m-0 ">{data.DESCR!=null?data.DESCR.length>70?data.DESCR.substr(0,70)+"..":data.DESCR:null}</div>
                                        <div className="col-4 p-0 m-0 pr-2">
                                            <StatusText className={"si-"+data.ID_CONTROL_STATUS}>{data.CONTROL_STATUS_TITLE}</StatusText></div>
                                    </CheckBoxElement.Body>
                            </CheckBoxElement.View>
                        )}

                    </ScrollView>
                </div>
            </div>

            <div className="row mt-3">
                {this.renderButtons()}
            </div>
        </POP>)
    
    }
}

const POP = styled.div`
width:880px !important;
`
const BlockLeft = styled.div`
border-right: 1px rgb(241, 241, 241) solid;
`



const ScrollView = styled.div`
height:70vh;
overflow-y:scroll;
overflow-x:hidden;
margin-top:20px;
padding:5px;
border-radius:5px;

`

const StatusText = styled.p`
text-align: right;
margin-top: 15px;
padding: 4px;
border-radius: 5px;
width: max-content;
float: right;
`


const CheckBoxElement = {
    
    View:styled.div`
    margin:5px;
    line-height: 11px;
    transition:.3s;
    &:hover{
        background:rgb(250,250,250)
    }
    position:relative;
    padding-left:40px;
    cursor:pointer;
    & input{
        position:absolute;
        left:0px;
        top:6px;
    }
    & .row{
        align-items: center;
        align-content: center;
    }
    
    `,
    Body:styled.div`
    height: 25px;
    & div{
        font-size:13px;
    }
    & .text-left{
        font-weight:600;
    }
    & .COMMENT{
        font-size:11px;
    }
    
    `
}

const Sss = styled.div`
font-size:10px;
color:rgb(150,150,150)
`
