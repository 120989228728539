import React from 'react';
import '../../css/_1.css';
import '../../css/Zaiavki.css';
import POP_reqv from './POP_reqv'
import POP_reqv_cont from './POP_reqv_cont'
import POP_object from './POP_object'
import Legend from './Legend';


export default class Zaiavki_zaka extends React.Component {
    constructor(props) {
        super()
        this.state = {
            OBJs:[],
            ID_REQV_opened:null,
            REQV:{},
            ID_OBJECT_selected:null
        }
    }
   

    componentDidMount(){
        this.getOBJ();
        global.addTimer("takeReqvests",()=>{
            if(global.CURRENT_PAGE==10){
                this.getOBJ();
                
            }
        },60000)
    }

    getOBJ(){
        return new Promise((resolve,reject)=>{
            global.SERVER_task('operation=getOBJ',
            (data)=>{
                try{
                this.setState({OBJs:data.result});
                setTimeout(()=>this.getREQV_OBJ(),5);
                setTimeout(()=>resolve());
                }catch{
                    reject();
                }
            })
        })
        
    }

    getREQV_OBJ(){
        
        global.SERVER_task('operation=getREQV_OBJ',
        (data)=>{
            var OBJs=this.state.OBJs;
            for(let i=0;i<OBJs.length;i++){
                OBJs[i].REQVs=[];
            }

            data.result.map((REQV)=>{
                for(let i=0;i<OBJs.length;i++){
                    if(OBJs[i].ID==REQV.ID_OBJECT){
                        OBJs[i].REQVs.push(REQV);
                        break;
                    }
                }
            })
            this.setState({OBJs:OBJs});
        })
    }

    createREQV(ID_OBJECT){
        var data={
            ID_OBJECT:ID_OBJECT
        };
        this.setState({ID_OBJECT_selected:ID_OBJECT})
        global.SERVER_task('operation=createUpdateREQV&params='+JSON.stringify(data),
        (data)=>{
            
           this.setState({ID_REQV_opened:data.result})
           setTimeout(()=>this.getREQV_OBJ(),5);
        })
    }

    

    render (){
        let REQV;
        if(this.state.ID_REQV_opened!=null){
            REQV=<POP_reqv callBack={()=>this.getREQV_OBJ()} onClose={()=>this.setState({ID_REQV_opened:null})} ID_OBJECT={this.state.ID_REQV_opened}/>
        }else
            REQV=<p>Заявка не выбрана</p>

        let list_OBJ = this.state.OBJs.map((JSON,i)=>{
           
            var hr=null;
            if(i>0) hr=<hr></hr>

            let REQVs=[]
            if(JSON.REQVs!=null)
                REQVs=JSON.REQVs.map((REQV)=>
                    <p className={this.state.ID_REQV_opened==REQV.ID?"active":""} onClick={()=>{
                        this.setState({ID_OBJECT_selected:JSON.ID,ID_REQV_opened:null});
                        setTimeout(()=>this.setState({ID_REQV_opened:REQV.ID}),1);
                    }}>Заявка {REQV.ID_VIS} <div className={"zay-st st-"+REQV.ID_STATUS}></div></p>
                )

            return(
                <div className="mb-2">
                    <div key={"bl"+JSON.ID}  className={"obj"+(JSON.ID==this.state.ID_OBJECT_selected?" active":"")} style={{height:(JSON.ID==this.state.ID_OBJECT_selected?60+REQVs.length*45:60)}} 
                    onClick={()=>{
                        if(this.state.ID_OBJECT_selected!=JSON.ID)
                            this.setState({ID_OBJECT_selected:JSON.ID,ID_REQV_opened:null});
                    }}>
                        <div className="float-right btn btn-sm " onClick={()=>this.createREQV(JSON.ID)}>+</div>
                            <b onClick={
                                ()=>
                                    global.onDBClick(JSON.ID,()=>{
                                        global.PopUp.Open({content:<POP_object
                                            ID_OBJECT={JSON.ID}
                                            callBack={()=>this.getOBJ()}
                                            />})
                                    })

                            }>{JSON.TITLE}</b>
                            {REQVs.length>0?<hr></hr>:null}
                        
                            {REQVs}
                    </div>
                </div>
            )

        })

        return  <div className="_1 Zaiavki Zaiavki_zaka" >
                    <div class="container-fluid m-0 " >
                        <div class="row main_pages ">
                            <div className="col-lg-2 col-md-5 left">

                            {this.props.TITLE}
                            <Legend/>
                           
                           
                            {list_OBJ}
                            <div id="smoller">
                                    
                                   
                                       <div id="PrevImg_cont">
                                            <div className="btn btn-primary btn-sm " id="upload" for="pct"
                                            onClick={
                                                ()=>
                                                global.PopUp.Open({content:<POP_object
                                                callBack={()=>{
                                                    var THIS=this;
                                                    this.getOBJ()
                                                        .then(()=>{
                                                            THIS.setState({ID_OBJECT_selected:THIS.state.OBJs[0].ID,ID_REQV_opened:null});
                                                        })
                                                    
                                                }}
                                                />})
                                            }> НОВЫЙ ОБЪЕКТ</div>
                                        </div>
                                    
                                    
                                    
                                </div>
                                
                                
                               
                            </div>  
                            <div className="col-lg-10 col-md-7 right   m-0 p-3 scroll">
                                {REQV}
                            </div> 

                            
                        </div>
                    </div>
                </div>
    
    }
}


