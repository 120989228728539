import React from 'react';
import '../css/Header.css';
import POP_Qestion from "../Components/POP_Qestion"

class Header extends React.Component {
    constructor(props) {
        super()
        this.state = {
            menu:[]
        }

        this.pages_def=[
        <div class="col " onClick={()=>this.props.openPage(1)}>
            <img className="align-middle"  src="/server/img/list.svg"></img> <p className="align-middle">
            Файловый<br></br>
            менеджер</p>
        </div>,
       
        <div class="col" onClick={()=>this.Exit()} style={{
            position: 'fixed',
            right: 20,
            width: 120
        }}>
            <img src="/server/img/logout.svg"></img> <p>
            Выход<br></br>
            из кабинета</p>
        </div>
        ]


        this.pages_zakaz=[
            <div class="col " onClick={()=>this.props.openPage(1)}>
                <img className="align-middle"  src="/server/img/list.svg"></img> <p className="align-middle">
                Файловый<br></br>
                менеджер </p>
            </div>,
            <div class="col" onClick={()=>this.props.openPage(10)}>
                <img src="/server/img/tasks.svg"></img> <p>
                Журнал<br></br>
                Заявок</p>
            </div>,
           
            <div class="col" onClick={()=>this.props.openPage(6)}>
                <img src="/server/img/statistics.svg"></img> <p>
                Раздел<br></br>
                Отчеты</p>
            </div>,
            <div class="col" onClick={()=>this.props.openPage(7)}>
                <img src="/server/img/alert.svg"></img> <p>
                Техническая<br></br>
                поддержка</p>
            </div>,
            <div class="col" onClick={()=>this.Exit()}>
                <img src="/server/img/logout.svg"></img> <p>
                Выход<br></br>
                из кабинета</p>
            </div>
            ]

            this.pages_nach=[
                <div class="col" onClick={()=>this.props.openPage(1)}>
                    <img className="align-middle"  src="/server/img/list.svg"></img> <p className="align-middle">
                    Файловый<br></br>
                    менеджер </p>
                </div>,
                <div class="col" onClick={()=>this.props.openPage(10)}>
                    <img src="/server/img/tasks.svg"></img> <p>
                    Журнал<br></br>
                    Заявок</p>
                </div>,
               
                <div class="col" onClick={()=>this.props.openPage(6)}>
                    <img src="/server/img/statistics.svg"></img> <p>
                    Раздел<br></br>
                    Отчеты</p>
                </div>,
                <div class="col" onClick={()=>this.props.openPage(11)}>
                    <img src="/server/img/server.svg"></img> <p>
                    Раздел<br></br>
                    Справочник</p>
                </div>,
                <div class="col" onClick={()=>this.props.openPage(7)}>
                    <img src="/server/img/alert.svg"></img> <p>
                    Техническая<br></br>
                    поддержка</p>
                </div>,
                <div class="col" onClick={()=>this.props.openPage(8)}>
                    <img src="/server/img/shield.svg"></img> <p>
                    Служба<br></br>
                    безопастности</p>
                </div>,
                <div class="col" onClick={()=>this.Exit()}>
                    <img src="/server/img/logout.svg"></img> <p>
                    Выход<br></br>
                    из кабинета</p>
                </div>
                ]


            

            this.waitROLE();
        
 
    }

    Exit(){
        global.PopUp.Open({content:<POP_Qestion text="Вы уверены, что хотите завершить сессию?" buttons={[
             ["Да, выйти",'danger',()=>this.Exit_complite()],
             ["Отмена",'']
             ]}/>
         })
     }
     Exit_complite(){
         global.SERVER_task('operation=ExitAccount',
             (data)=>{
                window.location.reload();
             })
     }

    waitROLE(){
        if(global.Auth.ROLE==null){

            setTimeout(()=>this.waitROLE(),20);
            return;
        }
        let menu=null;
        if(global.Auth.ROLE<4)
            menu=this.pages_def;
        if(global.Auth.ROLE==4)
            menu=this.pages_zakaz;
        if(global.Auth.ROLE==5 || global.Auth.ROLE==6)
            menu=this.pages_nach;

        this.setState({menu:menu});
    }

    render (){
        return  <div className="Header" >
                    <div class="container-fluid p-0 m-0" >
                        <div class="row  justify-content-md-center p-0 m-0">
                            
                            <div class="col-lg-11 " id="menuButs">
                                <div class="container-fluid p-0 m-0">
                                    <div class="row row-cols-2 p-0 ">
                                    

                                    {this.state.menu}

                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
    
    }
}

export default Header;