import React from 'react';
import '../../css/_1.css';
import '../../css/DataBase.css';
import $ from "jquery"
import POP_data from "./POP_data"

export default class extends React.Component {
    constructor(props) {
        super()
       
        this.state = {
            SPR:[],
            selectedSPR:null,
            selectedID_DATA_SPR:null
        }
       
        this.input=[]

    }
   

    componentDidMount(){
        this.getSPR_DATA()
    }

    getSPR_DATA(){
        return new Promise((resolve,reject)=>{
            global.SERVER_task('operation=getSPR',
            (data)=>{
              var datas=[]
              var  SPRNormal=[]
    
              var TYPES = Object.keys(global.SPR)
              TYPES.map(type=>{
    
               
                global.SPR[type].DATA=[]
                data.result.map(sprRow=>{
                  if(sprRow.ID_TYPE==global.SPR[type].ID){
                    delete sprRow.TYPE
                    delete sprRow.ID_TYPE
                    global.SPR[type].DATA.push(sprRow)
                  }else{
                    datas.push(sprRow)
                  }
                })
                data.result=datas
                datas=[]
                SPRNormal.push({
                    ID:global.SPR[type].ID,
                    TITLE:type,
                    DATA:global.SPR[type].DATA
                })
              })
              
              this.setState({SPR:SPRNormal})
              setTimeout(()=>resolve(),2)
            
            })
        })
        
    }

   
    
    updateRow(ID,VALUE){
        var data={
            ID:ID,
            VALUE:VALUE
        }
        if(VALUE.length==0)
            global.SERVER_task('operation=deleteSPR_DATA&params='+JSON.stringify(data),
            (data)=>{
               this.getSPR_DATA()
            })
        else
            global.SERVER_task('operation=createUpdateSPR_DATA&params='+JSON.stringify(data),
            (data)=>{
                this.getSPR_DATA()
            })
    }



    render (){
        const {SPR,selectedSPR} = this.state

        return  <div className="_1 Zaiavki DataBase tttb" >
                    <div class="container-fluid m-0 " >
                        <div class="row main_pages ">
                            <div className="col-lg-2 col-md-5 left">
                                {this.props.TITLE}
                                {SPR.map((spr,index)=>
                                    <p className={"SPR_TYPES"+(selectedSPR==index?" active":"")} onClick={()=>this.setState({selectedSPR:index})}>{spr.TITLE}</p>
                                )}
                            </div>

                            <div className="col-lg-10 col-md-7 right    m-0  p-0 scroll pt-3" id="scroll_msgs" ref={ref=>this.input.SCROLLER=ref}>
                                {selectedSPR!=null?[
                                    <p className="m-3 d-inline">Данные справочника ({SPR[selectedSPR].DATA.length}шт)</p>,
                                    <p className="btn  btn-light d-inline" onClick={()=>
                                        global.PopUp.Open({
                                            content:<POP_data
                                            ID_TYPE={SPR[selectedSPR].ID}
                                            callBack={()=>this.getSPR_DATA()}
                                        />})
                                    }>Добавить</p>,
                                    SPR[selectedSPR].DATA.map(data=>[
                                        <p className="SPR_DATA" onClick={()=>
                                            global.PopUp.Open({
                                                content:<POP_data
                                                ID_OBJECT={data.ID}
                                                ID_TYPE={SPR[selectedSPR].ID}
                                                callBack={()=>this.getSPR_DATA()}
                                            />})
                                        }>{data.VALUE}</p>,
                                        <hr/>
                                    ])
                                ]:<p className="m-3 d-inline">Выберите справочник для просмотра содержимого</p>}
                            

                            </div> 
                        </div>
                    </div>
                </div>
    
    }
}


