import React from 'react';
import '../css/Chat.css';
import POP_reqv_messages from "../Pages/Zaiavki/POP_reqv_messages"
import styled from 'styled-components';
import POP_ChatSettings from './POP_ChatSettings';
import {newMSG_mp3} from '../../media/audio/newMSG.mp3'


class Chat extends React.Component {
    constructor(props) {
        super()
        this.state = {
            isOpened:false,
            MSGs:[],
            haveNew:false,
            CHATS:[],
            OpenedChat:null,
            MESSAGES:[],
            USERS:[],
            haveNewMsg:false
        }
        this.input={}
        this.maxScrollTop=0
        this.lastMSG_audio=null
        this.audio=null

        
    }
    soundClick () {
        var audio = new Audio();
        audio.src = newMSG_mp3;

        return function () {
            debugger
          audio.play()
        }
    }

    getUsers(){
        global.SERVER_task('operation=getUsers',(data)=>{
            this.setState({USERS:data.result})
        })
    }

    componentDidMount(){
        this.getChats()
        this.getUsers()

        global.addTimer("getCHATS",()=>{
            try{
                this.getChats()
                this.getUsers()
            }catch(E){}
        },5000) 

        global.addTimer("getLastMSGs",()=>{
            try{
                if(this.state.OpenedChat!=null)
                    this.getMessages()
            }catch(E){}
        },3000)
    }
    getMessages(){
        if(this.state.OpenedChat==null || this.state.OpenedChat.ID==null){
            this.setState({MESSAGES:[]})
            return
        }
        let data={
            ID_CHAT:this.state.OpenedChat.ID
        }
        
        global.SERVER_task('operation=getChatMessages&params='+JSON.stringify(data),(res)=>{
            res.result.forEach((msg,i)=>{
                res.result[i].AUTHOR = this.state.USERS.find(us=>us.ID==msg.ID_USER)
            })
            this.setState({MESSAGES:res.result})
            setTimeout(()=>{
                try{
                this.ScrollView.scrollTop=this.ScrollView.scrollHeight
                this.maxScrollTop=this.ScrollView.scrollTop
                }catch(r){}
            },10)
           
        })
    }

  
    getChats(){
        new Promise((resolve,reject)=>{
            try{
            global.SERVER_task('operation=getChats',(data)=>{
                this.setState({CHATS:data.result})
                if(!this.state.haveNewMsg){
                    let maxIdMsg = 0
                    let id_author = null
                    data.result.forEach(ch=>{
                        if(ch.LAST_MSG_ID > maxIdMsg){
                            maxIdMsg=ch.LAST_MSG_ID
                            id_author = ch.LAST_MSG_AUTHOR_ID
                            
                        }
                    })
                
                    if(this.lastMSG_audio==null)
                        this.lastMSG_audio = maxIdMsg
                    else{
                        if(this.lastMSG_audio<maxIdMsg){
                            if(id_author!=global.Auth.ID_USER){
                            this.setState({haveNewMsg:true})
                            }
                            this.lastMSG_audio=maxIdMsg
                        }
                    }
                }
                setTimeout(()=>{resolve()})
            })
            }catch(e){
                reject()
            }
        })
        
    }
    openChat(ID_CHAT){
        let chat = this.state.CHATS.find(c=>c.ID==ID_CHAT)
        this.setState({OpenedChat:chat,MESSAGES:[]})
        if(chat!=null)
            setTimeout(()=>{
                    this.getMessages()
                    setTimeout(()=>{
                        this.ScrollView.scrollTop=this.ScrollView.scrollHeight
                        this.maxScrollTop=this.ScrollView.scrollTop
                    },500)
            },100)
    }

    sendChatMSG(){
        if(global.checkReqvireds([this.input.CHAT_TEXT])){
           
            var data={
                TEXT:this.input.CHAT_TEXT.value,
                ID_CHAT:this.state.OpenedChat.ID
            }
            this.input.CHAT_TEXT.value = ''
            global.SERVER_task('operation=sendChatMSG&params='+JSON.stringify(data),
            (data)=>{
                this.getMessages()
                this.getChats()
                setTimeout(()=>{
                        this.ScrollView.scrollTop=this.ScrollView.scrollHeight
                        this.maxScrollTop=this.ScrollView.scrollTop
                },100)
            })
        }
    }

    renderChat(){
        const {CHATS,OpenedChat,MESSAGES} = this.state
        let previosAuthor = null
        let list = this.state.MSGs.map(JSON=>
            <div key={"ch"+JSON.ID} className="chat" onClick={
                ()=>
                global.PopUp.Open({
                    content:<POP_reqv_messages
                    ID_REQVEST={JSON.ID_REQVEST}
                    collBack={()=>this.getLastMSGs()}
                />})
            }>
                <p>По заявке №{JSON.ID_REQVEST}</p>
                <small>{global.makeNormalDate(JSON.DATE_TIME,"dd.mm H:M")}</small>
            </div>
            )
        
            if(OpenedChat==null)
                return(
                <ChatsView isOpen={this.state.isOpened}>
                    <div className='btn btn-sm btn-light float-right' onClick={()=>
                        global.PopUp.Open({content:<POP_ChatSettings callBack={(ID_CHAT)=>{
                            this.getChats().then(()=>{
                                this.openChat(ID_CHAT)
                            })
                        }} />})
                    }>новый</div>
                    <p id="title">Список чатов</p>
                    <hr></hr>
                    <ScrollView className="scroll">
                        {CHATS.map((chat,i)=>
                            <ChatView  style={{
                                paddingLeft:0,
                                paddingRight:0
                            }} onClick={()=>this.openChat(chat.ID)}>
                                <b>{chat.TITLE}</b><br/>
                                
                                <small className='d-block mt-0 pt-0 float-right' style={{opacity:0.5}}>{chat.LAST_MSG_DATE==null?'':global.makeNormalDate(chat.LAST_MSG_DATE,'dd.mm.yyyy')==global.makeNormalDate(new Date(),'dd.mm.yyyy')?global.makeNormalDate(chat.LAST_MSG_DATE,'H:M'):global.makeNormalDate(chat.LAST_MSG_DATE,'dd.mm.yyyy')}</small>
                                <small className='d-block mt-0 pt-0'>{chat.LAST_MSG==null?'Новый чат':chat.LAST_MSG}</small>
                                {i!=CHATS.length-1?<hr className='mb-0'/>:null}
                            </ChatView>
                        )}
                    </ScrollView>
                </ChatsView>
                )
            else return(
                <ChatsView isOpen={this.state.isOpened}>
                    <div className='btn btn-sm btn-light float-right' onClick={()=>
                       this.openChat(null)
                    }>Назад к чатам</div>
                    <p id="title"> <Settings onClick={()=>
                        global.PopUp.Open({content:<POP_ChatSettings ID_OBJECT={OpenedChat.ID} callBack={(ID_CHAT)=>{
                            this.openChat(ID_CHAT)
                            this.getChats()
                        }} />})
                    } src="/server/img/edit.svg"/><b>{OpenedChat.TITLE}</b></p>
                    <hr></hr>
                    <ScrollView ref={ref=>this.ScrollView=ref} className="scroll">
                        {MESSAGES.map((msg,i)=>{
                            
                            let text=msg.TEXT.split(`\n`)
                            console.log(text)
                            let result_text = []
                            text.forEach((t,i)=>{
                                result_text.push(t)
                                if(i>0)
                                result_text.push(<br/>)
                            })
                            
                            let isIam = msg.ID_USER == global.Auth.ID_USER
                            let result = [
                            msg.AUTHOR.ID!=previosAuthor? 
                            <p className='mt-3' style={{fontSize:13}}><b>{msg.AUTHOR.FIO} {!isIam?null:'(я)'}</b><br/><small className='mt-0 d-block'>{msg.AUTHOR.ROLE_TITLE} </small></p>
                            :null,
                           <Msg isIam={isIam}>
                               <p>{result_text}</p>
                               <small>{global.makeNormalDate(msg.DATE_CREATE,'dd.mm.yyyy H:M')}</small>
                           </Msg>
                        ]
                        previosAuthor=msg.AUTHOR.ID
                        return result
                        })}
                    </ScrollView>
                    <WhiteBlock/>
                    <SendMSG> 
                        <textarea className='form-control' ref={ref=>this.input.CHAT_TEXT=ref} onKeyUp={(e)=>{

                            if(e.keyCode==13){
                                this.input.CHAT_TEXT.value=this.input.CHAT_TEXT.value.substr(0,this.input.CHAT_TEXT.value.length-1)
                                this.sendChatMSG()
                            }
                        }} placeholder='Ваше сообщение...'/>
                        <div className='btn btn-sm btn-primary ' onClick={()=>this.sendChatMSG()}>отправить</div> 
                    </SendMSG>
                </ChatsView>
                )
        
    }

    render (){
        const {isOpened,haveNewMsg} = this.state
        return [ 
            this.renderChat(),
                <ChatBut isOpen={this.state.isOpened} className="Chat w-100 container-fluid" onClick={()=>{
                    this.AudioView.play()
                    this.setState({isOpened:!this.state.isOpened,haveNewMsg:false})
                    
                }} >
                    
                    <div className="row">
                        <div className="col-3 p-0">
                           
                            <img src="/server/img/message.svg"></img>
                        </div>
                        <div className="col-8 p-0">
                           
                            <h4>Чат активен</h4>
                            <p>{isOpened?"Закрыть диалоги":"Открыть диалоги"}</p>
                        </div>
                        {!haveNewMsg?null:<NewMsg/>}
                    </div>

                    <audio ref={ref=>this.AudioView=ref} >
                    <source src={newMSG_mp3}></source>
                    </audio>
                </ChatBut>
        ]
    
    }
}

const NewMsg = styled.div`
background: rgb(235, 133, 133);
height: 15px;
width: 15px;
border-radius: 200px;
position: absolute;
right: 20px;
top: calc(50% - 7px);
`

const SendMSG = styled.div`
position: absolute;
bottom: 70px;
width: 320px;
z-index:1000;
margin-left:-5px;
& textarea{
    margin-bottom:5px;
    display:block;
   
}
& .btn{
    display:block;
    width: 90px;
    
}

`

const WhiteBlock = styled.div`
position: absolute;
bottom: 0px;
height: 200px;
width: 340px;
z-index: 1000;
margin-left: -5px;
background: white;

`
const Settings = styled.img`
width: 15px;
height: 15px;
margin-top: -6px;
margin-right: 5px;
cursor: pointer;

`

const Msg = styled.div`
padding: 10px;
border-radius: 5px;
${props=>!props.isIam?`
background: rgb(235, 239, 242);
`:`
background:rgb(183, 214, 237);
`}

margin-right: 10px;
margin-bottom:5px;
& p{ 
    margin:0;
    font-size:14px;
}
& small{
    margin:0;
    margin-top:0;
    display:block;
    font-size:10px;
}
`

const ChatView = styled.div`
cursor:pointer;
transition:  .3s;
padding:10px;
&:hover{
    ${props=>props.isHover?'background:rgb(208, 218, 230);':''}
    
}
& .deleter{
    opacity:0;
    transition:  .3s;
}
&:hover .deleter{
    opacity:1;
}
`

const ScrollView = styled.div`
width: 330px;
height: 100%;
overflow:scroll;

margin-top: -16px;
padding-top: 16px;
padding-bottom:300px;
`

const ChatBut = styled.div`
${props=>props.isOpen?`
width: 320px !important;
margin-left: -70px !important;
`:`
width: 250px;
`}
margin-left: 0px;
transition:.5s ease;


`

const ChatsView = styled.div`

height:calc(100vh - 62px);
transition:.5s ease;
padding:20px;
width:365px;
background:white;
border-bottom-left-radius: 5px;
box-shadow:0px 0px 30px rgba(0,0,0,0.05);
position:fixed;

top:62px;
right:${props=>props.isOpen?'-15px':'-365px'};
`

export default Chat;
