import React from 'react';
import '../../css/POP.css';
import '../../css/POP_reqv_messages.css';
import $ from 'jquery'


export default class POP_reqv_messages extends React.Component {
    constructor(props) {
        super()
        this.state = {
            MSGs:[]
        }
        this.input=[]
        this.needScrollDown=true
        this.itFerstScroll=true

        this.previosHeight=0
        this.ScrolFixHeight=0
        this.userScrollHeight=0


    }
    componentDidMount(){
        this.getMSGs()
        global.addTimer("getMSGs",()=>{
            try{
                this.getMSGs()
            }catch{}
            
        },10000)
        setTimeout(()=>{
            try{
                this.props.collBack()
            }catch{}
        },1000)
    }

    getMSGs(){
        var data={
            ID_REQVEST:this.props.ID_REQVEST
        };
        
        global.SERVER_task('operation=getMSGs&params='+JSON.stringify(data),
            (data)=>{
                
                    data.result.map(msg=>{
                        if(msg.ID_AUTHOR==global.Auth.ID_USER)
                            msg.DIRECTION=true
                        else 
                            msg.DIRECTION=false
                        msg.TEXT=msg.TEXT.split('\n')
                        msg.TEXT=msg.TEXT.map(txt=><p>{txt}</p>)
                    })
                
                    this.setState({MSGs:data.result})
                    setTimeout(()=>{
                            try{
                                let height=this.input.SCROLLER.scrollHeight
                            
                                if(this.needScrollDown || this.previosHeight<height && this.input.SCROLLER.scrollTop+this.ScrolFixHeight==this.previosHeight)
                                    this.scrollDown()
                                this.previosHeight=height
                            }catch(e){console.log(e)}

                            if(data.result[0]!=null){
                                console.log()
                                let MAX_ID= global.getCookie("MAX_ID_MSG_SEEING");
                                if(MAX_ID==null || MAX_ID<data.result[data.result.length-1].ID)
                                    global.setCookie("MAX_ID_MSG_SEEING",data.result[data.result.length-1].ID)
                            }
                    },10)
                
               
        })
    }

    scrollDown(){
        var height=this.input.SCROLLER.scrollHeight
        
        if(this.itFerstScroll){
            $('#scroll_msgs').animate({ scrollTop: height }, 1);
            setTimeout(()=>{
                this.ScrolFixHeight=height-this.input.SCROLLER.scrollTop
            },2)
        }else{
            $('#scroll_msgs').animate({ scrollTop: height }, 1000)
            setTimeout(()=>{
                this.ScrolFixHeight=height-this.input.SCROLLER.scrollTop
            },1002)
        }

        this.itFerstScroll=false
        this.needScrollDown=false
        
    }

    Send(){
        if(this.input.MSG.value.length==0) return

        var data={
            ID_REQVEST:this.props.ID_REQVEST,
            TEXT:encodeURIComponent(this.input.MSG.value).replace(/%0A/g,'\n')
        };
        this.input.MSG.value="";
        global.SERVER_task('operation=sendMSG_REQV&params='+JSON.stringify(data),
            (data)=>{
                this.needScrollDown=true
                this.getMSGs()
                try{
                    this.props.collBack()
                }catch{}
            })
            
    }

    FileUpload(){
            if(this.input.file.files.length>0){
                var form_data = new FormData();
                for(var i=0;i<this.input.file.files.length;i++){
                
                    form_data.append('file'+i, this.input.file.files[i]);
                    
                }
                
                global.UploadFlieToServer_DEFOULT(form_data,334,(data)=>{
                    
                    try{
                        data=JSON.parse(data)
                    }catch{}
                    if(data.result[0]!=null){
                        var data={
                            ID_REQVEST:this.props.ID_REQVEST,
                            TEXT:encodeURIComponent(this.input.MSG.value),
                            ID_FILE:data.result[0].ID
                        };
                        this.input.MSG.value="";
                        global.SERVER_task('operation=sendMSG_REQV&params='+JSON.stringify(data),
                            (data)=>{
                                this.needScrollDown=true
                                this.getMSGs()

                                try{
                                    this.props.collBack()
                                }catch{}
                            })
                    }
                });   
                this.input.file.value=null;
            

            }
    }
    ImgError(obj){
        obj.target.src="/server/img/files/simple.svg";
    }

    renderMSGs(){
        var lastAuthor=0
        return this.state.MSGs.map(JSON=>{
            let result=[]
            if(lastAuthor!=JSON.ID_AUTHOR){
                lastAuthor=JSON.ID_AUTHOR;
                if(JSON.ID_AUTHOR!=global.Auth.ID_USER)
                    result.push(<p key={"Amsg"+JSON.ID}>{JSON.ROLE_TITLE} {JSON.AUTHOR_FIO}</p>)
            }
            let width=null
            if(JSON.TEXT.length<50)
                width="max-content"

            
            

            result.push(
                <div key={"msg"+JSON.ID} className={"msg "+(JSON.DIRECTION?"to":"from")} style={{width:width}}>
                    <p>{JSON.TEXT}</p>
                    <small>{global.makeNormalDate(JSON.DATE_TIME,"dd.mm H:M")}</small>
                </div>
            )
            if(JSON.FILE_EXT!=null)
            result.push(
                <a className={"addedFile "+(JSON.DIRECTION?"to":"from")}
                href={"/server/files/"+JSON.FILE_URL+"."+JSON.FILE_EXT}
                download={JSON.FILE_TITLE+'.'+JSON.FILE_EXT}
                target="blank" >
                    Скачать файл
                    <img src={"/server/img/files/"+JSON.FILE_EXT+".svg"} onError={(e)=>this.ImgError(e)}/>
                </a>
            )
            return result
        })
    }

    setUserScrolValue(){
       
        this.userScrollHeight=this.input.SCROLLER.scrollTop
    }

    render (){

        
      
        return(
        <div className="POP POP_reqv POP_reqv_messages content-fluid " >
            <div className="row ">
                <div className="col-12">
                    <p>Сообщения по заявке №{this.props.ID_REQV_VIS}</p>
                </div>
            </div>
            <div className="row ">

                <div className="col-12">
                   <div className="scroll" onScroll={()=>this.setUserScrolValue()} id="scroll_msgs" ref={ref=>this.input.SCROLLER=ref}>
                       
                       {this.renderMSGs()}

                   </div>
                </div>

            </div>

            <hr></hr>
            <div className="row ">
            
                <div className="col-10">
                   
                    <textarea ref={ref=>this.input.MSG=ref} placeholder="Сообщение" className="form-control border-0"/>
                </div>
                <div className="col-2 buts">
                    <img src="./server/img/send.svg" onClick={()=>this.Send()}/>
                    <label for="pct"><img src="./server/img/add.svg"/></label>
                    <input type="file" id="pct"   ref={ref => this.input.file = ref} onChange={()=>this.FileUpload()} hidden class=" mb-2 w-100" />
                </div>
            </div>
        </div>)
    
    }
}

