import React from 'react';
import '../../css/POP.css';
import '../../css/POP_Users.css';
import POP_Qestion from '../../Components/POP_Qestion'
import POP_ChangeUser from './POP_ChangeUser'
import POP_CreateUser from './POP_CreateUser'
import styled from 'styled-components';


class POP_Users extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            Users:[],
            selectedUser:-1,
            serchUser:"",
            avaStyle:{ },
            Files:[],
            Folders:[],
            OpenedFolders:[],
            PromissedFiles:null,
            PromissedFolders:[],
            serchFile:"",
            serchFolder:"",
            Roles:[]
        }
        this.SaveTimer=null;
    }
    componentDidMount(){
        this.getUsers()
        this.getFolders()
        this.getRoles()
        
    }

   
    getPromissedFolders(ID){
        
        global.SERVER_task('operation=getUsersFolderPermisions&ID_USER='+ID,
        (data)=>{
            data.result=data.result.map(e=>e.ID_FOLDER)
            this.setState({PromissedFolders:data.result})
            
        })
    }

    getUsers(ID){
        try{
        if(ID!=null){
            setTimeout(()=>this.selectUser(ID),500);
        }else this.setState({selectedUser:-1});

        
        global.SERVER_task('operation=getUsers',
        (data)=>{
            
            this.setState({Users:data.result});   
           
            
            
                
        })
    }catch{}
    }

    getRoles(){
    
            global.SERVER_task('operation=getRoles',
            (data)=>{
            
                this.setState({Roles:data.result});   

            })
    }
    
    getFolders(){
        global.SERVER_task('operation=getAllFolders',
        (data)=>{

            this.setState({Folders:data.result});   
        })
        
    }

    selectUser(ID){
        try{
        var user=null;
        for(var i=0;i<this.state.Users.length;i++){
            if(this.state.Users[i].ID==ID){
                user=this.state.Users[i];
                break;
            }
        }
        
       
        this.getPromissedFolders(ID);
        

        

        global.checkFile(
            global.serverURL_Main+"img/accounts/img_"+user.ID+".jpg",
            ()=>{
                this.setState({avaStyle:{
                    backgroundImage:"url(" + global.serverURL_Main+"img/accounts/img_"+user.ID+".jpg?"+global.getRandomInt(10000) + ")",
                }})
            },
            ()=>{
                this.setState({avaStyle:{
                    backgroundImage:"url(/server/img/accounts/img.jpg)",
                }})
            }
        )
        this.setState({selectedUser:user});

        }catch{}
    }

    Save(){
        console.log("save");
        clearTimeout(this.SaveTimer);
        this.SaveTimer=null;
        var data={
            ID_USER:this.state.selectedUser.ID,
            P_Files:this.state.PromissedFiles,
            P_Folders:this.state.PromissedFolders
        };
        global.SERVER_task('operation=UserSetPremissions&params='+JSON.stringify(data),
        (data)=>{
           
        })
    }

    SetUserRole(JSON_ROLE){
        if(this.state.selectedUser.ROLE!=JSON_ROLE.ID)
            global.PopUp.Open({content:<POP_Qestion text={"Изменить роль пользователя на "+JSON_ROLE.TITLE+"?"} buttons={[
                ["Да, изменить",'primary',()=>this.SetUserRole_complite(JSON_ROLE.ID)],
                ["Отмена",'']
                ]}/>
            })
    }

    SetUserRole_complite(ID_ROLE){
        var data={
            ID_USER:this.state.selectedUser.ID,
            ID_ROLE:ID_ROLE
        };
        global.SERVER_task('operation=updateUserRole&params='+JSON.stringify(data),
        (data)=>{
           this.getUsers(this.state.selectedUser.ID);
        })
    }

    ReqvestPermissionsFolder(ID_FOLDER){
        var data={
            ID_FOLDER,
            ID_USER:this.state.selectedUser.ID
        }
        
        global.SERVER_task('operation=ReqvestPermissionsFolder&params='+JSON.stringify(data),
        (data)=>{
           this.getPromissedFolders(this.state.selectedUser.ID)
        })
    }

    renderFileSustem(parnetFolder = null,zIndex=0,isNotAlowedGlobal=false){
        const {OpenedFolders,PromissedFolders,serchFolder} = this.state
        const Folders = parnetFolder==null? this.state.Folders : parnetFolder.Childes
        let renderFolders = []

        Folders.map(folder=>{
            let canDisplaySelf = true
            if(serchFolder.length>0) if(folder.TITLE.toUpperCase().indexOf(serchFolder.toUpperCase())==-1) 
                canDisplaySelf=false

            let index_in_opened_folders = OpenedFolders.findIndex(id_folder=>id_folder==folder.ID)
            
            let index_in_permissions = PromissedFolders.findIndex(id_folder=>id_folder===folder.ID)

            let isAllowed = !isNotAlowedGlobal
            
            if(index_in_permissions==-1 && folder.IS_PRIVATE==1 || index_in_permissions!=-1 && folder.IS_PRIVATE==0) 
                isAllowed = false
            

            renderFolders.push(
                <FileSustem.View canDisplaySelf={canDisplaySelf} zIndex={zIndex} onClick={()=>{
                    let OpenedFolders_tmp= OpenedFolders
                
                    if(index_in_opened_folders==-1)
                        OpenedFolders_tmp.push(folder.ID)
                    else
                        OpenedFolders_tmp.splice(index_in_opened_folders,1)

                    this.setState({OpenedFolders:OpenedFolders_tmp})
                }}>
                    <FileSustem.Icon
                        onClick={(e)=>{
                            e.stopPropagation()
                            this.ReqvestPermissionsFolder(folder.ID)
                        }}
                        src={isAllowed?"/server/img/files/folder.svg":"/server/img/files/folderGray.svg"}/>
                    <FileSustem.Title isOpen={index_in_opened_folders!=-1}>{folder.TITLE} 
                    <j style={{fontSize:10,color:"rgb(140,140,140)"}}>{folder.IS_PRIVATE==1?" private":""}</j></FileSustem.Title>
                </FileSustem.View>
            )
            if(index_in_opened_folders!=-1)
                renderFolders=renderFolders.concat(this.renderFileSustem(folder,zIndex+1,!isAllowed))
        })
        if(Folders.length==0)
            renderFolders.push(
                <FileSustem.View zIndex={zIndex}>
                   
                    <FileSustem.Title style={{color:"rgb(200,200,200)"}} isOpen={false}>Внутри нет каталогов </FileSustem.Title>
                </FileSustem.View>
            )

        return renderFolders
    }

    render (){
        try{
        var list_Roles = this.state.Roles.map((JSON)=>
            <a class="dropdown-item" onClick={()=>this.SetUserRole(JSON)}>{JSON.TITLE}</a>
        )

        if(global.Auth.ROLE>2)
            list_Roles=[]

        var list_users = this.state.Users.map((JSON)=>{
            if(this.state.serchUser.length!=0)
                if(JSON.FIO.toUpperCase().indexOf(this.state.serchUser.toUpperCase())<0)
                    return null;

            
            var class_=JSON.ID==this.state.selectedUser.ID?"selected":"";

            
        if(JSON.STATUS==2)  class_+=" blocked";
            switch(JSON.ROLE){
                case 1:case 2:
                    return <h6 onClick={()=>this.selectUser(JSON.ID)} className={class_}>{global.smollFIO(JSON.FIO)}<small> админ</small></h6> 
                case 4:
                    return <h6 onClick={()=>this.selectUser(JSON.ID)} className={class_}>{global.smollFIO(JSON.FIO)}<small> заказчик</small></h6>
                case 5:
                    return <h6 onClick={()=>this.selectUser(JSON.ID)} className={class_}>{global.smollFIO(JSON.FIO)}<small> начальник</small></h6> 
                case 6:
                    return <h6 onClick={()=>this.selectUser(JSON.ID)} className={class_}>{global.smollFIO(JSON.FIO)}<small> директор</small></h6> 
                default: return <h6 onClick={()=>this.selectUser(JSON.ID)} className={class_}>{global.smollFIO(JSON.FIO)}</h6> 
            }
            
        })

        if(this.state.selectedUser==-1)
            var content=<p className="ml-5 mr-5 text-center text-muted">Выберите пользователя из списка для настроки прав доступа к разделам и файлам</p>
        else{


            var content=<div >
                            <div className="row" id="topS">
                               
                                <div className="col-12 pt-0 pr-0">
                                    {global.Auth.ROLE<=2?
                                    <div className="row">
                                        <div className="col-8 p-0">
                                            {
                                                this.state.selectedUser.STATUS!=2?
                                                    <div class="dropdown mt-0 mb-3 mr-2">
                                                        <button class=" overflow-hidden btn btn-light btn-sm dropdown-toggle w-100 " type="button"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            {this.state.selectedUser.ROLE_TITLE}
                                                        </button>
                                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            {list_Roles}
                                                        </div>
                                                    </div>
                                                :
                                                <div class="dropdown mt-0 mb-3 mr-2 ">
                                                    <button class=" overflow-hidden btn btn-secondary btn-sm text-white  w-100" type="button"  >Заблокирован</button>
                                                </div>
                                            }
                                            
                                        </div>
                                        <div className="col-4  p-0">
                                                <button class="overflow-hidden btn btn-primary btn-sm  w-100 " type="button" 
                                                onClick={()=>
                                                    global.PopUp.Open({content:<POP_ChangeUser ID_OBJECT={this.state.selectedUser.ID} callBack={(ID_USER)=>{
                                                        this.getPromissedFolders(ID_USER)
                                                        this.getUsers(ID_USER)
                                                    }} />})
                                                }>
                                                    Изменить
                                                </button>
                                        </div>
                                    </div>
                                    :
                                        <div className="row">
                                            <div className="col-12 p-0">
                                                <button class=" overflow-hidden btn btn-light btn-sm dropdown-toggle w-100 " type="button"  >
                                                    {this.state.selectedUser.ROLE_TITLE}
                                                </button>
                                            </div>
                                        </div>
                                    }
                                    
                                    <p className="p-0 m-0"><b className="infa">ФИО:</b> {this.state.selectedUser.FIO}</p>
                                   
                                   
                                    <p className="p-0 m-0"><b className="infa">Логин:</b> {this.state.selectedUser.LOGIN}</p>
                                    
                                   
                                    <p className="p-0 m-0"><b className="infa">E-mail:</b> {this.state.selectedUser.EMAIL}</p>
                                    <p className="p-0 m-0"><b className="infa">Телефон:</b> {this.state.selectedUser.PHONE}</p>

                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-12 pl-0 pr-0 ">
                                    <small className="pb-0 mb-0">Доступ к файловой системе</small>
                                    <input onKeyUp={(e)=>this.setState({serchFolder:e.target.value})} type="text" class="form-control mt-0 mb-0" placeholder="Поиск..." />
                                    <div id="files" className="verticalScroll p-1">
                                        {this.state.selectedUser.ROLE >2 ?
                                            this.renderFileSustem()
                                            :
                                            <p>Администратору доступны все разделы</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
        }
    }catch{}

        return <div className="POP POP_Users content-fluid">
                    
                        <div className="row">
                            <div className="col-4" id="users">
                            <input autoFocus type="text"  onKeyUp={(e)=>this.setState({serchUser:e.target.value})} class="form-control mt-3 mb-0" placeholder="Поиск..." />
                                <div className="verticalScroll p-1">
                                    <div className="scrollContent">
                                        {list_users}
                                    </div>
                                </div>
                                <button type="button" 
                                    onClick={()=>
                                        global.PopUp.Open({content:<POP_CreateUser callBack={(ID)=>this.getUsers(ID)} />})
                                    } class="w-100 btn btn-primary btn-sm mb-3">Создать пользователя</button>
                            
                            </div>
                            <div className="col-8 p-3" id="settings">
                                {content}    
                            </div>
                        </div>
                    
                </div>
    
    }
}

export default POP_Users;

const FileSustem={
    View: styled.div`
    opacity:${props=>props.canDisplaySelf?1:0.2};
    padding-left:${props=>props.zIndex*10}px;
    border-bottom: 1px solid rgb(249, 249, 249);
    height: 26px;
    cursor:pointer;
    &:hover img{
        margin-left:-3px;
    }
    `,
    Icon: styled.img`
    width:20px;
    height:20px;
    transition: .3s;
    `,
    Title: styled.p`
    ${props=>props.isOpen?`font-weight:bold;`:`font-weight:normal;`}
    display: inline-block;
    margin-left: 10px;
    font-size: 13px;
    `
}
