import React from 'react';
import '../css/POP.css';


export default class POP_Select extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selected:this.props.SELECTED!=null?this.props.SELECTED:[],
            Serch:null
        }

              
    }
    
    Select(ID){
       
        if(this.props.IS_SINGLE){
            this.setState({selected:[ID]});
        }else{

            if(this.props.MEGA_SELECTED!=null)
                for(var i=0;i<this.props.MEGA_SELECTED.length;i++){
    
                    if(this.props.MEGA_SELECTED[i]==ID){
                      
                       return null;
                      
                    }
                }
         
            var selected = this.state.selected;
            var itHave=false;
           
            for(var i=0;i<selected.length;i++){
                if(selected[i]==ID){
                    selected.splice(i,1);
                    itHave=true;
                    break;
                }
            }
            if(!itHave) 
                selected.push(ID);
            this.setState({selected:selected});
        }
    }
    Return(){
        this.props.callBack( this.state.selected);
        global.PopUp.CloseFunction();
    }

    Serch(val){
        if(val=="" || val.length==0)
            val=null;
        this.setState({Serch:val});
       
    }

    render (){
        var list=this.props.ELEMENTS.map((JSON)=>{
            

                if(this.state.Serch!=null)
                    if(JSON.TITLE.toUpperCase().indexOf(this.state.Serch.toUpperCase())==-1)
                        return null;

                if(this.props.FILTER!=null){
                for(var i=0;i<this.props.FILTER.length;i++)
                    if(this.props.FILTER.ID==JSON.ID)
                        return null;
                }

                var isSelected=false;
                var isMega=false;
                if(this.props.MEGA_SELECTED!=null){
                    for(var i=0;i<this.props.MEGA_SELECTED.length;i++)
                        if(this.props.MEGA_SELECTED[i]==JSON.ID){
                            isSelected=true;
                            isMega=true;
                            break;
                        }
                    }

                if(!isSelected)
                    for(var i=0;i<this.state.selected.length;i++)
                        if(this.state.selected[i]==JSON.ID){
                            isSelected=true;
                            break;
                        }

                return <p style={
                    {
                        fontSize:14
                    }
                } className={(isSelected?"bg-primary text-white rounded":"")+" p-2"} onClick={()=>this.Select(JSON.ID)}>{JSON.TITLE} 
                    {isMega?<small> {this.props.MEGA_SELECTED_POSTFIX}</small>:""}
                    </p>
            
        })
        
        return <div className="POP POP_Trener content-fluid">
                    <br></br>
                    <input autoFocus type="text"  onKeyUp={(e)=>this.Serch(e.target.value)} class="form-control mb-2" placeholder="Поиск..." />
                    
                    <div className="verticalScroll" style={
                        {
                            maxHeight:"70vh"
                        }
                    }>
                        {list}
                    </div>
                    <small >Выбрано {this.state.selected.length+this.props.MEGA_SELECTED.length}</small>
                    <button type="button" onClick={()=>this.Return()} class={"w-100 btn btn-primary"}>Выбрать</button>
                    
                </div>
    
    }
}

