import React from 'react';
import '../../css/AddFolder.css';
import POP_Folder from './POP_Folder'


class AddFolder extends React.Component {
    constructor(props) {
        super()
        this.state = {
            
        }
 

      
    }

    CreateFolder(){
        global.PopUp.Open({content:<POP_Folder callBack={()=>this.props.callBack()} DIST={this.props.DIST}/>})
    }
    
   
    render (){
        return  <div onClick={()=>this.CreateFolder()} className={"AddFolder "+this.props.class}>
                   <h1>+</h1>
                    <p>Создать папку</p>
                </div>
    
    }
}

export default AddFolder;
