import React from 'react';

import './css/App.css';
import './css/Modal.css'
import Drawer from 'react-drag-drawer/umd/react-drag-drawer'
import 'react-bootstrap';
import Header from './Components/Header'
import Footer from './Components/Footer'

import _1 from './Pages/_1/_1'
import Zaiavki from './Pages/Zaiavki/Zaiavki'
import Zaiavki_zaka from './Pages/Zaiavki/Zaiavki_zaka'
import {BrowserRouter as Router,Switch, Route,Link} from "react-router-dom";


import Login from './Pages/Login/Login'
import Account from './Pages/Accaunt/Accaunt'
import Contacts from './Pages/Contacts/Contacts'
import 'popper.js/dist/popper'
import 'bootstrap/dist/js/bootstrap.min.js';
import Otchot from './Pages/Otchot'

import PageTitle from './Components/PageTitle'
import 'bootstrap/dist/css/bootstrap.min.css';

import WebFont from 'webfontloader';
import DataBase from './Pages/DataBase';
import FilePreview from './Pages/FilePreview';
import POP_reqv_join from './Pages/Zaiavki/POP_reqv_join';
import POP_reqv_item from './Pages/Zaiavki/POP_reqv_item';
import styled from 'styled-components';
import Support from "./Pages/Support"


//import {Helmet} from "react-helmet";


class App extends React.Component {
  constructor(props) {
    super();
    //setTimeout(()=>this.setState({CURRENT_PAGE:10}),400)
    this.state={
      CURRENT_PAGE:global.CURRENT_PAGE,
      isLogined:0, // 0-загрузка 1-ок 2-не ок 3-нужно сменить пароль
      popups:[],
      notAllowedDomain:false
    }
    this.TMP_CURRENT_PAGE=null;
  
   
    global.API_task(global.API.DOMAIN_CHECK,{
      from:window.location.hostname
    },(data)=>{
      
      if(data.result=="not ok"){
        this.setState({notAllowedDomain:true});
      }
    })

    global.PopUp.CloseFunction=(key)=>this.toggle_popup(key);
    global.PopUp.Open=(options)=>this.openPopup(options);
    
    global.SERVER_task('operation=checkLogined',
    (data)=>{
     
      if(data.result=="ok login"){
        this.setState({ isLogined: 1 });
       
        global.SERVER_task('operation=getROLE',
        (data)=>{
            global.Auth.ROLE=data.result*1;  
        })

        global.SERVER_task('operation=getID',
        (data)=>{
            global.Auth.ID_USER=data.result*1;  
        })
      }
      else
        if(data.result=="new user")
          this.setState({ isLogined: 3 });
        else
          this.setState({ isLogined: 2 });
        
    })


  }

  componentDidMount(){
  
 }

 
  openPopup(options){
    var st = this.state.popups;
    options.isOpened=true;
    options.key="K"+Math.floor(Math.random() *100000000);
    st.push(options);
    
    this.setState({popups:st});

    global.PopUp.PreviosPopUp_key.push(global.PopUp.ActualPopUp_key);
    global.PopUp.ActualPopUp_key=options.key;
  }
  
  toggle_popup(key){
    

    if(key==undefined || key==null)
      key=global.PopUp.ActualPopUp_key;
    if(key==undefined || key==null)
      return;
    
    var tmp_pop=this.state.popups;
    tmp_pop.forEach(element => {
      if(element.key==key){
        element.isOpened=false;
      }
    });
    this.setState({popups:tmp_pop});

    setTimeout(()=>{
      let tmp_pop=this.state.popups
      let index = tmp_pop.findIndex(element => element.key==key)
      tmp_pop.splice(index,1)
      this.setState({popups:tmp_pop})
    },300)

    if(global.PopUp.PreviosPopUp_key.length>0){
      global.PopUp.ActualPopUp_key=global.PopUp.PreviosPopUp_key[global.PopUp.PreviosPopUp_key.length-1];
      global.PopUp.PreviosPopUp_key.pop();
    }else
      global.PopUp.ActualPopUp_key=null;
    
  }
  openPage(CURRENT_PAGE){
    this.setState({CURRENT_PAGE:CURRENT_PAGE});
    global.CURRENT_PAGE=CURRENT_PAGE;
  }

  getPage(){
   
    if(this.state.isLogined==0)
      return <p>Загрузка</p>;
    else
      if(this.state.isLogined==1)
        switch(this.state.CURRENT_PAGE){
          case "LOGIN":
            return <Login/>
          case 1: return <_1 DIST="1" TITLE={ <PageTitle 
                            img='/server/img/list.svg'
                            title={<p>Подразделения<br></br>организаций</p>}
                          />}/>
                          
         
                          
          case 4: return <_1 DIST="2" TITLE={ <PageTitle 
                          img='/server/img/work.svg'
                          title={<p>Руководитель<br></br>подразделений</p>}
                        />}/>

          case 6: return <Otchot DIST="2" TITLE={ <PageTitle 
                          img='/server/img/statistics.svg'
                          title={<p>Раздел<br></br>отчеты</p>}
                        />}/>


          case 7: return <Support/>
                        
          case 9: return <_1 DIST="3" TITLE={ <PageTitle 
                          img='/server/img/list.svg'
                          title={<p>Файловый<br></br>менеджер</p>}
                        />}/>
          case 10: return global.Auth.ROLE!=4?<Zaiavki TITLE={ <PageTitle 
                          img='/server/img/tasks.svg'
                          title={<p>Журнал<br></br>заявок</p>}
                        />}/> 
                        :
                        <Zaiavki_zaka TITLE={ <PageTitle 
                          img='/server/img/tasks.svg'
                          title={<p>Мои<br></br>Заявки</p>}
                        />}/> 
          case 11: return <DataBase TITLE={ <PageTitle 
                          img='/server/img/server.svg'
                          title={<p>Раздел<br></br>справочник</p>}
                        />}/>
                        
          case "ACCOUNT": return <Account AppState={this.state}/>
          case "CONTACTS": return <Contacts AppState={this.state}/>
              
          default:
            return <div id="MAIN_title">
            <h1>Серверный файловый менеджер</h1>
            <p>Разработано ITTS</p>

          </div>
        }
      else if(this.state.isLogined==3)
        return <Account AppState={this.state}/>
      else
        return <Login/>

  }
 
render() {

  var list_popups = this.state.popups.map((obj) =>
    <Drawer key={obj.key} open={obj.isOpened} >
      <div className="card" >
        <p
          style={{position: 'absolute',right: 0,zIndex:1000}}
          className="btn btn-sm  float-right"
          onClick={()=>this.toggle_popup(obj.key)}>x</p>
        <div className="card-body mt-1">
          {obj.content}
        </div>
    </div>
      
    </Drawer>
  );
  


      
  var page=this.getPage();

  if(!this.state.notAllowedDomain)
    return (
      <Router key="mainRouter" >
        <Switch>

          <Route path="/file_preview/:FILE_URL/:FILE_TITLE/:FILE_EXT">
            <FilePreview />
          </Route>

          <Route path="/">
            <div className="App">
              {this.state.popups.length>0?<BlurBG/>:null}
              {list_popups}
              {this.state.isLogined==1?
                <Header openPage={(CURRENT_PAGE)=>this.openPage(CURRENT_PAGE)}/>
                :
                null
              }

              {page}
              {this.state.isLogined==1?
                <Footer openPage={(CURRENT_PAGE)=>this.openPage(CURRENT_PAGE)}/>
                :
                null
              }
            </div>
          </Route>
 
        </Switch>
      </Router>
      
    );
  else
  return (
    <div className="App">
      <meta charSet="utf-8" />
      <title>ITTS</title>
      <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no"></meta>
  </div>
  );
}

}

WebFont.load({
  google: {
    families: ['Roboto:100,300,400,600,700,900', 'sans-serif']
  }
});

export default App;


const BlurBG = styled.div`
width:100vw;
height:100vh;
backdrop-filter:blur(10px);
position:fixed;
top:0;
left:0;
z-index:20;
`