import React from 'react';
import '../../css/POP.css';
import POP_Qestion from '../../Components/POP_Qestion'


class POP_CreateUser extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ROLES:[],
            selectedROLE:{
                ID:null,
                TITLE:"Не назначена"
            }
        }
        this.input=[];
       
        this.canSave=true

    }
    
    componentDidMount(){
        this.getRoles();
    }

    getRoles(){
        global.SERVER_task('operation=getRoles',
        (data)=>{
           
            if(global.Auth.ROLE==6)
                this.setState({ROLES:data.result.filter(role=>role.ID>2 && role.ID!=6)});   
            else
                this.setState({ROLES:data.result}); 
        })
    }

    selectRole(JSON){
        this.setState({selectedROLE:JSON});
    }
    Save(){
        if(!this.canSave) return

        if(global.checkReqvireds(this.input)){
           if(this.state.selectedROLE.ID==null){
            global.PopUp.Open(
                {content:<POP_Qestion  text="Выберите роль нового пользователя"/>}
            )
            this.canSave = true
            return;
           }

            var data={
                FIO:this.input.FIO.value,
                EMAIL:this.input.EMAIL.value,
                ROLE:this.state.selectedROLE.ID
            };
            this.canSave = false
            global.SERVER_task('operation=CreateUser&params='+JSON.stringify(data),
            (data)=>{
                this.canSave = true
                if(data.result.length!=0){
                    global.API_task(global.API.SEND_MAIL,{
                        EMAIL:this.input.EMAIL.value,
                        FROM:"Веско",
                        SHABLON:"Registration",
                        TEXT:{
                            ":FIO":this.input.FIO.value,
                            ":PASSWORD":data.result[0].PASSWORD
                        }
                    },(result)=>{
                        if(result=="ok"){
                            try{
                                this.props.callBack(data.result[0].ID);
                            }catch{}
                            global.PopUp.CloseFunction();
                        }else{
                            global.PopUp.Open(
                                {content:<POP_Qestion title="Ошибка" text="Пользователь был создан в базе но письмо ему небыло отправленно" footer={"Логин пользователя:INIT Пароль: "+data.result[0].PASSWORD}/>}
                              )
                        }
                    })
                    
                }
            })

        }
    }

    render (){
        var list_ROLES = this.state.ROLES.map((JSON)=>
            <a class={"dropdown-item "+(this.state.selectedROLE.ID==JSON.ID?" bg-primary":"")} onClick={()=>this.selectRole(JSON)}>{JSON.TITLE}</a>
        )
        
        return <div className="POP POP_CreateUser content-fluid">
                        <p className="mb-0">ФИО</p>
                        <input autoFocus type="text" ref={ref => this.input.FIO = ref} class="form-control" placeholder="Иванов Иван Иванович" />

                        <p className="mb-0 mt-1">E-mail</p>
                        <input  type="email" ref={ref => this.input.EMAIL = ref} class="form-control" placeholder="samemail@mail.ru" />

                        <p className="mb-0 mt-1">Роль</p>
                        <div class="dropdown w-100">
                            <button class="btn w-100 btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {this.state.selectedROLE.TITLE}
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                {list_ROLES}
                            </div>
                        </div>

                        <div className="alert alert-primary mt-3 mb-3">
                            На указанную почту придет письмо, в котором будут содержаться данные для входа нового пользователя в систему
                        </div>
                    
                    <div className="row">
                       <div key={global.getRandomInt(9999)} className="col">
                            <button type="button" onClick={()=>this.Save()} class="w-100 btn btn-primary">Зарегистрировать</button>
                        </div>
                        <div key={global.getRandomInt(9999)} className="col">
                            <button type="button" onClick={()=>global.PopUp.CloseFunction()} class="w-100 btn">Отмена</button>
                        </div>
                    </div>
                </div>
    
    }
}

export default POP_CreateUser;
