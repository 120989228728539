import React from 'react';
import '../../css/_1.css';
import '../../css/Zaiavki.css';
import POP_reqv from './POP_reqv'
import POP_reqv_cont from './POP_reqv_cont'
import POP_reqv_item from './POP_reqv_item';
import FiltersView from '../../Components/Filters';
import POP_reqv_join from './POP_reqv_join';


export default class Zaiavki extends React.Component {
    constructor(props) {
        super()
        this.state = {
            REQVs:[],
            REQV_ITEMS:[],
            Opened_REQVs:[],
            Filters:{}
        }
        let Filters=[
            {
                Name:"ID_STATUS",
                Title:"Статус",
                Type:"select"
            },
            {
                Name:"ID_OBJECT",
                Title:"Объект контроля",
                Type:"select"
            },
            {
                Name:"ID_METHOD",
                Title:"Метод НК",
                Type:"select"
            },
            {
                Name:"ID_VIS",
                Title:"Номер заявки",
                Type:"text"
            },
            {
                Name:"OVNER_FIO",
                Title:"Заказчик",
                Type:"text"
            },
            {
                Name:"OVNER_EMAIL",
                Title:"Заказчик e-mail",
                Type:"text"
            },
            {
                Name:"OVNER_PHONE",
                Title:"Заказчик телефон",
                Type:"text"
            }
        ]
        

        Filters.map(fil=>{
            this.state.Filters[fil.Name]={
                Title:fil.Title,
                Value:null,
                Type:fil.Type,
            }
            if(fil.Type=="select")
            this.state.Filters[fil.Name].Variants=[]
        })

      
        
        this.ref=[]
        
    }

    LoadFilters(){
        
        global.SERVER_task('operation=getREQV_STATUSES', //ID_STATUS
        (data)=>{
            this.setState({Filters:{
                ...this.state.Filters,
                ID_STATUS:{
                    ...this.state.Filters.ID_STATUS,
                    Variants:data.result.map(item=>{
                        return {
                            Value:item.ID,
                            Title:item.TITLE
                        }
                    })
                }
            }});
        })


        global.SERVER_task('operation=getOBJECTS', //ID_OBJECT
        (data)=>{
            this.setState({Filters:{
                ...this.state.Filters,
                ID_OBJECT:{
                    ...this.state.Filters.ID_OBJECT,
                    Variants:data.result.map(item=>{
                        return {
                            Value:item.ID,
                            Title:item.TITLE
                        }
                    })
                }
            }});
        })

         
        this.setState({Filters:{  //ID_METHOD
            ...this.state.Filters,
            ID_METHOD:{
                ...this.state.Filters.ID_METHOD,
                Variants: global.SPR["НК"].DATA.map(item=>{
                    return {
                        Value:item.ID,
                        Title:item.VALUE
                    }
                })
            }
        }});
    }
   

    setFilters(Filters){
        this.setState({Filters})
    }

    async componentDidMount(){
        await this.getREQV_CONTs()
        await this.getREQV_JOINS()
        //await this.getREQV_ITEMs()
        this.LoadFilters()

        global.addTimer("takeReqvests",()=>{
            if(global.CURRENT_PAGE==10){
                this.getREQV_CONTs()
                this.getREQV_JOINS()
                //this.getREQV_ITEMs();
            }
        },30000)
    }

    getREQV_CONTs(){
        return new Promise((resolve,reject)=>{
            global.SERVER_task('operation=getREQV_CONTs',
            (data)=>{
                this.setState({REQVs:data.result});
                setTimeout(()=>resolve(),1)
            })
        })
    }

    getREQV_JOINS(){
        return new Promise((resolve,reject)=>{
            global.SERVER_task('operation=getREQV_JOINS',
            (data)=>{
                
                this.setState({REQV_JOINS:data.result});
                setTimeout(()=>resolve(),1)
            })
        })
    }

    getREQV_ITEMs(){
        return new Promise((resolve,reject)=>{
            global.SERVER_task('operation=getREQV_ITEMS',
            (data)=>{
                this.setState({REQV_ITEMS:data.result});
                setTimeout(()=>resolve(),1)
            })
        })
    }



    openTR(ID){
        let arr = this.state.Opened_REQVs;
        let ind = arr.indexOf(ID);
        if(ind>=0)
            arr.splice(ind,1);
        else
            arr.push(ID);

        this.setState({Opened_REQVs:arr})
        
    }
  
   

    render (){
        const {Filters} = this.state
        let REQVs =this.state.REQVs

        
        if(REQVs.length>0){
            
            REQVs=REQVs.filter(r=>{
                let result = true
                
                
                Object.keys(Filters).map(key=>{
                    if(result){
                        let filter = Filters[key]
                        
                        if(filter.Value!=null){
                            
                            if(r[key]!=filter.Value)
                                result=false
                        }
                    }
                })
                
                return result
            })
            
        }
        
        let list_REQV = REQVs.map((JSON,i)=>{
            let ind = this.state.Opened_REQVs.indexOf(JSON.ID);
            
            let JOINS=null;
            if(ind>=0){
                
                //отрисовать JOINS
                let REQV_JOINS=this.state.REQV_JOINS;
                JOINS=REQV_JOINS.map((JOIN)=>{
                    
                    if(JOIN.ID_REQV_CONT==JSON.ID){
                        return (
                            <tr onClick={
                                ()=>
                                    global.PopUp.Open({content:<POP_reqv_join
                                    ID_OBJECT={JOIN.ID}
                                    callBack={()=>this.getREQV_JOINS()}
                                    />})
                                }>
                                <td></td>
                                <td></td>{/* № п\п */}
                                <td></td>{/* Статус */}
                                <td></td>{/* Номер заявки */}
                                <td></td>{/* Заказчик */}
                                <td></td>{/* Заказчик e-mail */}
                                <td></td>{/* Заказчик телефон */}
                                <td></td>{/* Монтажная организация */}
                                <td></td>{/* Объект контроля */}
                                <td></td>{/* Дата заявки */}
                                <td>{JOIN.DATE_CONTROL}</td>{/* Дата контроля */}
                                <td></td>{/* Метод НК */}
                                <td></td>{/* Объем контроля */}
                                <td></td>{/* Транспортируемый продукт */}
                                <td></td>{/* № Сварного журнала \ схемы */}
                                <td>{JOIN.NAME}</td>{/* Наим. узла \ детали */}
                                <td></td>{/* Наим. линии */}
                                <td>{JOIN.NAME}</td>{/* № Сварного соед по сварочному журналу */}
                                <td>{JOIN.WELDER_TYPE}</td>{/* Способ сварки */}
                                <td>{JOIN.NAME}</td>{/* № Контролируемого участка */}
                                <td></td>{/* Марка стали */}
                                <td>{JOIN.MATERIAL_WELDER_TITLE}</td>{/* Марка сварного материала */}
                                <td>{JOIN.DIAMETER}</td>{/* Контролируемый метраж объекта */}
                                <td>{JOIN.WIDTH}</td>{/* Контролируемая толщина объекта */}
                                <td>{JOIN.WELDER}</td>{/* Шифр сварщика */}
                                <td></td>{/* Условия проведения контроля */}
                                <td></td>{/* Применяемые средства контроля */}
                                <td></td>{/* Контроль в соответствии с НТД */}
                                <td></td>{/* Оценка качества по НТД */}

                                <td></td>{/* Параметры преобразователей */}
                                <td></td>{/* Схема контроля */}
                                <td></td>{/* Норма оценки качествка */}


                                <td></td>{/* Чувствительность */}
                                <td></td>{/* Установленный СПиН */}
                                <td></td>{/* По результатам контроля */}

                                <td></td>{/* Описание выявленных дефектов (кординаты) */}
                                <td></td>{/* № Заключения НК */}
                                <td></td>{/* Дата выдачи заключения НК */}
                                <td></td>{/* Сведенья о качестве проконтролированного соединения */}

                                <td></td>{/* ФИО Специалиста */}
                                <td></td>{/* ФИО Начальника */}


                            </tr>
                        )
                    }else 
                        return null
                })
            
            }

            /*
            let ITEMS=null;
            if(ind>=0){
                //отрисовать ITEMS
                let REQV_ITEMS=this.state.REQV_ITEMS;
                ITEMS=REQV_ITEMS.map((ITEM)=>{
                    if(ITEM.ID_REQV_CONT==JSON.ID){
                        return (
                            <tr onClick={
                                ()=>
                                    global.PopUp.Open({content:<POP_reqv_item
                                    ID_OBJECT={ITEM.ID}
                                    callBack={()=>this.getREQV_ITEMs()}
                                    />})
                                }>
                                <td> </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>{ITEM.CONTROL_PLACE}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>

                                <td></td>
                                <td></td>
                                <td></td>


                                <td></td>
                                <td></td>
                                <td></td>

                                <td>{ITEM.DEFECT_TEXT}</td>
                                <td></td>
                                <td></td>
                                <td><div className={"zay-st si-"+ITEM.ID_CONTROL_STATUS}>{ITEM.STATUS_TITLE!=null?ITEM.STATUS_TITLE:"Не исследован"}</div></td>

                                <td></td>
                                <td></td>


                            </tr>
                        )
                    }else 
                        return null
                })
            
            }
            */
            
            return [
                
                    <tr className={"main "+(ind>=0?"open":"")} onClick={
                        ()=>
                            global.PopUp.Open({content:<POP_reqv_cont
                            ID_OBJECT={JSON.ID}
                            callBack={()=>{
                                this.getREQV_CONTs();
                                this.getREQV_JOINS()
                                //this.getREQV_ITEMs();
                            }}
                            />})
                        }>
                        <td onClick={(e)=>{
                            e.stopPropagation();
                            this.openTR(JSON.ID);
                            }}> <img width="10" src="./server/img/down-arrow.svg"/></td>
                        <td>{(i+1)}</td>
                        <td><div className={"zay-st st-"+JSON.ID_STATUS}>{JSON.STATUS_TITLE}</div></td>
                        <td><div className={"zay-st st-1"} onClick={
                                (e)=>{
                                    e.stopPropagation();
                                    global.PopUp.Open({content:<POP_reqv
                                    ID_OBJECT={JSON.ID_REQVEST}
                                    callBack={()=>this.getREQV_CONTs()}
                                    />})
                            }}> {JSON.ID_VIS}</div></td>
                        <td>{global.Auth.ROLE==6?JSON.OVNER_FIO:'-'}</td>
                        <td>{global.Auth.ROLE==6?JSON.OVNER_EMAIL:'-'}</td>
                        <td>{global.Auth.ROLE==6?JSON.OVNER_PHONE:'-'}</td>
                        <td>{JSON.INSTALLATION_ORGANIZATION}</td>
                        <td>{JSON.TITLE_OBJECT}</td>
                        <td>{JSON.DATE_CREATE}</td>
                        <td>{JSON.DATE_CONTROL}</td>
                        <td>{JSON.METHOD_NK}</td>
                        <td>{JSON.CONTROL_VALUE}</td>
                        <td>{JSON.TRANSPORT_PROD_TITLE}</td>
                        <td>{JSON.WILDER_JURNAL}</td>
                        <td></td>
                        <td>{JSON.LINE_TITLE}</td>
                        <td>{JSON.WLDER_NUMBER}</td>
                        <td>{JSON.WELDERS_JOIN_TITLE}</td>
                        <td></td>
                        <td>{JSON.MARK_MATERIAL_TITLE}</td>
                        <td>{JSON.MARK_MATERIAL_WELDER}</td>
                        <td>{JSON.CONTROLLING_LENGTH}</td>
                        <td>{JSON.CONTROLLING_WEIGHT}</td>
                        <td>{JSON.WELDER_MARK}</td>
                        <td>{JSON.CONDITIONS_TITLE}</td>
                        <td>{JSON.CONTROL_TOOLS_TITLE}</td>
                        <td>{JSON.NTD_CONTROL_TITLE}</td>
                        <td>{JSON.NTD_QUALITY}</td>

                        <td>{JSON.CONVERTER_PARAMS}</td>
                        <td>{JSON.CONTROL_SCHEME}</td>
                        <td>{JSON.QUALITY_ASSESSMENT}</td>


                        <td>{JSON.SENSITIVITY}</td>
                        <td>{JSON.ESTABLISHED_SNIP}</td>
                        <td>{JSON.BASED_RESULTS}</td>

                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>

                        <td>{JSON.RESP_FIO}</td>
                        <td>{JSON.NACH_FIO}</td>


                    </tr>
                ,
                JOINS
            ]
        })

        return  ([
                <div className="_1 Zaiavki tttb" >
                    <div class="container-fluid m-0 " >
                        <div class="row main_pages ">
                           
                            <div className="col-12 right row   m-0  p-0 scroll">
                                
                            <table class="table" ref={ref => this.ref.TABLE = ref}>
                            <thead >
                            <tr>
                                
                                <td/>
                                <td>№ п\п</td>
                                <td>Статус</td>
                                <td>Номер заявки</td>
                                <td>Заказчик</td>
                                <td>Заказчик e-mail</td>
                                <td>Заказчик телефон</td>
                                <td>Монтажная организация</td>
                                <td>Объект контроля</td>
                                <td>Дата заявки</td>
                                <td>Дата контроля</td>
                                <td>Метод НК</td>
                                <td>Объем контроля</td>
                                <td>Транспортируемый продукт</td>
                                <td>№ Сварного журнала \ схемы</td>
                                <td>Наим. узла \ детали</td>
                                <td>Наим. линии</td>
                                <td>№ Сварного соед по сварочному журналу</td>
                                <td>Способ сварки</td>
                                <td>№ Контролируемого участка</td>
                                <td>Марка стали</td>
                                <td>Марка сварного материала</td>
                                <td>Контролируемый метраж объекта</td>
                                <td>Контролируемая толщина объекта</td>
                                <td>Шифр сварщика</td>
                                <td>Условия проведения контроля</td>
                                <td>Применяемые средства контроля</td>
                                <td>Контроль в соответствии с НТД</td>
                                <td>Оценка качества по НТД</td>

                                <td>Параметры преобразователей</td>
                                <td>Схема контроля</td>
                                <td>Норма оценки качествка</td>


                                <td>Чувствительность</td>
                                <td>Установленный СПиН</td>
                                <td>По результатам контроля</td>

                                <td>Описание выявленных дефектов (кординаты)</td>
                                <td>№ Заключения НК</td>
                                <td>Дата выдачи заключения НК</td>
                                <td>Сведенья о качестве проконтролированного соединения</td>

                                <td>ФИО Специалиста</td>
                                <td>ФИО Начальника</td>

                                
                                </tr>
                            </thead>

                            {list_REQV}
                                
                            </table>


                                   
                            </div> 
                        </div>
                    </div>
                </div>,
                <FiltersView Filters={Filters} setFilters={(Filters_)=>this.setFilters(Filters_)}/>
        ])
    }
}


