import React from 'react';
import '../css/POP.css';
import '../css/POP_Qvestion.css';


class POP_Trener extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
       

    }
    
    Clicer(func){
        global.PopUp.CloseFunction();
        if(func!==undefined && func!=null)
            func();
        
    }
   

    render (){
        if(this.props.buttons!=null && this.props.buttons.length>0)
            var buts= this.props.buttons.map((JSON) =>
                <div key={global.getRandomInt(9999)} className="col">
                    <button type="button" onClick={()=>this.Clicer(JSON[2])} class={"w-100 btn btn-"+JSON[1]}>{JSON[0]}</button>
                </div>
            );
        else
            var buts = <div key={global.getRandomInt(9999)} className="col">
                            <button type="button" onClick={()=>this.Clicer()} class="w-100 btn btn-primary">ОК</button>
                        </div>

        
        return <div className="POP POP_Trener content-fluid">
                    <br></br>
                    <h1 class="text-center ">{this.props.title}</h1>
                    <p class="text-center ">{this.props.text}</p>
                    <p style={{fontSize:12}} class="text-right text-center text-muted m-0 p-0">{this.props.footer}</p>
                    <br></br>
                    <div className="row">
                       {buts}
                    </div>
                    
                </div>
    
    }
}

export default POP_Trener;
