import React from 'react';
import '../../css/POP.css';
import '../../css/POP_Folder.css';
import POP_Select from '../../Components/POP_Select'
import POP_Qestion from '../../Components/POP_Qestion'
import styled from 'styled-components';


class POP_Folder extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedDistId:this.props.DIST,
            Dist:[],
            Users:[],
            Users_selected:[],
            IS_PRIVATE:0
        }
        this.input=[];
        this.ImgLoaded=false;
        this.AllWaysSelectedUsers=[];

    }
    componentDidMount(){
       this.getDist();
       this.getUsers();
       if(this.props.ID_OBJECT!=null)
        this.getFolder();
       
    }

    getFolder(){
        document.querySelector("#PrevImg_img_FOLDER").style.backgroundImage = "url(" + global.serverURL_Main+"img/folders/img_"+this.props.ID_OBJECT+".jpg?"+global.getRandomInt(10000) + ")";

        global.SERVER_task('operation=getFolders&ID='+this.props.ID_OBJECT,
        (data)=>{
            this.input.FOLDER_NAME.value=data.result[0].TITLE;
            this.setState({
                    selectedDistId:data.result[0].DIST,
                    IS_PRIVATE:data.result[0].IS_PRIVATE
                })
        })

        global.SERVER_task('operation=getFolder_Users&ID='+this.props.ID_OBJECT,
        (data)=>{
            var users=[];
            for(var i=0;i<data.result.length;i++){
                users.push(data.result[i].ID_USER);
            }
            this.setState({
                Users_selected:users
                })
        })
    }

    getDist(){
        if(global.Auth.ROLE<=2)
        global.SERVER_task('operation=getDist',
        (data)=>{
             this.setState({Dist:data.result});   
        })
    }

    getUsers(){
        global.SERVER_task('operation=getUsers',
        (data)=>{
            this.AllWaysSelectedUsers=[];
            for(var i=0;i<data.result.length;i++){
                data.result[i].TITLE=data.result[i].FIO;
                if(data.result[i].ROLE*1<=2)
                    this.AllWaysSelectedUsers.push(data.result[i].ID);
            }
            
            this.setState({Users:data.result});   
        })
    }


    Save(){
        if(global.checkReqvireds(this.input)){
            var data={
                TITLE:this.input.FOLDER_NAME.value,
                DIST:this.state.selectedDistId,
                ID_PARENT:0,
                IS_PRIVATE:this.state.IS_PRIVATE,
                Users_selected:this.state.Users_selected
            };
            if(this.props.ID_OBJECT!=undefined)
                data.ID=this.props.ID_OBJECT;
           
           

            global.SERVER_task('operation=createUpdateFolder&params='+JSON.stringify(data),
            (data)=>{
                if(data.result*1>0){
                    if(this.ImgLoaded){
                        var ID=data.result*1;
                        var file_data = this.input.file.files[0];
                        var form_data = new FormData();
                        form_data.append('file', file_data);
                        global.UploadFlieToServer("folders",ID,form_data);   
                    }

                    try{
                        this.props.callBack();
                    }catch{}
                    global.PopUp.CloseFunction();
                }
                
            })

           

        }
        
        
        
    }

    FilePrev() {
        debugger;
        var obj=document.getElementById("pct_FOLDER");
        debugger;
        if (obj.files[0]) {
          var fr = new FileReader();
          var THIS = this;
      
          fr.addEventListener("load", ()=>{
            document.querySelector("#PrevImg_img_FOLDER").style.backgroundImage = "url(" + fr.result + ")";
            document.querySelector("#PrevImg_img_FOLDER").style.backgroundColor = "rgba(255,255,255,0.3);";
            document.querySelector("#PrevImg_img_FOLDER").style.backgroundSize = "auto";
            this.ImgLoaded=true;
            
          }, false);
      
          fr.readAsDataURL(obj.files[0]);
        }else{
            this.ImgLoaded=false;
            
            document.querySelector("#PrevImg_img_FOLDER").style.backgroundImage = "url('/img/user_white.svg')";
            document.querySelector("#PrevImg_img_FOLDER").style.backgroundColor = "rgba(255,255,255,0.3);";
            document.querySelector("#PrevImg_img_FOLDER").style.backgroundSize = "100px";
        }
    }
    Delete(){
       global.PopUp.Open({content:<POP_Qestion text="Безвозвратно удалить папку и файлы, содержащиеся в ней?" buttons={[
            ["Удалить",'danger',()=>this.deleteFolder()],
            ["Отмена",'']
            ]}/>
        })
    }
    deleteFolder(){
        global.SERVER_task('operation=deleteFolder&ID='+this.props.ID_OBJECT,
            (data)=>{
                
                    try{
                        this.props.callBack();
                    }catch{}
                    global.PopUp.CloseFunction();
                
                
            })
    }

    setUsers(Users){
        this.setState({Users_selected:Users});
    }
   
    selectDist(ID){
        this.setState({selectedDistId:ID});
    }

    render (){
        for(var i=0;i<this.state.Dist.length;i++){
            if(this.state.Dist[i].ID==this.state.selectedDistId){
                var Dist_title=this.state.Dist[i].TITLE;
                break;
            }
        }

var list_folders=this.state.Dist.map((JSON)=>
    <a class={"dropdown-item "+(this.state.selectedDistId==JSON.ID?"bg-primary":"")} onClick={()=>this.selectDist(JSON.ID)}>{JSON.TITLE}</a>
);


if(this.props.ID_OBJECT==null)
    var buttons =  <div className="row">
                        <div key={global.getRandomInt(9999)} className="col ">
                            <button type="button" onClick={()=>this.Save()} class="w-100 btn btn-primary ">Создать</button>
                        </div>
                        <div key={global.getRandomInt(9999)} className="col">
                            <button type="button" onClick={()=>global.PopUp.CloseFunction()} class="w-100 btn">Отмена</button>
                        </div>
                    </div>
else 
    var buttons =  <div className="row">
                        <div key={global.getRandomInt(9999)} className="col">
                            <button type="button" onClick={()=>this.Save()} class="w-100 btn btn-primary">Сохранить</button>
                        </div>
                        <div key={global.getRandomInt(9999)} className="col">
                            <button type="button" onClick={()=>this.Delete()} class="w-100 btn">Удалить</button>
                        </div>
                    </div>
        
        return <CreateDirr className="POP POP_Folder content-fluid">
                    
                        <div id="PrevImg_img_FOLDER">
                                <div id="PrevImg_cont">
                                    <label className="filePrev btn btn-light btn-sm" id="PrevImg" for="pct_FOLDER"> Выбрать изображение</label>
                                </div>
                               
                               
                                <input type="file" id="pct_FOLDER" ref={ref => this.input.file = ref} onChange={()=>this.FilePrev()} hidden class=" mb-2 w-100" />
                        </div>

                        <p className="mb-0">Название папки</p>
                        <input autoFocus type="text" ref={ref => this.input.FOLDER_NAME = ref} class="form-control mb-2" placeholder="Новая папка" />

                        {global.Auth.ROLE<=2?[
                            <p className="mb-0">Раздел</p>,
                            <div class="dropdown w-100 mb-2">
                                <button class="btn w-100 btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {Dist_title}
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    {list_folders}
                                </div>
                            </div>
                        ]
                        :null}
                        <p className="mb-0">Доступ к папке</p>
                        <div className="row p-0 m-0">
                            <div className="col-6 p-0 pr-1 m-0">
                                    <button type="button" className={"btn btn-sm w-100 mb-4 "+(this.state.IS_PRIVATE==1?"btn-primary":"btn-light")}
                                    onClick={
                                        ()=>{
                                            this.setState({IS_PRIVATE:1});
                                            global.PopUp.Open({content:<POP_Select
                                            ELEMENTS={this.state.Users}
                                            SELECTED={this.state.Users_selected}
                                            MEGA_SELECTED={this.AllWaysSelectedUsers}
                                            MEGA_SELECTED_POSTFIX="admin"
                                            callBack={(Users)=>this.setUsers(Users)}
                                            />});
                                        }
                                    }
                                >Доступно только для ...</button>
                            </div>
                            <div className="col-6 p-0 pl-1 m-0">
                                    <button type="button" className={"btn btn-sm  w-100 mb-4 "+(this.state.IS_PRIVATE==1?"btn-light":"btn-primary")} 
                                    onClick={
                                        ()=>{
                                            this.setState({IS_PRIVATE:0});
                                            global.PopUp.Open({content:<POP_Select
                                            ELEMENTS={this.state.Users}
                                            SELECTED={this.state.Users_selected}
                                            MEGA_SELECTED={[]}
                                            MEGA_SELECTED_POSTFIX="admin"
                                            callBack={(Users)=>this.setUsers(Users)}
                                            />});
                                        }
                                    }>Доступна всем, кроме ...</button>
                            </div>
                        </div>

                    <br></br>
                   {buttons}
                    
                </CreateDirr>
    
    }
}

export default POP_Folder;

const CreateDirr = styled.div`
width:400px
`
