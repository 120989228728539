import React from 'react';
import '../css/Chat.css';


class User extends React.Component {
    constructor(props) {
        super()
        this.state = {
            FIO:null
        }
      
    }

    componentDidMount(){
        global.SERVER_task('operation=getAccount',
            (data)=>{
                this.setState({FIO:global.smollFIO(data.result[0].FIO)});
               
            })
    }

    render (){
        return  <div className="User w-100 container-fluid" >
                    <div className="row">
                       
                        <div className="col-12 pr-0">
                            <h4 className="">{this.state.FIO}</h4>
                            <p className="" onClick={()=>this.props.openPage("ACCOUNT")}>Перейти в аккаунт</p>
                        </div>
                        
                    
                    </div>
                </div>
    
    }
}

export default User;
