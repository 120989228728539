import React from 'react';
import '../css/Footer.css';
import Chat from './Chat'
import User from './User'
import Cont from './Cont'

import Fab from './Fab'
import styled from 'styled-components';


class Footer extends React.Component {
    constructor(props) {
        super()
        this.state = {
            
        }
      
    }

    render (){
        return  <div className="Footer container-fluid mb-2" >
                   <div className="row p-3 d-sm-none d-md-flex d-lg-flex">
                        
                        <Widjet style={{left:15}}>
                            <User openPage={(CURRENT_PAGE)=>this.props.openPage(CURRENT_PAGE)}/>
                        </Widjet>
                        <Widjet style={{left:250+15+15}}>
                            {global.Auth.ROLE!=4 && global.Auth.ROLE!=null?
                            <Cont openPage={(CURRENT_PAGE)=>this.props.openPage(CURRENT_PAGE)}/>
                            :null}
                        </Widjet>
                        <Widjet style={{right:15}}>
                            <Chat/>
                        </Widjet>
                   </div>
                    <Fab class="d-sm-block d-md-none d-lg-none" buttons={[
                        {
                            title:"Аккаунт",
                            onClick:null,
                            svg:<img src="/img/user.svg"></img>
                        },
                        {
                            title:"Почта",
                            onClick:null,
                            svg:<img src="/img/mail.svg"></img>
                        },
                        {
                            title:"Контакты",
                            onClick:null,
                            svg:<img src="/img/contact.svg"></img>
                        },
                        {
                            title:"Чат",
                            onClick:null,
                            svg:<img src="/img/message.svg"></img>
                        }
                    ]}/>
                </div>
    
    }
}
const Widjet = styled.div`
backgroud:white;
border-radius:10px;

position:fixed;
bottom:10px;
width:250px;
`
export default Footer;
